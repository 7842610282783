import React from 'react'

import { Link } from 'tools/Links'
import { fromNow } from 'utils/time'

import MilestoneArrows from 'components/Journey/Milestone/Arrows'

function JourneyHeader({ jmap, path, children = undefined, className }) {
  return (
    <div className="w-100 mt4" key={jmap.id}>
      <div className="flex-between mb2">
        <div className={className}>{jmap.journey.name}</div>
        <div className="f7 gray">Last Updated: {fromNow(jmap.updatedAt)}</div>
      </div>
      <Link to={`${path}/${jmap.shortId}`}>
        <MilestoneArrows jmap={jmap} className="w-100" />
      </Link>
      {children}
    </div>
  )
}

export default JourneyHeader
