import React, { useContext } from 'react'

import { useSaveUrl } from 'tools/FormHelpers/Url'

import AvatarEditViewer from 'components/Person/tools/Picture'

import { OrgContext, R_ORG } from '../reducer'
import InputChange from './InputChange'
import InputName from './InputName'
import Tags from './Tags'

function Profile() {
  const [org, dispatch] = useContext(OrgContext)
  const saveUrl = useSaveUrl(org.onSave)
  const args = { data: org, dispatch, onSave: org.onSave }

  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <div className="flex">
        <div className="mr4 w-100">
          <InputName onSave={org.onSave} dispatch={dispatch} data={org} />
          <InputChange
            {...args}
            token="website"
            onSave={saveUrl}
            placeholder="Company/Org Website URL"
            label="Website"
            className="mt4"
            type="line"
          />
        </div>
        <AvatarEditViewer
          origin={org}
          type="org"
          round={false}
          onSave={(v) => dispatch({ type: R_ORG.UPSERT_FILE, value: v })}
          canEdit={org.edit}
        />
      </div>
      <Tags />
      <InputChange
        {...args}
        token="locales"
        label="Preferred Regions of Investment"
        placeholder="e.g. USA, Europe, Global"
        className="mt4"
      >
        You invest in companies located in these regions
      </InputChange>

      <InputChange
        {...args}
        token="invest_range"
        label="Preferred Investments"
        placeholder="e.g. $50k-$100k, or $1mm-$5mm"
        className="mt4"
      >
        Your typical investment range per round.
      </InputChange>

      <InputChange
        {...args}
        token="detail"
        label="Details"
        placeholder="A paragraph explaining your organization."
        className="mt4"
        type="text"
      />

      <h2 className="primary mt4 mb3">Location</h2>
      <i>Your primary location (HQ)</i>
      <InputChange
        {...args}
        token="city"
        placeholder="City"
        label="City"
        type="line"
      />
      <InputChange
        {...args}
        token="state"
        placeholder="State"
        label="State"
        className="mt4"
        type="line"
      />
      <InputChange
        {...args}
        token="country"
        placeholder="Country"
        label="Country"
        className="mt4"
        type="line"
      />
    </div>
  )
}

export default Profile
