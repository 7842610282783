import paths from 'constants/paths'

import { makeTab } from 'tools/NavTabs'

import Applications from './Applications'
import Files from './Files'
import Financials from './Financials'
import Forum from './Forum'
import Members from './Members'
import Profile from './Profile'
import Progress from './Progress'
import Rubrics from './Rubrics'

////////////////////////////////////////////////////////////////////////////////
const mod = [
  makeTab({
    id: 'profile',
    name: 'Profile',
    Component: Profile
  }),
  makeTab({
    id: 'summary',
    name: 'Financials',
    Component: Financials
  }),
  makeTab({
    id: 'progress',
    orig: true,
    name: 'Progress',
    Component: Progress
  }),
  makeTab({
    id: 'rubrics',
    name: 'Rubrics',
    Component: Rubrics
  }),
  makeTab({
    id: 'members',
    name: 'Members',
    Component: Members
  }),
  makeTab({
    id: 'files',
    name: 'Files',
    Component: Files
  }),
  makeTab({
    id: 'applications',
    name: 'Applications',
    Component: Applications
  }),
  makeTab({
    id: paths.forum,
    name: 'Forums',
    view: (x, y) => false,
    hideTabs: true,
    Component: Forum
  })
]
export default mod
