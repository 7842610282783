import React, { useContext } from 'react'

import ManageMembers from 'tools/ManageMembers'

import { UPSERT_ORG_MEMBER } from '../graphql'
import { MEMBER_TYPES } from '../normalize'
import OrgContext from '../reducer'

function Members({ types, orgId = '', members, projectId = '' }) {
  const [org] = useContext(OrgContext)

  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <div className="flex-items mb2">
        <label className="heading dib">
          {org.name} Team <i className="fas fa-users ml2" />
        </label>
      </div>
      <ManageMembers
        types={MEMBER_TYPES}
        token="orgId"
        target={org}
        canChange={org.canUsers}
        onChange={() => org.refetch()}
        mutate={UPSERT_ORG_MEMBER}
      />
    </div>
  )
}

export default Members
