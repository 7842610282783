// // level 0 is nothing, higher levels are more verbosity
import axios from 'axios'
import * as jose from 'jose'
import { v4 as uuid } from 'uuid'

import config from 'constants/config'

import { readableError } from 'tools/Loader'

import { VALIDATION_KEY } from 'components/Signon'

import debug from '../debug'
import { safeStoreDrop, safeStoreGet, safeStorePut } from './safeLocalStore'

const DEBUG_LEVEL = 0
export function authDebug(level, message, data) {
  if (level <= DEBUG_LEVEL) {
    debug(message, data)
  }
}

export const AUTHX_ACTIONS = {
  SIGNING_IN: 0, // 'AUTHX_ACTIONS_SIGNING_IN',
  SIGNED_IN: 1, // 'AUTHX_ACTIONS_SIGNED_IN',
  SIGN_OUT: 2, // 'AUTHX_ACTIONS_SIGN_OUT',
  SIGNIN_TIMEOUT: 3, // 'AUTHX_ACTIONS_SIGNIN_TIMEOUT',
  ERROR: 4, // 'AUTHX_ACTIONS_ERROR',
  ERROR_CLEAR: 5, // 'AUTHX_ACTIONS_ERROR_CLEAR',
  REFRESH_TOKEN: 6, // 'AUTHX_ACTIONS_REFRESH_TOKEN',
  ERROR_REDIRECT: 7 // 'AUTHX_ACTIONS_ERROR_REDIRECT'
}

export const initialState = {
  targetAuthed: false, // what is our desired state - authenticated or not?
  handshaking: false, // are we in the middle of a signin sequence? - used by the AuthX frontend
  isAuthN: false, // have we been authenticated?
  error: undefined,
  refresh: false, // trigger a token refresh
  redirect: false // should we redirect to signin page?
}

export const AUTHX_STATUS = {
  INITIAL: 0,
  ANONYMOUS: 1,
  IDENTIFIED: 2
}

export function authXinitialState() {
  const hasValKey = !!getValidationKey()
  return {
    ...initialState,
    status: !hasValKey ? AUTHX_STATUS.ANONYMOUS : AUTHX_STATUS.INITIAL,
    targetAuthed: hasValKey,
    refresh: hasValKey
  }
}

////////////////////////////////////////////////////////////////////////////////
// mutable global -- not ideal, but we have a tough challenge
const defaultAccessToken = { token: '', expires: 0, valid: false, claims: {} }
let ACCESS_TOKEN = { ...defaultAccessToken }

export function syncHasAccessTokenExpired() {
  return Date.now() > ACCESS_TOKEN.expires
}

export function syncGetAccessToken(dispatch) {
  authDebug(5, '[utils/signon] syncGetAccessToken()')
  if (ACCESS_TOKEN.valid && syncHasAccessTokenExpired()) {
    authDebug(3, '[utils/signon] syncGetAccessToken()', `expired - removing`)
    syncSetAccessToken(null)
  }
  return ACCESS_TOKEN
}

export function syncSetAccessToken(token) {
  ACCESS_TOKEN = validAccessToken(token)
  authDebug(3, '[utils/signon] syncSetAccessToken()')
}

export function dropAccessToken() {
  authDebug(3, '[utils/signon] dropAccessToken()', '')
  ACCESS_TOKEN = { ...defaultAccessToken }
}

function validAccessToken(token) {
  authDebug(5, '[utils/signon] validAccessToken()', token)
  if (!token) {
    return { ...defaultAccessToken }
  }

  const claims = jose.decodeJwt(token)

  authDebug(4, '[utils/signon] token claims', [claims, token])
  if (Date.now() / 1000 < claims.exp) {
    return { claims, token, expires: claims.exp * 1000, valid: true }
  }
  return { ...defaultAccessToken }
}

////////////////////////////////////////////////////////////////////////////////
export function getValidationKey() {
  // authDebug(3, "[utils/signon] getValidationKey()", '')
  const key = safeStoreGet(VALIDATION_KEY)
  authDebug(3, '[utils/signon] getValidationKey() key => ', key)
  return key
}

export function syncSetValidationKey(key) {
  authDebug(3, '[utils/signon] syncSetValidationKey()', key)
  safeStorePut(VALIDATION_KEY, key)
}

export function dropValidationKey() {
  authDebug(3, '[utils/signon] dropValidationKey()', '')
  safeStoreDrop(VALIDATION_KEY)
}

////////////////////////////////////////////////////////////////////////////////
// Try to get/refresh token and then signInUser or SIGNOUT
export async function asyncRefreshToken(dispatch) {
  authDebug(3, '[utils/signon] asyncRefreshToken()', '')
  dispatch({ type: AUTHX_ACTIONS.REFRESH_TOKEN, value: false }) // prevents further calling in app.jsx
  const { valid } = syncGetAccessToken(dispatch)
  if (!valid) {
    authDebug(1, '[utils/signon] asyncRefreshToken()', 'Already in refresh cycle')
    const validation_key = getValidationKey()
    if (validation_key) {
      const refresh = await asyncGenRefreshToken()
      if (refresh) {
        return authRequest(
          'refresh',
          {
            body: JSON.stringify({
              client_assertion_type:
                'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
              client_assertion: refresh
            })
          },
          dispatch
        )
          .then((result) => {
            authDebug(2, '[utils/signon] asyncRefreshToken():', result)
            if (result && result.access_token) {
              syncSetAccessToken(result.access_token)
              dispatch({ type: AUTHX_ACTIONS.SIGNED_IN })
            } else {
              dispatch({ type: AUTHX_ACTIONS.SIGN_OUT })
            }
            return result
          })
          .catch((err) => {
            console.error('UNHANDLED ERR', err)
          })
      } else {
        authDebug(5, '[utils/signon] asyncRefreshToken():', 'no refresh token?')
      }
    } else {
      authDebug(5, '[utils/signon] asyncRefreshToken():', 'no validation_key')
      dispatch({ type: AUTHX_ACTIONS.SIGN_OUT })
    }
  }
  authDropStates()
  authDebug(5, '[utils/signon] asyncRefreshToken()', 'unable to refresh')
  return new Error('unable to refresh token')
}

export async function asyncGenRefreshToken() {
  authDebug(5, '[utils/signon] asyncGenRefreshToken()')
  const validation_key = safeStoreGet(VALIDATION_KEY)
  if (validation_key) {
    const { secret, subject, audience } = validation_key
    const key = new TextEncoder().encode(secret)
    return await new jose.SignJWT({ jti: uuid() })
      .setProtectedHeader({ alg: 'HS256' })
      .setIssuedAt()
      .setAudience(audience)
      .setExpirationTime('15m')
      .setSubject(subject)
      .sign(key)
  } else {
    return false
  }
}

////////////////////////////////////////////////////////////////////////////////
// Loader from the various signOn interfaces
export function asyncStartValidate({ state, vars, status, dispatch }) {
  authDropStates()
  authDebug(1, '[utils/signon] asyncStartValidate()', 'querying for new token')
  return authRequest(
    'signon',
    {
      body: JSON.stringify(vars)
    },
    dispatch
  )
    .then((data) => asyncHandleValidate({ state, status, data, dispatch }))
    .catch((error) => authError({ dispatch, msg: readableError(error) }))
}

async function asyncHandleValidate({ state, status, data, dispatch }) {
  authDebug(2, '[utils/signon] asyncHandleValidate() data=', data)
  if (data.aud && data.sec && data.sub) {
    let token = {
      audience: data.aud,
      secret: data.sec,
      subject: data.sub
    }
    syncSetValidationKey(token)
    return asyncRefreshToken(dispatch)
  } else if (data.reason) {
    authError({ dispatch, msg: readableError(data.reason) })
  } else {
    authError({
      dispatch,
      msg:
        'response received from backend with no asyncGenRefreshToken token? cannot continue'
    })
  }
}

////////////////////////////////////////////////////////////////////////////////
export function authDropStates() {
  authDebug(1, '[utils/signon] authDropStates()')
  dropAccessToken()
  dropValidationKey()
}

export function authRequest(path, opts, dispatch) {
  authDebug(3, '[utils/signon] authRequest():', `{api}/${path}`)
  if (!opts.headers) {
    opts.headers = {}
  }
  if (!opts.headers['Content-Type']) {
    opts.headers['Content-Type'] = 'application/json'
  }
  return axios
    .post(`${config.url.app}${config.url.authapi}${path}`, opts.body, {
      headers: opts.headers,
      withCredentials: true
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      if (dispatch) {
        switch (e.message) {
          case 'Request failed with status code 403':
            dispatch({
              type: AUTHX_ACTIONS.ERROR_REDIRECT,
              // value: 'Cached signin expired, please signin again.'
              value: 'Unable to signin, please try again.'
            })
            break
          default:
            authError({ dispatch, msg: `${e}` })
            break
        }
      }
      return Promise.reject(e.message)
    })
}

export function authError({ dispatch, msg }) {
  authDebug(3, '[utils/signon] authError() msg=', msg)
  dispatch({ type: AUTHX_ACTIONS.ERROR, value: msg })
}
