import React from 'react'

import InputChange from './InputChange'

function InputName(props) {
  return (
    <InputChange
      {...props}
      heading="heading mb2"
      token="name"
      label="Organization Name"
      placeholder="Your organization's name"
      className="f2 b"
    />
  )
}

export default InputName
