// import { intcmp } from 'utils/string'
// import { normalizeJourneyActor } from './journeyActor'

const defaultAnswer = Object.freeze({
  id: null,
  length: 0,
  status: '',
  version: 1,
  answer: '',
  readyOn: '',
  ready: false,
  acceptedOn: '',
  accepted: false
})

export const ANSWER_STATUS = {
  WORKING: 0,
  SUBMITTED: 1,
  ACCEPTED: 2,
  RETURNED: 3
}

////////////////////////////////////////////////////////////////////////////////
export function normalizeAnswer(newAnswer) {
  let answer = undefined
  if (newAnswer) {
    answer = { ...defaultAnswer, ...newAnswer }
  } else {
    answer = { ...defaultAnswer }
  }
  if (!answer.ready) {
    answer.ready = !!answer.readyOn
  }
  if (!answer.accepted) {
    answer.accepted = !!answer.acceptedOn
  }
  switch (answer.status) {
    case 'submitted':
      answer.statusA = ANSWER_STATUS.SUBMITTED
      break
    case 'returned':
      answer.statusA = ANSWER_STATUS.RETURNED
      break
    case 'accepted':
      answer.statusA = ANSWER_STATUS.ACCEPTED
      break
    default:
      answer.statusA = ANSWER_STATUS.WORKING
      break
  }

  answer.length = answer.answer.length

  return answer
}

////////////////////////////////////////////////////////////////////////////////
export function updateAnswer(state, answer) {
  console.log('ignoring updateAnswer')
  return state
  // state = {
  //   ...state,
  //   actor: {
  //     ...state.actor,
  //     answers: Object.values({
  //       ...state.actor.answersD,
  //       [answer.id]: normalizeAnswer(answer)
  //     })
  //   }
  // }
  // resetRubricForAnswer(state, answer)
  //
  // // this is to get it to recalculate progress, so we should break that out...
  // return normalizeJourneyActor(state)
}

// function resetRubricForAnswer(state, answer) {
//   return
//   for (let x in state.journey.rubricsL) {
//     const rId = state.journey.rubricsL[x]
//     const questionMapL = state.journey.rubricsD[rId].questionMapsL
//     for (let y in questionMapL) {
//       const qmId = questionMapL[y]
//       const qmap = state.journey.rubricsD[rId].questionMapsD[qmId]
//       if (answer.questionId === qmap.question.id) {
//         // resetting these keys will allow it to normalize just these things
//         const rubric = state.journey.rubricsD[rId]
//         rubric.questionMaps = Object.values(rubric.questionMapsD)
//         state.journey = {
//           ...state.journey,
//           rubrics: Object.values(state.journey.rubricsD)
//         }
//         return
//       }
//     }
//   }
// }
