import React, { useContext, useMemo, useState } from 'react'

import { AddButton, CreateButtonValState, DEFAULT_EDIT } from 'tools/Uniform'
import { inputAsInt, intcmp } from 'utils/string'
import { YEAR } from 'utils/time'

import ProjectContext from '../reducer'
import style from './FinancialSummary.module.scss'

const DEFAULT_SUMMARY = { [YEAR]: [] }

function FinancialSummary({ onSave, onChange }) {
  const valState = useState(DEFAULT_EDIT)
  const [edit, setEdit] = valState
  const [
    {
      d: { financials = {} }
    }
  ] = useContext(ProjectContext)
  const s = financials.summary
  const { summary, list } = useMemo(() => {
    const summary = s || DEFAULT_SUMMARY
    const list = Object.keys(summary)
      .toSorted((a, b) => intcmp(parseInt(a), parseInt(b)))
      .reverse()
    return { summary, list }
  }, [s])

  const args = { onSave, onChange, summary, list }
  return (
    <>
      <label className="mt4 mb3">Financial Summary</label>
      <table className="list">
        <thead>
          <tr>
            <th></th>
            {list.map((year) => (
              <th key={year} className={`tc ${style.headbutton}`}>
                {year}
                <div
                  className={`${style.icon} button clear`}
                  onClick={() => {
                    delete summary[year]
                    onSave({ token: 'summary', component: 'financials' }, summary)
                  }}
                >
                  <i className="fas fa-trash" />
                </div>
              </th>
            ))}
            <th>
              <AddButton onClick={() => setEdit({ ...edit, open: true })} />
            </th>
          </tr>
        </thead>
        <tbody>
          <FinSumRow index={0} label="Revenue" {...args} />
          <FinSumRow index={1} label="Expenditure" {...args} />
        </tbody>
      </table>
      <CreateButtonValState
        onSave={(x, value, good, bad) =>
          onSave(
            { token: 'summary', component: 'financials' },
            { [value]: [], ...summary },
            good,
            bad
          )
        }
        placeholder="year"
        valState={valState}
      >
        Add Year
      </CreateButtonValState>
    </>
  )
}

const INPUT_STYLE = {
  width: '4rem',
  paddingLeft: '0.5rem'
}

function getWidth([x, y]) {
  return Math.max(gWidth(x), gWidth(y)) + 'rem'
}
function gWidth(x) {
  const value = `${x}`
  const vlen = value.length
  if (vlen > 4) {
    return vlen
  } else {
    return 4
  }
}

function FinSumRow({ index, label, summary, list, onSave, onChange }) {
  return (
    <tr>
      <th>{label}</th>
      {list.map((year) => {
        const istyle = { ...INPUT_STYLE, width: getWidth(summary[year]) }
        return (
          <td key={year}>
            <div className="relative flex justify-end theme-bg-hilite br3">
              <input
                style={istyle}
                className="tr"
                value={summary[year][index] || 0}
                onChange={(ev) => {
                  summary[year][index] = ev.target.value
                  onChange('summary', summary, 'financials')
                }}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter' || ev.key === 'Tab') {
                    ev.target.blur()
                  }
                }}
                onBlur={() => {
                  const { value } = inputAsInt(`${summary[year][index]}`)
                  summary[year][index] = value
                  onSave({ token: 'summary', component: 'financials' }, summary)
                }}
              />
              <div className="absolute left-0 input-inner gray pl1">$</div>
            </div>
          </td>
        )
      })}
    </tr>
  )
}

export default FinancialSummary
