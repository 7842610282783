import React from 'react'

import { GLYPH } from 'constants/icons'

import { Link } from 'tools/Links'
import { fromNow } from 'utils/time'

import { Avatar } from 'components/Person/tools/Label'

function SubjectLine({
  linkTo = undefined,
  subjectClass = 'b',
  msg,
  showCount = true,
  className = ''
}) {
  return (
    <Link
      push={false}
      type={linkTo ? 'div' : 'disable'}
      className={`mb2 flex justify-begin items-start ${
        linkTo ? 'pointer hover-hilite' : ''
      } br2 ph2 pv1 ${className}`}
      to={linkTo}
    >
      <Link to={`/u/${msg.author.handle}`}>
        <Avatar person={msg.author} className="mr3" />
      </Link>
      <div className="">
        {msg.subject ? <div className={subjectClass}>{msg.subject}</div> : null}
        <div className="fw2 f7">
          Posted by u/{msg.author.handle} {GLYPH.dot} {fromNow(msg.insertedAt)}
        </div>
      </div>
      {showCount && msg.meta && msg.meta.posts ? (
        <div className="ml-auto nowrap f7">
          <i className="fas fa-comment-alt mr2 gray" />
          <span className="fw3">{msg.meta.posts || 0}</span>
        </div>
      ) : null}
    </Link>
  )
}

export default SubjectLine
