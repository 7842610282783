import React, { useContext } from 'react'

import { useMutation } from '@apollo/client'

import { useSaveUrl } from 'tools/FormHelpers/Url'
import RadioButtons, { useSaveRadio } from 'tools/RadioButtons'
import SelectTags, { TAGS_CHANGE_PROJECT, tagUpdate } from 'tools/SelectTags'

import AvatarEditViewer from 'components/Person/tools/Picture'

import { ProjectContext, R_PROJECT } from '../reducer'
import Input from './Input'

////////////////////////////////////////////////////////////////////////////////
function Profile({ action = 'view', create = false, refetch = () => {} }) {
  const [project, dispatch] = useContext(ProjectContext)
  const saveRadio = useSaveRadio(project.onSave)
  const saveUrl = useSaveUrl(project.onSave)
  const [tagProject] = useMutation(TAGS_CHANGE_PROJECT)
  const selectTag = (value, mapType, tagType, multi = undefined) => {
    tagUpdate(
      tagProject,
      { value, mapType, tagType, multi, ref: 'project', refId: project.id },
      (args, { tagsChange: result }) => {
        if (result?.success) {
          dispatch({ type: R_PROJECT.SET_TAGS, value: result.project.tags })
        }
      }
    )
  }

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  let statcolor
  let message = 'does not appear in searches'
  switch (project.status) {
    case 'private':
      statcolor = 'var(--error)'
      break
    case 'closed':
      statcolor = 'gray'
      break
    default:
      statcolor = 'transparent'
      message = ''
  }

  return (
    <div
      className="theme-frame-tabbed max-view-page mb5 pa4 relative"
      style={{ border: `dotted 2px ${statcolor}` }}
    >
      <div className="flex-items">
        <RadioButtons
          className="b f7"
          buttonClass=""
          value={project.status}
          opts={['private', 'public', 'closed']}
          onSave={saveRadio}
          token="status"
        />
        <div className="i f7 mr-auto">
          {message ? <>&mdash;</> : ''} {message}
        </div>
      </div>
      <div className="pt4 flex flex-column flex-row-ns">
        <div className="mr0 mr4-ns w-100 mb4 mb0-ns">
          <Input
            token="title"
            label="Venture Name"
            placeholder="Your venture name"
            className="mt0"
            maxlength={100}
          />
          <Input
            token="subtitle"
            label="One Line Summary"
            placeholder="One line explanation"
            maxlength={254}
          />
        </div>
        <AvatarEditViewer
          origin={project}
          type="project"
          round={false}
          onSave={(value) => dispatch({ type: R_PROJECT.UPSERT_FILE, value })}
          canEdit={true}
        />
      </div>
      <Input
        token="elevator"
        label="Business Summary"
        placeholder="A short, focused paragraph explaining what you do"
        maxlength={500}
        className="mt4"
        type="text"
      />
      <Input
        token="website"
        placeholder="Venture Website URL"
        label="Website"
        className="mt4"
        type="line"
        maxlength={100}
        onSave={saveUrl}
      />
      <SelectTags
        selectTag={selectTag}
        create={true}
        title="Markets"
        tagType="market"
        mapType="market"
        target={project}
        placeholder="select markets"
        defaultOptions={true}
        multi={true}
      />
    </div>
  )
}

export default Profile
