import React from 'react'

function Reports() {
  return (
    <div className="theme-frame-tabbed max-view-page pa4 mb6">
      <i>
        As your portfolio gets members, this is where you can find quarterly and
        other summary type reports
      </i>
    </div>
  )
}

export default Reports
