import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import paths from 'constants/paths'

import ProjectTile from 'components/Project/Tile'
import GlobalContext from 'reducer/global'

import { ApplyContext, R_APPLY } from './reducer'

function SelectExisting({ portfolioId, projects }) {
  const [{ user }] = useContext(GlobalContext)
  const [, dispatch] = useContext(ApplyContext)
  const history = useHistory()
  return (
    <div className="mt4">
      <label className="tc">Choose which project to submit:</label>
      <div className="flex flex-wrap justify-center">
        {projects.list.map((project) => (
          <ProjectTile
            key={project.id}
            project={project}
            onClick={() => {
              dispatch({
                type: R_APPLY.LINK_PROJECT,
                value: project,
                user
              })
              history.push(`${paths.apply}/${portfolioId}/${project.shortId}`)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default SelectExisting
