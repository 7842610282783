import React, { useState } from 'react'

import { FILE_TYPES } from 'tools/Files/normalize'
import { ExtLink } from 'tools/Links'
import { sizeOf } from 'utils/string'

function ShowFiles({ project, className = '', types = undefined }) {
  let files = []
  if (types) {
    files = Object.keys(project.fileTypes).filter((a) => types.includes(a))
  } else {
    files = Object.keys(project.fileTypes).filter((a) => a !== 'avatar')
  }
  if (files.length === 0) {
    return null
  }
  return (
    <div className={`${className} flex flex-wrap items-center`}>
      {files.toSorted().map((ftype, x) => {
        return project.fileTypes[ftype].map((file, y) => {
          return <File key={file.id} file={file} project={project} />
        })
      })}
    </div>
  )
}

function File({ project, file }) {
  const [, setClick] = useState(false)
  const inner = (
    <>
      <div className="tc mb2 text">
        <i className={`${FILE_TYPES[file.subject].icon} mr2`} />{' '}
        {FILE_TYPES[file.subject].name}
      </div>
      <div className="tc f7">
        {sizeOf(file.size)} - {file.name}
      </div>
    </>
  )
  const classes = 'button border primary mr3 ph3 pv2 mb3'
  if (file.url) {
    return (
      <ExtLink className={classes} to={file.url}>
        {inner}
      </ExtLink>
    )
  }
  return (
    <>
      <div className={`${classes} disabled`} onClick={() => setClick(true)}>
        {inner}
      </div>
      {/* <RequestAccess
        viewState={[click, setClick]}
        project={project}
        resourceType="file"
        resourceId={file.id}
      /> */}
    </>
  )
}

export default ShowFiles
