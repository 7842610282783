import React, { useContext, useState } from 'react'

import GlobalContext from 'reducer/global'

import { doSignOnLocal } from './doSignOnLocal'
import { badPassword } from './password'

////////////////////////////////////////////////////////////////////////////////
function checkPassword(pass1, pass2, signup, setStatus) {
  pass1 = pass1.trim()
  if (!signup) {
    return pass1
  }
  const error = badPassword(pass1, pass2)
  setStatus(error)
  return pass1
}

export function SignOnLocal({ signup, label, status, setStatus }) {
  const [state, dispatch] = useContext(GlobalContext)
  const [password, setPass1] = useState('')
  const [pass2, setPass2] = useState('')
  const [handle, setHandle] = useState('')

  return (
    <div className={`items-center`}>
      <form>
        <input
          className="w-100 br2"
          placeholder="Your email"
          value={handle}
          onChange={(e) => setHandle(e.target.value.trim())}
        />
        <input
          className="w-100 br2 mt3"
          type="password"
          placeholder={'Your ' + (signup ? 'desired ' : '') + 'password'}
          value={password}
          onChange={(e) => {
            setPass1(checkPassword(e.target.value, pass2, signup, setStatus))
          }}
        />
        {signup ? (
          <input
            className="w-100 br2 mt3"
            type="password"
            placeholder={'Your desired password (repeated)'}
            value={pass2}
            onChange={(e) => {
              setPass2(checkPassword(e.target.value, password, signup, setStatus))
            }}
          />
        ) : null}

        <div className="flex justify-around mt3 items-center">
          <button
            className={`button auth-signin  w-100 items-center pa2`}
            style={{ border: 0, margin: 0 }}
            onClick={(e) => {
              e.preventDefault()
              setStatus(undefined)
              doSignOnLocal({
                state,
                signup,
                status: { status: status ? status : '', setStatus },
                handle,
                password,
                dispatch
              })
            }}
          >
            <small className="label pl2">{label}</small>
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignOnLocal
