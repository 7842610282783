import { normalizeFiles } from 'tools/Files/normalize'

import { normalizeAnswer as normalizeJourneyAnswer } from 'components/Journey/normalize/answer'
import { normalizeOrg } from 'components/Org/normalize'
import { normalizeProject } from 'components/Project/normalize'

////////////////////////////////////////////////////////////////////////////////
export const defaultApply = Object.freeze({
  org: undefined,
  folio: undefined,
  login: undefined,
  project: {},
  status: undefined,
  fieldsL: [],
  fieldsD: {},
  answersD: {},
  progress: { required: 0, good: 0, total: 0, finished: 0 },
  _answers: false,
  _normal: false,
  _init: false
})

////////////////////////////////////////////////////////////////////////////////
export function normalizeApply(state, org) {
  org = Object.freeze(normalizeOrg(org))
  const app = {
    ...state,
    ...org.application,
    org,
    _normal: true
  }
  if (app.answers?.length) {
    app.answersD = normalizeAnswerList(app.fieldsD, app.answers)
  }
  return Object.freeze(app)
}

export function normalizeAnswer(field, answer) {
  switch (field.type) {
    case 'files':
      return { ...answer, field, ...normalizeFiles(answer.files) }
    case 'rubricQuestion':
      return { ...answer, field, ...normalizeJourneyAnswer(answer) }
    default:
      return { ...answer, field }
  }
}

export function normalizeAnswers(state, value) {
  const { status, progress: p, answers } = value
  const progress = {
    ...p,
    percent: p.required < 1 ? 100 : Math.ceil((p.good / p.required) * 100)
  }
  const answersD = normalizeAnswerList(state.fieldsD, answers)
  return { ...state, status, progress, answers, answersD, _answers: true }
}

function normalizeAnswerList(fieldsD, answers) {
  if (fieldsD) {
    return answers.reduce((acc, v) => {
      acc[v.fieldId] = normalizeAnswer(fieldsD[v.fieldId], v)
      return acc
    }, {})
  }
  return {}
}

export function normalizeLinkProject(state, project) {
  state = { ...state }
  state.project = normalizeProject(project)
  if (project.portfolioMembers?.length === 1) {
    return normalizeMember(state, project.portfolioMembers[0])
  }
  return state
}

export function normalizeMember(state, pm) {
  state._init = pm.application.init
  state.status = pm.status
  if (state.member) {
    state.member = { ...state.member, ...pm }
  } else {
    state.member = { ...pm }
  }
  return state
}
