//
// common gradients which can be brought into an SVG
//
import React from 'react'

const unique = '-9f3'
export const GRADIENTS = {
  horiz: {
    dark: 'hd' + unique,
    darkReverse: 'hdu' + unique,
    injecting: 'hi' + unique,
    charging: 'hc' + unique,
    load: 'hl' + unique
  },
  vert: {
    light: 'vl' + unique,
    lightUp: 'vlu' + unique,
    dark: 'vd' + unique,
    darkUp: 'vdu' + unique,
    injecting: 'vi' + unique,
    charging: 'vc' + unique,
    load: 'vl' + unique
  },
  radial: {
    white: 'rw' + unique,
    charging: 'rc' + unique,
    injecting: 'ri' + unique,
    load: 'rl' + unique
  }
}

export function gradient(orientation, name) {
  return `url(#${GRADIENTS[orientation][name]})`
}

function Gradients({ colors }) {
  return (
    <defs>
      <linearGradient id={GRADIENTS.horiz.injecting} x1="0" x2="1" y1="0" y2="0">
        <stop offset="0%" stopColor={colors.injecting} stopOpacity={1} />
        <stop offset="100%" stopColor={colors.injecting} stopOpacity={0} />
      </linearGradient>
      <linearGradient id={GRADIENTS.horiz.charging} x1="0" x2="1" y1="0" y2="0">
        <stop offset="0%" stopColor={colors.charging} stopOpacity={1} />
        <stop offset="100%" stopColor={colors.charging} stopOpacity={0} />
      </linearGradient>
      <linearGradient id={GRADIENTS.horiz.load} x1="0" x2="1" y1="0" y2="0">
        <stop offset="0%" stopColor={colors.load} stopOpacity={1} />
        <stop offset="100%" stopColor={colors.load} stopOpacity={0} />
      </linearGradient>
      <linearGradient id={GRADIENTS.horiz.dark} x1="0" x2="1" y1="0" y2="0">
        <stop offset="0%" style={{ stopColor: '#000', stopOpacity: 0 }} />
        <stop offset="100%" style={{ stopColor: '#000', stopOpacity: 0.5 }} />
      </linearGradient>
      <linearGradient
        id={GRADIENTS.horiz.darkReverse}
        x1="0"
        x2="1"
        y1="0"
        y2="0"
      >
        <stop offset="0%" style={{ stopColor: '#000', stopOpacity: 0.5 }} />
        <stop offset="100%" style={{ stopColor: '#000', stopOpacity: 0 }} />
      </linearGradient>
      <linearGradient id={GRADIENTS.vert.dark} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" style={{ stopColor: '#000', stopOpacity: 0 }} />
        <stop offset="100%" style={{ stopColor: '#000', stopOpacity: 0.5 }} />
      </linearGradient>
      <linearGradient id={GRADIENTS.vert.darkUp} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" style={{ stopColor: '#000', stopOpacity: 0.5 }} />
        <stop offset="100%" style={{ stopColor: '#000', stopOpacity: 0 }} />
      </linearGradient>
      <linearGradient id={GRADIENTS.vert.light} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" style={{ stopColor: '#fff', stopOpacity: 0.7 }} />
        <stop offset="100%" style={{ stopColor: '#fff', stopOpacity: 0 }} />
      </linearGradient>
      <linearGradient id={GRADIENTS.vert.lightUp} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" style={{ stopColor: '#fff', stopOpacity: 0 }} />
        <stop offset="100%" style={{ stopColor: '#fff', stopOpacity: 0.7 }} />
      </linearGradient>
      <linearGradient id={GRADIENTS.vert.injecting} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={colors.injecting} stopOpacity={1} />
        <stop offset="100%" stopColor={colors.injecting} stopOpacity={0} />
      </linearGradient>
      <linearGradient id={GRADIENTS.vert.charging} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={colors.charging} stopOpacity={1} />
        <stop offset="100%" stopColor={colors.charging} stopOpacity={0} />
      </linearGradient>
      <linearGradient id={GRADIENTS.vert.load} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={colors.load} stopOpacity={1} />
        <stop offset="100%" stopColor={colors.load} stopOpacity={0} />
      </linearGradient>
      <radialGradient id={GRADIENTS.radial.white}>
        <stop offset="0%" stopColor="#ffffff" stopOpacity="1.0" />
        <stop offset="100%" stopColor="#ffffff" stopOpacity="0.0" />
      </radialGradient>
      <radialGradient id={GRADIENTS.radial.injecting}>
        <stop offset="0%" stopColor={colors.injecting} stopOpacity="1.0" />
        <stop offset="100%" stopColor={colors.injecting} stopOpacity="0.0" />
      </radialGradient>
      <radialGradient id={GRADIENTS.radial.charging}>
        <stop offset="0%" stopColor={colors.charging} stopOpacity="1.0" />
        <stop offset="100%" stopColor={colors.charging} stopOpacity="0.0" />
      </radialGradient>
      <radialGradient id={GRADIENTS.radial.load}>
        <stop offset="0%" stopColor={colors.load} stopOpacity="1.0" />
        <stop offset="100%" stopColor={colors.load} stopOpacity="0.0" />
      </radialGradient>
    </defs>
  )
}

export function GradientRect({ fill, children: child }) {
  const { x, y, width, height } = child.props
  return (
    <>
      {child}
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        className="pointer-events-none"
      />
    </>
  )
}

export default Gradients
