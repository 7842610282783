import React from 'react'
import { useParams } from 'react-router-dom'

import paths from 'constants/paths'

import Comments from 'components/Forums/Comments'

function Forum() {
  const { subId, targetId } = useParams()
  return (
    <Comments
      topicId={subId}
      parentLink={`${paths.project}/${targetId}/${paths._forum}/${subId}`}
    />
  )
}
export default Forum
