import React, { useCallback, useContext } from 'react'

import { useMutation } from '@apollo/client'

import Confirm from 'tools/Confirm'
import notifier from 'tools/Notify/notifier'
import RadioButtons from 'tools/RadioButtons'

import PersonLabel from 'components/Person/tools//Label'

export const defaultEdit = {
  member: {},
  user: undefined,
  invited: false,
  isNew: false,
  delete: false,
  error: undefined
}

function Edit({
  editState: [edit, setEdit],
  types,
  mutate,
  onChange,
  roles = undefined,
  orgId = undefined,
  projectId = undefined
}) {
  const [, nDispatch] = useContext(notifier.Context)
  const [updateMember] = useMutation(mutate)
  const saveMember = useCallback(
    (action, type, roles) => {
      updateMember({
        variables: {
          orgId,
          projectId,
          member: {
            id: edit.user && edit.user.id,
            email: edit.email,
            action,
            type,
            roles
          }
        },
        update(cache, d) {
          const { meta, success, result, reason } = d.data[Object.keys(d.data)[0]]
          if (meta?.invited) {
            onChange(result)
            notifier.info(nDispatch, 'Your invitation has been sent!')
            setEdit(undefined)
          } else {
            if (success) {
              onChange(result)
              setEdit(undefined)
            } else {
              notifier.error(nDispatch, reason)
            }
          }
        }
      })
    },
    [nDispatch, edit, onChange, orgId, projectId, setEdit, updateMember]
  )

  if (edit.invited) {
    return (
      <div
        className="flex-center mt5"
        style={{ height: '60%', minHeight: '60%' }}
      >
        <div>
          <div className="f3 mb5">Your invitation has been sent!</div>
          <div className="tc">
            <button className="button large" onClick={() => setEdit(undefined)}>
              Ok
            </button>
          </div>
        </div>
      </div>
    )
  }

  const valid = isValid(edit.member, roles)

  return (
    <>
      <label className="mt4 mb3 heading">
        {edit.isNew ? 'Invite' : null} Team Member
      </label>
      <div className="flex-between">
        {edit.email ? (
          <code>{edit.email}</code>
        ) : (
          <PersonLabel person={edit.user} />
        )}
      </div>
      <MemberType
        type={edit.member.type}
        types={types}
        setType={(t) => setEdit({ ...edit, member: { ...edit.member, type: t } })}
      />
      <MemberRoles roles={roles} editState={[edit, setEdit]} />
      <div className="flex mt4">
        {edit.isNew ? null : (
          <button
            className="button large plain"
            onClick={() => setEdit({ ...edit, delete: true })}
          >
            <i className="fas fa-trash mr2" /> Remove
          </button>
        )}
        <button
          className="button large ml-auto"
          disabled={!valid}
          onClick={() =>
            saveMember('upsert', edit.member.type, edit.member.roles)
          }
        >
          {edit.isNew ? 'Invite' : 'Save'}
        </button>
      </div>
      {edit.delete ? (
        <Confirm
          viewState={[edit.delete, () => setEdit({ ...edit, delete: false })]}
          onConfirm={() => {
            saveMember('delete')
            setEdit(undefined)
          }}
        >
          <div className="mv4">Remove {edit.user.name}?</div>
          {edit.error ? <div className="red mb4">{edit.error}</div> : null}
        </Confirm>
      ) : null}
    </>
  )
}

function isValid(mbr, roles) {
  if (!mbr.type) return false
  if (requiresRoles(mbr, roles)) {
    return mbr.roles?.length > 0
  }
  return true
}

function requiresRoles(mbr, roles) {
  return roles && (mbr.type === 'admin' || mbr.type === 'contributor')
}

function MemberRoles({ roles, editState: [edit, setEdit] }) {
  const setRadioRoles = useCallback(
    ({ orig, type, token, boolTrue }, selected) => {
      if (orig.includes(selected)) {
        setEdit({
          ...edit,
          member: { ...edit.member, roles: orig.filter((i) => i !== selected) }
        })
      } else {
        setEdit({
          ...edit,
          member: { ...edit.member, roles: orig.concat(selected) }
        })
      }
    },
    [setEdit, edit]
  )

  if (!requiresRoles(edit.member, roles)) return null

  return (
    <>
      <label className="mt3 mb2 heading">Roles</label>
      <RadioButtons
        className="mt3 f7"
        value={edit.member.roles || []}
        variant="column"
        type="multiple"
        opts={roles}
        onSave={setRadioRoles}
        token="roles"
      />
    </>
  )
}

function MemberType({ types, type, setType }) {
  return (
    <div className="mt3">
      <RadioButtons
        className="f7"
        value={type}
        opts={Object.keys(types)}
        onSave={(params, value) => setType(value)}
        token="type"
      />
      <div className="f6 i mt3">Access: {types[type]}</div>
    </div>
  )
}

export default Edit
