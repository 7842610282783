import paths from 'constants/paths'

import { intcmp } from 'utils/string'

// this comes in from a direct call to reactorChallenges()
export function normalizeChallenges(challenges, milestonesD, projectId) {
  const challengesD = challenges.reduce((challengesD, challenge) => {
    challenge = { ...normalizeChallenge(challenge) }
    challenge.name = interpolateName(challenge.name, projectId)
    challengesD[challenge.id] = Object.freeze(challenge)
    return challengesD
  }, {})

  const top = Object.keys(challengesD).reduce(
    (top, cId) => {
      return challengesD[cId].linksL.reduce((top, id) => {
        delete top[id]
        return top
      }, top)
    },
    { ...challengesD }
  )

  let challengesL = Object.keys(top).reduce((challengesL, id) => {
    if (challengesD[id].visible) {
      return challengesL.concat(id)
    }
    return challengesL.concat(challengesD[id].linksL)
  }, [])

  challengesL = Array.from(new Set(challengesL)).toSorted((b, a) =>
    intcmp(challengesD[b]?.order || 0, challengesD[a]?.order || 0)
  )

  return { challengesD, challengesL }
}

/*
Challenges are recursive.

You have links of Challenges and challenges can have links of challenges.
*/
function normalizeChallengeLink({ id: linkId, order, challenge, challengeId }) {
  return Object.freeze({
    // we get id from the challenge, unless it's just a ref with challengeId,
    id: challengeId,
    ...normalizeChallenge(challenge),
    linkId,
    order
  })
}

export function normalizeChallenge(challenge) {
  if (!challenge) return {}
  const {
    id,
    name = undefined,
    rname = undefined,
    explain = undefined,
    visible = undefined,
    context = undefined,
    links = [],
    triggers = [],
    conditions = undefined,
    actions = undefined,
    completed = undefined,
    journal = undefined,
    meta = undefined,
    originId = undefined,
    originType = undefined,
    originKey = undefined
  } = challenge

  return Object.freeze({
    id,
    name,
    rname,
    milestoneId: meta?.milestone_id,
    explain,
    visible,
    context,
    triggers,
    conditions,
    actions,
    completed,
    journal,
    meta,
    originId,
    originType,
    originKey,
    jaMapId: undefined,
    ...normalizeChallengeLinks(links)
  })
}

export function normalizeChallengeLinks(links = []) {
  const linksD = links.reduce((linksD, link) => {
    // because id changes, do this in two steps
    link = normalizeChallengeLink(link)
    linksD[link.id] = link
    return linksD
  }, {})
  const linksL = Object.values(linksD)
    .toSorted((a, b) => intcmp(a.order, b.order))
    .map((l) => l.id)
  return { linksD, linksL }
}

////////////////////////////////////////////////////////////////////////////////
function interpolateName(name, projectId) {
  return name.replace(/(\${link:([^}]+)})/g, (a, b, c) =>
    interpolateLookup(c, projectId)
  )
}

function interpolateLookup(token, projectId) {
  const [type, ...rest] = token.split(':')
  switch (type) {
    case 'files':
      return `${paths.project}/${projectId}/files`
    case 'topics':
      return `${paths.project}/${projectId}`
    case 'members':
    case 'invite': // this goes away
      return `${paths.project}/${projectId}/members`
    case 'profile':
      return `${paths.project}/${projectId}/profile`
    case 'goals':
      return `${paths.project}/${projectId}/goals`
    case 'map': {
      const [jmId, qId] = rest
      if (qId) {
        return `${paths.project}/${projectId}/rubrics/${jmId}/${qId}`
      } else {
        return `${paths.project}/${projectId}/rubrics/${jmId}`
      }
    }
    default:
      return 'bad link'
  }
}
