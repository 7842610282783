import React, { useCallback, useContext } from 'react'

import FileEditor from 'tools/Files'
import Help from 'tools/Files/Help'
import { sizeOf } from 'utils/string'

import ProjectContext, { R_PROJECT } from '../reducer'

function Files() {
  const [project, dispatch] = useContext(ProjectContext)
  const onAdd = useCallback(
    (value) => dispatch({ type: R_PROJECT.UPSERT_FILE, value }),
    [dispatch]
  )
  const onDelete = useCallback(
    (value) => dispatch({ type: R_PROJECT.DELETE_FILE, value }),
    [dispatch]
  )

  const percent = Math.round(
    ((project.d.license?.storage || 0) / (project.d.license?.quota || 1)) * 100
  )
  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <div className="flex-items">
        <label className="mb3 heading f4">
          <i className="fas fa-folder-open mr2" />
          Venture Files
          <Help className="ml2" title="Upload Types" />
        </label>
        {project.d?.license?.storage && (
          <div className="ml-auto f7 ba br3 theme-b-minimize pv1 ph2">
            {sizeOf(project.d?.license?.storage)} of{' '}
            {sizeOf(project?.d?.license?.quota)} used ({percent}%)
          </div>
        )}
      </div>

      <FileEditor
        fileTypes={project.fileTypes}
        onAdd={onAdd}
        onDelete={onDelete}
        refType="project"
        refId={project.id}
      />
    </div>
  )
}

export default Files
