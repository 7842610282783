import React, { useContext } from 'react'

import { useMutation } from '@apollo/client'

import SelectTags, { TAGS_CHANGE_ORG, tagUpdate } from 'tools/SelectTags'

import { OrgContext, R_ORG } from '../reducer'

function Tags() {
  const [org, dispatch] = useContext(OrgContext)
  const [tagOrg] = useMutation(TAGS_CHANGE_ORG)
  const selectTag = (value, mapType, tagType, multi = undefined) => {
    tagUpdate(
      tagOrg,
      { value, mapType, tagType, multi, ref: 'org', refId: org.id },
      (cache, result) => {
        if (result.tagsChange?.success) {
          dispatch({ type: R_ORG.SET_TAGS, value: result.tagsChange.org.tags })
        }
      }
    )
  }
  return (
    <>
      <SelectTags
        selectTag={selectTag}
        create={true}
        title="Organization Type"
        tagType="org_type"
        mapType="org_type"
        target={org}
        placeholder="select types"
        defaultOptions={true}
        multi={true}
      />
      <SelectTags
        selectTag={selectTag}
        create={true}
        title="Preferred Markets"
        tagType="market"
        mapType="market"
        target={org}
        placeholder="select markets"
        defaultOptions={true}
        multi={true}
      />
    </>
  )
}

export default Tags
