import moment from 'moment'

import { intcmp } from 'utils/string'

import { normalizeProject } from 'components/Project/normalize'

import tabs from './tabs'

////////////////////////////////////////////////////////////////////////////////
const defaultApplication = {
  showOrg: true
}

export const defaultPortfolio = {
  id: undefined,
  shortId: undefined,
  name: '',
  columnsL: [],
  columnsD: {},
  membersL: [],
  membersD: {},
  cohortsL: [],
  cohortsD: {},
  showCohorts: {},
  application: defaultApplication
}

export const defaultStudio = {
  tabs,
  tabProps: { activity: {} },
  onSave: undefined,
  _normal: false,
  ...defaultPortfolio
}

export const defaultField = {
  id: undefined,
  type: 'line',
  target: 'project',
  explain: '',
  question: '',
  meta: {}
}

////////////////////////////////////////////////////////////////////////////////
export function normalizePortfolio(state, update) {
  let studio = { ...state, ...update }

  studio = normalizeList(studio, 'columns')
  studio = normalizeList(studio, 'cohorts')
  // temp
  studio.showCohorts = studio.cohortsL.reduce((d, id) => {
    d[id] = true
    return d
  }, {})

  const cols = studio.columnsL
  const members = (studio.members || [])
    .map((pm) => {
      const updated = moment(pm.updatedAt)
      // TEMP
      let columnId =
        studio.columnsL[Math.floor(Math.random() * studio.columnsL.length)]
      let cohorts = [
        studio.cohortsL[Math.floor(Math.random() * studio.cohortsL.length)]
      ]
      return {
        ...pm,
        columnId,
        cohorts,
        _updated: updated,
        _updated_t: updated.unix(),
        project: normalizeProject(pm.project)
      }
    })
    .toSorted((b, a) => intcmp(a.project.title, b.project.title))

  studio.membersL = members.map((m) => m.id)
  studio.membersD = members.reduce((d, m) => {
    m.project._updated_t = moment(m.project.updatedAt).unix()
    d[m.id] = m
    return d
  }, {})

  // studio = normalizeList(studio, 'members')
  studio.memberStatus = members.reduce((d, m) => {
    if (!d[m.status]) {
      d[m.status] = []
    }
    d[m.status].push(m.id)
    return d
  }, {})

  studio.memberCohorts = members.reduce((d, m) => {
    let { columnId } = m
    if (!columnId) {
      // temp -- it should put them in the "unassigned" top column
      columnId = cols[Math.floor(Math.random() * cols.length)]
    }
    if (!d[columnId]) {
      d[columnId] = []
    }
    d[columnId].push(m.id)
    return d
  }, {})

  studio = normalizeApplication(studio)
  studio._normal = true
  return studio
}

export function normalizeList(state, keyword) {
  const list = state[keyword]
  if (list) {
    const l = list.toSorted((a, b) => intcmp(a.order, b.order)).map((a) => a.id)
    const d = list.reduce((a, c) => {
      a[c.id] = c
      return a
    }, {})
    state[keyword + 'L'] = l
    state[keyword + 'D'] = d
    delete state[keyword]
  }
  return state
}

export function upsertCohort(state, cohort) {
  state = { ...state }
  state.cohortsD[cohort.id] = cohort
  state.cohorts = Object.values(state.cohortsD)
  return normalizeList(state, 'cohorts')
}

export function normalizeApplication(portfolio) {
  const app = {
    ...(portfolio.application || defaultApplication),
    portfolio
  }
  if (portfolio.applicationFields) {
    app.fieldsL = (portfolio.applicationFields || [])
      .toSorted((a, b) => intcmp(a.order, b.order))
      .map((a) => a.id)
    app.fieldsD = (portfolio.applicationFields || []).reduce((d, v) => {
      d[v.id] = normalizeField(v)
      return d
    }, {})
    delete portfolio.applicationFields
  }

  portfolio.application = app
  return portfolio
}

function normalizeField(field) {
  field = { ...field }
  field.question = field.qstring || ''
  field.explain = field.estring || ''
  field.meta = field.meta || {}
  return field
}

function upsertField(state, field) {
  state = { ...state }
  field = normalizeField(field)
  const fieldsD = { ...state.application.fieldsD, [field.id]: { ...field } }
  const fieldsL = Object.values(fieldsD)
    .toSorted((a, b) => intcmp(a.order, b.order))
    .map((f) => f.id)
  state.application = { ...state.application, fieldsD, fieldsL }
  return state
}

export function changeField(state, value, args) {
  const { fieldId, token, meta } = args
  let field = state.application.fieldsD[fieldId]
  if (meta) {
    field = { ...field, meta: { ...field.meta, [token]: value } }
  } else {
    field = { ...field, [token]: value }
  }
  const fieldsD = {
    ...state.application.fieldsD,
    [fieldId]: field
  }
  return { ...state, application: { ...state.application, fieldsD } }
}

export function changeUpsert(component, orig, value) {
  switch (component) {
    case 'portfolio':
      return normalizePortfolio(orig, value)
    case 'cohort':
      return upsertCohort(orig, value)
    case 'field':
      return upsertField(orig, value)
    default:
      return orig
  }
}

export function changeDelete(component, orig, value) {
  switch (component) {
    case 'portfolio':
      return orig
    // return normalizePortfolio(orig, value)
    case 'cohort': {
      const state = { ...orig }
      delete state.cohortsD[value.id]
      state.cohortsL = state.cohortsL.filter((id) => id !== value.id)
      return state
    }
    // return upsertCohort(orig, value)
    case 'field': {
      // const state = {...orig, application: {...state.application}}
      const application = { ...orig.application }
      application.fieldsD = { ...application.fieldsD }
      delete application.fieldsD[value.id]
      application.fieldsL = application.fieldsL.filter((id) => id !== value.id)
      return { ...orig, application }
    }
    default:
      return orig
  }
}
