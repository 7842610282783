import gql from 'graphql-tag'

export const LIST_TAGS = gql`
  query tags($type: String!, $matching: String) {
    tags(type: $type, matching: $matching) {
      id
      label
      type
      meta
    }
  }
`

export const TAGS_CHANGE_MINE = gql`
  mutation tagChangeMine(
    $id: String!
    $type: String!
    $public: Boolean
    $meta: Json
  ) {
    tagChangeMine(id: $id, type: $type, public: $public, meta: $meta) {
      success
      reason
      user {
        id
        tags {
          id
          type
          public
          tag {
            id
            label
            meta
          }
        }
      }
    }
  }
`

export const TAGS_CHANGE_USER = gql`
  mutation tagsChange($tags: InputTagsChange!) {
    tagsChange(tags: $tags) {
      success
      reason
      user {
        id
        tags {
          id
          type
          public
          tag {
            id
            label
            meta
          }
        }
      }
    }
  }
`

export const TAGS_CHANGE_PROJECT = gql`
  mutation tagsChange($tags: InputTagsChange!) {
    tagsChange(tags: $tags) {
      success
      reason
      project {
        id
        tags {
          id
          tag {
            id
            label
            type
            meta
          }
        }
      }
    }
  }
`

export const TAGS_CHANGE_ORG = gql`
  mutation tagsChange($tags: InputTagsChange!) {
    tagsChange(tags: $tags) {
      success
      reason
      org {
        id
        tags {
          id
          tag {
            id
            label
            type
            meta
          }
        }
      }
    }
  }
`
