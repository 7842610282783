export function dig(target, keys) {
  keys = keys.split('.')
  for (const key of keys) {
    if (typeof target === 'undefined' || target === null) {
      return undefined
    }
    target = target[key]
  }
  return target
}

export function put(dict, key, value) {
  dict[key] = value
  return dict
}
