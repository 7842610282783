import React, { useContext } from 'react'

import Welcome from './Welcome'
import { ApplyContext, R_APPLY } from './reducer'

function AskUser() {
  return (
    <div className="doc">
      <label className="mt4 mb3 primary f4">Tell us about yourself</label>
      <p className="mv2 f4" />
      <Welcome />
      <p className="mv2 f4" />
      Do you already have a Venturly account?
      <div className="mv4 flex-center">
        <Clicky value={false}>Sign-in now</Clicky>
        <Clicky value={true}>Create a new account</Clicky>
      </div>
    </div>
  )
}

function Clicky({ value, children }) {
  const [, dispatch] = useContext(ApplyContext)
  return (
    <button
      className="mh2 medium"
      onClick={() => dispatch({ type: R_APPLY.MERGE, value: { login: value } })}
    >
      {children}
    </button>
  )
}

export default AskUser
