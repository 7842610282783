import React, { useContext } from 'react'

import InputData from '../Profile/Input'
import ProjectContext from '../reducer'

function FundingGoal({ onChange, onSave }) {
  const [project] = useContext(ProjectContext)

  const needz = project?.d?.needs

  let goalsVisible = false
  if (project.journeyMapsL.length > 0) {
    const has_progress = project.journeyMapsL.find((id) => {
      if (project.journeyMapsD[id].milestone.order > 0) {
        return true
      }
      return false
    })
    if (has_progress) {
      goalsVisible = true
    }
  }

  if (!needz) return null

  return (
    <>
      <label className="mb3">Funding Goal</label>
      {goalsVisible ? (
        <div>
          <InputData
            value={needz.goal || ''}
            className="w-30"
            onChange={onChange}
            token="goal"
            component="needs"
            placeholder="target funding amount"
            prefix={<div className="gray mr2">$</div>}
            numeric={true}
            onSave={onSave}
            display={
              <ul className="less">
                <li>${needz.goal}</li>
              </ul>
            }
          />
          <InputData
            value={needz.purpose}
            onChange={onChange}
            token="purpose"
            component="needs"
            label="Purpose"
            className="mt4"
            placeholder="How do you plan on using these funds?"
            onSave={onSave}
            type="text"
          />
        </div>
      ) : (
        <div>
          Funding goals are not available until after you advance one milestone in
          a journey.
        </div>
      )}
    </>
  )
}

export default FundingGoal
