import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Svg from 'tools/Svg'

const strokeSize = 4
const defaultMilestone = {
  name: ' ',
  meta: { style: { primary: '#999', text: '#000' } }
}

const defaultJmap = {
  milestone: 'a',
  journey: {
    milestonesL: ['a', 'b', 'c', 'd'],
    milestonesD: {
      a: { ...defaultMilestone, id: 'a', order: 0 },
      b: { ...defaultMilestone, id: 'b', order: 1 },
      c: { ...defaultMilestone, id: 'c', order: 2 },
      d: { ...defaultMilestone, id: 'd', order: 3 }
    }
  }
}

function Arrows({ jmap, className = '', linkPath = undefined }) {
  const { stage: show = jmap.milestone?.name || '' } = useParams()
  const history = useHistory()
  const onClick = useCallback(
    (ev, stage) => {
      if (linkPath) {
        ev.preventDefault()
        ev.stopPropagation()
        history.push(linkPath(stage))
      }
    },
    [linkPath, history]
  )

  if (!jmap.milestoneId || !jmap._normal) {
    jmap = defaultJmap
  }
  const { milestonesL, milestonesD } = jmap.journey
  const width = 816 // max-viewport (55rem) - 1rem for each left/right padding
  const height = 40
  const gutter = 10
  const section = (width - height / 2 - gutter) / milestonesL.length

  // perhaps move these into a useEffect() -- performance profile needed
  const current = jmap.milestone
  let outline = milestonesL.findIndex((mId) => milestonesD[mId].name === show)
  if (outline === -1) {
    outline = current?.order
  }

  const { points } = getPoints({
    x: section * outline + gutter,
    y: gutter,
    gutter,
    height,
    width: section,
    big: current?.name === show
  })

  return (
    <Svg
      width={width}
      height={height + gutter * 2 + strokeSize * 2}
      className={className}
    >
      {milestonesL.map((mId, x) => (
        <Milestone
          key={x}
          x={section * x + gutter}
          y={gutter}
          height={height}
          milestone={milestonesD[mId]}
          width={section}
          current={current}
          gutter={gutter}
          big={current.id === mId}
          onClick={onClick}
        />
      ))}
      {
        <polygon
          points={points}
          fill="transparent"
          className="b--hilite"
          strokeWidth={`${strokeSize}px`}
          onClick={(ev) => onClick(ev, show)}
        />
      }
    </Svg>
  )
}

function Milestone({
  milestone: {
    id,
    order,
    meta: { style },
    name
  },
  x,
  y,
  height,
  width,
  current,
  big,
  gutter,
  onClick
}) {
  const { left, top, points, xmid, ymid } = getPoints({
    x,
    y,
    gutter,
    height,
    width,
    big
  })

  const complete = current.order > order

  return (
    <>
      <polygon points={points} fill={style.primary} />
      <text
        textAnchor="middle"
        dominantBaseline="central"
        x={left + xmid}
        y={top + ymid}
        className={`fw6 ${complete ? 'strike' : ''}`}
        style={{ fill: style.text }}
      >
        {name}
      </text>
    </>
  )
}
// <polygon
//   points={points}
//   fill="transparent"
//   className="hover-hilite"
//   onClick={(ev) => onClick(ev, name)}
// />

function getPoints({ x, y, height, width, gutter, big }) {
  let left = x
  if (isNaN(left)) {
    return {}
  }
  let top = y + strokeSize

  if (big) {
    left = x - gutter
    top = y - gutter + strokeSize
    height = height + gutter + gutter
  }
  const ymid = height / 2
  const xmid = width / 2
  return {
    left,
    top,
    xmid,
    ymid,
    points: `${left},${top} ${left + width},${top} ${left + width + ymid},${
      top + ymid
    } ${left + width},${top + height} ${left},${top + height} ${left + ymid},${
      top + ymid
    }`
  }
}

export default Arrows
