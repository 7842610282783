// while we could use interpolation for more editability, this is more efficient
const mod = {
  noId: '_',
  graphql: '/graphql',
  authapi: '/authx/v1/api/',
  forum: 'r',
  // note: /ev is a direct route to the backend for email verification
  person: '/prefs',
  personPublic: '/u',
  personPublicRoute: '/u/:targetId?',
  personRoute: '/prefs/:targetId?/:tabId?/:code?',
  preferences: '/prefs/_/profile',
  unsubscribe: '/prefs/_/notify',
  password: '/prefs/_/pwreset',
  org: '/o',
  orgRoute: '/o/:orgId?/:tabId?/:folioId?/:projectId?',
  orgCreate: '/o/_/create',
  orgMine: '/o/_/mine',
  studio: '/s',
  studioRoute: '/s/:orgId?/:tabId?/:folioId?/:action?',
  apply: '/a',
  applyRoute: '/a/:folioId/:projectId?',
  project: '/p',
  projectRoute: '/p/:targetId?/:tabId?/:subId?/:subSubId?',
  // projectRoute: '/p/:targetId?/:tabId?/:subId?', // /:subSubId?',
  projectCreate: '/p/_/create',
  projectMine: '/p/_/mine',
  feed: '/f',
  learn: '/l',
  learnCreate: '/l/_/edit',
  learnRoute: '/l/:targetId?/:action?',
  meet: '/m',
  meetRoute: '/m/:targetId?/:tabId?/:eventId?',
  meetCreate: '/m/_/create',
  meetMine: '/m/_/mine',
  meetAdmin: '/m/_/admin',
  home: '/h',
  docs: '/d',
  docsRoute: '/d/:tab?',
  signon: '/signon',
  signup: '/signup',
  signout: '/signout',
  returnCode: '/c',
  returnCodeRoute: '/c/:action?/:shortId?',
  admJourney: '/adm/journey',
  admJourneyRoute: '/adm/journey/:targetId?/:tabId?/:subId?',
  admUser: '/adm/user',
  admUserRoute: '/adm/user',
  admMentor: '/adm/mentor',
  admMentorRoute: '/adm/mentor/:tabId?',
  admSummary: '/adm/summary',
  admSummaryRoute: '/adm/summary/:tabId?'
}
export default mod
