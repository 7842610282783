import { makeTab } from 'tools/NavTabs'

import { StudioDeepLink as Studio } from 'components/Studio'

import Config from './Config'
import Members from './Members'
import Profile from './Profile'

////////////////////////////////////////////////////////////////////////////////
const mod = [
  makeTab({
    id: 'profile',
    name: 'Profile',
    orig: true,
    Component: Profile
  }),
  makeTab({
    id: 'members',
    name: 'Members',
    Component: Members
  }),
  makeTab({
    id: 'config',
    name: 'Config',
    Component: Config
  }),
  makeTab({
    id: 'folios',
    name: 'Studio',
    path: ({ params: { orgId } }) => `/s/${orgId}`,
    Component: Studio,
    view: () => false
  })
]
export default mod
