import { dig } from 'utils/dict'

export function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1)
}

// doesn't belong here, oh well
export function intcmp(a, b) {
  return a < b ? -1 : a === b ? 0 : 1
}

export function boolcmp(a, b) {
  return a === b ? 0 : -1
}

export function strcmp(a, b) {
  return a.localeCompare(b, 'en', {
    sensitivity: 'base',
    numeric: 'true'
  })
}

// function cacheTime(obj, key, _key) {
//   if (!obj[_key]) {
//     obj[_key] = moment(obj[key]).unix()
//   }
//   return obj
// }
//
// export function datecmp(a, b, key, _key) {
//   a = cacheTime(a, key, _key)
//   b = cacheTime(b, key, _key)
//   return intcmp(a[_key], b[_key])
// }

const pgMax = 2147483646
export function inputAsInt(input = '') {
  let cvalue = `${input.replace(/[^0-9]/g, '')}`
  if (cvalue === '') {
    cvalue = '0'
  }
  let value = parseInt(cvalue)
  if (value >= pgMax) {
    value = pgMax
  }
  return { value }
}

export function plural(number, label) {
  return `${number || 0} ${label}${number !== 1 ? 's' : ''}`
}

function ffmt(num) {
  return num.toFixed(0)
}
export function sizeOf(bytes) {
  if (bytes >= 1024 * 1024 * 1024) {
    return `${ffmt(bytes / 1024 / 1024 / 1024)} Gb`
  } else if (bytes >= 1024 * 1024) {
    return `${ffmt(bytes / 1024 / 1024)} Mb`
  } else if (bytes >= 1024) {
    return `${ffmt(bytes / 1024)} Kb`
  } else if (bytes === null) {
    bytes = 0
  }
  return `${bytes} b`
}

export function interpolate(string, keymap, markdown = '') {
  return string.split(/(#{[^{}]*)}/g).map((part) => {
    if (part[0] === '#' && part[1] === '{') {
      const value = dig(keymap, part.substring(2) || '')
      return `${markdown}${value}${markdown}`
    }
    return part
  })
}
