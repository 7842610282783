import React from 'react'

import { ExtLink } from 'tools/Links'
import { fromNow } from 'utils/time'

function Summary({ org }) {
  if (!org._normal) {
    return null
  }
  let location = [
    org.location.city,
    org.location.state,
    org.location.country
  ].join(', ')
  location = location.replace(/(,\s*,)+/g, ', ').replace(/(^,|, ?$)/g, '')
  return (
    <div className="flex mb4">
      <div style={{ maxWidth: '10rem' }}>
        <img src={org.avatar.url} alt="" className="br2" />
      </div>
      <div className="ml3 flex flex-column justify-between w-100 h-100">
        <div className="f2 b">{org.name}</div>
        <div className="flex justify-between">
          <div className="i primary f4">
            {org.tagsD.org_type.map((t) => t.label).join(', ')}
          </div>
          <div>{location}</div>
        </div>
        {org.locales || org.invest_range ? (
          <div className="mt2">
            {org.locales}
            {org.locales && org.invest_range ? <>: </> : null}
            {org.invest_range}
          </div>
        ) : null}
        <div className="fw2 i mt2">
          {org.tagsD.market.map((t) => t.label).join(', ')}
        </div>
        <div className="f7 mt2 flex-items">
          <i className="fas fa-comment-alt mr2 gray" />
          {org.summary.comments}
          <i className="fas fa-clock ml3 mr2 gray" />
          Last Updated: {fromNow(org.updatedAt)}
          {org.website ? (
            <ExtLink to={org.website} className="flex-items f6 ml-auto">
              {org.website} <i className="fas fa-external-link-alt ml2" />
            </ExtLink>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Summary
