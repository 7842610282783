import React, { createContext, useReducer } from 'react'

import { useDelay, usePage, useTitle } from './hooks'
import { R_GLOBAL, reducer } from './reducer'
import { initialState } from './state'

export const GlobalContext = createContext(null)
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { useDelay, usePage, useTitle, R_GLOBAL }
