import React from 'react'

import Cohorts from './Cohorts'
import Columns from './Columns'

function Workflow() {
  return (
    <div className="theme-frame-tabbed max-view-page pa4 mb6">
      <Cohorts className="mb3" />
      <Columns />
    </div>
  )
}

export default Workflow
