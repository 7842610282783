import apollo from 'utils/apollo'
import { authXinitialState } from 'utils/signon'
import { defaultUser } from 'utils/user'

import { randomBackground } from 'components/Root/Background/backgrounds'
// import { Cookies } from 'react-cookie'
//
// export const cookies = new Cookies()
// export const COOKIE_NAME = 'p'
import { bar, menu } from 'components/Root/NavBar/config'

////////////////////////////////////////////////////////////////////////////////
export const initialState = {
  apolloInit: false,
  query: {},
  saving: false,
  savingKeys: {},
  prefs: {
    orgList: {
      name: ''
    },
    orgList_t: 0,
    projectList: {
      goal: 'any',
      needs: [],
      files: [],
      activity: 'quarter',
      name: ''
    },
    projectList_t: 0,
    connect: {
      name: '',
      topics: []
    },
    connect_t: 0,
    skip: true
  },
  page: {
    title: undefined,
    image: randomBackground(),
    background: 'flat',
    theme: 'dark'
  },
  querys: Object.freeze({
    search: '',
    r: [],
    q: [],
    c: [],
    parsed: new URLSearchParams()
  }),
  nav: { bar, menu },
  history: [window.location.pathname],
  apollo: apollo(() => {}),
  interfaceConfig: {},
  user: defaultUser(),
  authx: authXinitialState()
}
