import gql from 'graphql-tag'

import { projectListValues } from 'components/Project/graphql'

const folioListVars = `
id
shortId
name
summary
status
updatedAt
`
export const applicationValues = `
id
order
qstring
estring
type
target
required
meta
`

const folioMemberLoadVars = `
  id
  status
  application
  project {
    ${projectListValues}
  }
  columnId
  cohorts {
    id
  }
  updatedAt
`

const columnLoadVars = `
id
order
transition
name
`
const cohortLoadVars = `
id
name
`

const folioLoadVars = `
orgId
application
style
applicationFields {
  ${applicationValues}
}
columns {
  ${columnLoadVars}
}
cohorts {
  ${cohortLoadVars}
}
members {
  ${folioMemberLoadVars}
}
`
// TODO: members should be tabulated and as a separate query, this will get too
// big over time
// -- possibly even application should be loaded separately

export const PORTFOLIOS = gql`
  query portfolios($orgId: String!) {
    portfolios(orgId: $orgId) {
      ${folioListVars}
    }
  }
`

export const LOAD_PORTFOLIO = gql`
  query portfolio($orgId: String!, $id: String!) {
    portfolio(orgId: $orgId, id: $id) {
      success
      reason
      portfolio {
        ${folioListVars}
        ${folioLoadVars}
      }
    }
  }
`

export const UPSERT_PORTFOLIO = gql`
  mutation upsertPortfolio($portfolio: InputPortfolio, $cohort: InputPortfolioCohort, $column: InputPortfolioColumn, $orgId: String!, $field: InputApplicationField) {
    upsertPortfolio(portfolio: $portfolio, cohort: $cohort, column: $column, orgId: $orgId, field: $field) {
      success
      reason
      deleted
      cohort {
        ${cohortLoadVars}
      }
      column {
        ${columnLoadVars}
      }
      portfolio {
        ${folioListVars}
        application
        style
      }
      field {
        ${applicationValues}
      }
    }
  }
`

export const UPSERT_PORTFOLIO_MEMBER = gql`
  mutation upsertPortfolio($member: InputPortfolioMember, $orgId: String!) {
    upsertPortfolio(member: $member, orgId: $orgId) {
      success
      reason
      member {
        ${folioMemberLoadVars}
      }
    }
  }
`

export const UPSERT_PORTFOLIO_APPLICATION_FIELD = gql`
  mutation upsertApplicationField($field: InputApplicationField!) {
    upsertApplicationField(field: $field) {
      success
      reason
      result {
        ${applicationValues}
      }
    }
  }
`

export const PORTFOLIO_EXPORT = gql`
  mutation portfolioExport($id: String!) {
    portfolioExport(id: $id) {
      url
    }
  }
`
