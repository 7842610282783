import React, { useContext } from 'react'

import { ApplyContext } from './reducer'

function Welcome() {
  const [app] = useContext(ApplyContext)
  return (
    <>
      <b>Welcome!</b> {app.org.name} is partnered with Venturly. We help you:
      <ul>
        <li>Understand the stage your venture is in</li>
        <li>Connect you with a network of other entrepreneurs</li>
      </ul>
      And we help {app.org.name} keep track of everybody's applications as
      Venturly Projects.
    </>
  )
}

export default Welcome
