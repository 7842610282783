import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// a node package, brought in with path-browserify
import path from 'path-browserify'

import paths from 'constants/paths'

import Help from 'tools/Help'
import Link from 'tools/Links'
import { LoadingInline } from 'tools/Loader'
import Activity from 'tools/NavTabs/Activity'

import Journeys from 'components/Project/Progress/Journeys'
import Request from 'components/Project/Show/Request'
import ProjectContext from 'components/Project/reducer'
import { GlobalContext } from 'reducer/global'
import { useExpandQuery2 } from 'reducer/global/hooks'

import MilestoneArrows from '../Milestone/Arrows'
import { JourneyContext, R_JOURNEY } from '../reducer'
import RubricQuestions from './Questions'

function Rubrics() {
  const [project] = useContext(ProjectContext)
  const [jmap, dispatch] = useContext(JourneyContext)
  const [access, setAccess] = useState(false)
  let { subId: jMapId, subSubId: qId } = useParams()
  const history = useHistory()
  const projectCount = project.journeyMapsL.length
  const basePath = `${paths.project}/${project.shortId}/rubrics`
  let listing = false
  if (!jMapId || jMapId === '_') {
    listing = true
    jMapId = undefined
  }

  useEffect(() => {
    if (projectCount > 0 && !jMapId && !listing) {
      const jmap = project.journeyMapsD[project.journeyMapsL[0]]
      history.replace(`${basePath}/${jmap.shortId}`)
    }
  }, [jMapId, projectCount, project, listing, basePath, history])

  // shortcut for direct linking to a question
  useEffect(() => {
    if (qId) {
      const [rId, qmId] = jmap.q2r?.[qId.toLowerCase()] || []
      if (rId) {
        const dirname = path.dirname(window.location.pathname)
        const parsed = new URLSearchParams('')
        parsed.set('r', rId)
        parsed.set('q', qmId)
        history.replace(`${dirname}?${parsed.toString()}#${qmId}`)
      }
    }
  }, [qId, history, jmap.q2r])

  useEffect(() => {
    dispatch({ type: R_JOURNEY.SET_ACCESS, value: project.can })
  }, [project.can, dispatch])

  // TODO: update font-awesome
  if (listing) {
    return (
      <div className="theme-frame-tabbed max-view-page mb5 pa4">
        <label className="mb3 f4 heading">
          <i className="fas fa-route mr3 fa-flip-horizontal" />
          Select Journey
        </label>
        <Journeys className="b f5 heading" />
      </div>
    )
  }
  return (
    <div className="theme-frame-tabbed max-view-page mb5">
      <label className="pt4 ph4 mb3 f4 heading flex justify-between items-center">
        <div>
          <i className="fas fa-route mr3" />
          {jmap.journey.name} Rubrics
          <Help className="ml2" title="Journey Rubrics">
            How to use your Journey Rubric:
            <ul>
              <li>
                Each step, question, or challenge is designed to help you learn
                and mature your idea, concept or capability. As you review each
                one, if you feel uncertain about how to answer, that is an
                indicator of where you can spend time with more learning.
              </li>
              <li>
                The Mindshare Topics listed with each are how you can advance your
                knowledge in the specified areas until you feel comforable
                completing that step. Follow each link to learn more.
              </li>
            </ul>
          </Help>
        </div>
        <div>
          {projectCount > 1 ? (
            <Link className="f6 button plain ph2" to={`${basePath}/_`} type="a">
              +{projectCount - 1} more
            </Link>
          ) : null}
        </div>
      </label>
      <div className="ml-auto f6">
        {project.can.answerRead ? (
          <FormatProgress progress={jmap.journey.progress} count={false} />
        ) : null}
      </div>
      <div className="ph4 mb4">
        <MilestoneArrows jmap={jmap} className="w-100 pa1" />
      </div>
      <div className="i warning f6 pb3 pl5">* required for this milestone</div>
      {project.can.answerRead ? (
        <ListRubrics jmap={jmap} />
      ) : jmap.type === 'venture' ? (
        <div className="tc i pb5">
          Sorry, you don't have access to view this venture's Journey Rubric.
          <br />
          <button className="mt3" onClick={() => setAccess(true)}>
            Request Access
          </button>
          {
            <Request
              viewState={[access, setAccess]}
              project={jmap.actor.project}
              resourceType="journey"
              resourceId={jmap.id}
            />
          }
        </div>
      ) : (
        <div className="pb4">Personal Journeys are not yet implemented</div>
      )}
    </div>
  )
}

function ListRubrics({ jmap: { journey, _normal } }) {
  const [show, setShow] = useState('rubricsShow')
  if (!_normal) {
    return (
      <div
        className="w-100 flex-center theme-hilite-layer"
        style={{ height: '20rem' }}
      >
        <LoadingInline babble={false} />
      </div>
    )
  }

  const hidden =
    show === 'rubricsShow'
      ? journey.rubricsL.length - journey.rubricsShow.length
      : 0

  return (
    <>
      {(journey[show] || []).map((rId, x) => {
        return <Rubric rubric={journey.rubricsD[rId]} key={rId} first={x === 0} />
      })}
      <div className="pl5 i pv3 gray f6">
        {hidden > 0 ? (
          <div className="link" onClick={() => setShow('rubricsL')}>
            +{hidden} rubrics unlocked in future milestones
          </div>
        ) : null}
      </div>
    </>
  )
}

function Rubric({ rubric, first }) {
  const [state] = useContext(GlobalContext)
  const { expand, pivot } = useExpandQuery2(state, 'r')
  const open = expand.includes(rubric.shortId)
  return (
    <>
      <div
        className={`pv2 ph4 flex-items f4 theme-hilite-layer hover-hilite pointer relative`}
        onClick={() => pivot(rubric.shortId)}
      >
        <div className="f6 pa2 relative">
          <i className={`fas fa-${open ? 'minus' : 'plus'} f7`} />
          <Activity show={rubric.progress.returned} />
        </div>
        <div className="ml2 flex-between w-100 relative">
          <div className="">
            <b>{rubric.name}</b>
          </div>
        </div>
        <div className="ml-auto nowrap f6 flex-items">
          {rubric.progress.feedback ? (
            <i className="fas fa-comment-alt mr2" />
          ) : null}
          <FormatProgress progress={rubric.progress} />
        </div>
      </div>
      {open ? <RubricQuestions rubric={rubric} /> : null}
    </>
  )
}

export function FormatProgress({ progress, count = true }) {
  if (!progress) {
    return <></>
  }
  const percent = (
    <span className={progress.percent > 0 ? 'green b mr2' : 'mr2'}>
      {progress.percent}% complete
    </span>
  )
  if (!count) {
    return percent
  }
  return (
    <>
      {percent} — {progress.finished}/{progress.total} answered
    </>
  )
}

export default Rubrics
