import React, { useContext, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import moment from 'moment'

import { Browser, BrowserContext, R_BROWSER } from 'tools/Browser'
import { intcmp } from 'utils/string'

import { PROJECTS } from 'components/Project/graphql'
import GlobalContext from 'reducer/global'

import ProjectTile from '../Tile'
import { normalizeProject } from '../normalize'
import Filters from './Filters'
import style from './index.module.scss'

function Browse({ action }) {
  return (
    <Browser className="pb5 mt4" top="top-2">
      <BrowseProjects action={action} />
    </Browser>
  )
}

function BrowseProjects({ action }) {
  const [
    {
      user: { id: memberId }
    }
  ] = useContext(GlobalContext)
  const [browse, dispatch] = useContext(BrowserContext)
  let filter = browse.filter

  useEffect(() => {
    if (action !== browse.action) {
      const filter = action === 'mine' ? { memberId } : {}
      dispatch({ type: R_BROWSER.RESET, value: { action, offset: 0, filter } })
    }
  }, [filter, action, memberId, browse.action, dispatch])

  useQuery(PROJECTS, {
    variables: {
      filter: { ...filter, limit: browse.limit, offset: browse.offset }
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(result) {
      if (!result) return
      if (result.projects.success) {
        dispatch({ type: R_BROWSER.SET, value: result.projects, normalize })
      }
    }
  })

  // let bottom = null
  let top = null
  if (browse.last === 0) {
  } else if (!action) {
    top =
      'No matches found!  You can create a new venture, or change your search filters.'
  }
  return (
    <>
      <div className="ba b--transparent ph3-m ph0-l pv2 pv4-ns max-view-page">
        <div className="f3 i mb3 tc">{top}</div>
        <div
          className={`${style.negativeBoundary} flex flex-wrap justify-center pb2-ns`}
        >
          {browse.list.map((project) => (
            <ProjectTile key={project.id} project={project} />
          ))}
        </div>
      </div>
      <Filters />
    </>
  )
}

////////////////////////////////////////////////////////////////////////////////
export function normalize(state, { result, meta, total, matching, offset }) {
  result = result.reduce((list, p) => {
    if (!state.index[p.id]) {
      p = normalizeProject(p)
      p._updated = moment(p.updatedAt)
      p._updated_t = p._updated.unix()
      return list.concat(p)
    }
    return list
  }, [])

  const last = result.length

  let list
  if (offset > 0 && state.scrolling) {
    list = state.list.concat(result)
  } else {
    list = result
  }
  list = list.toSorted((a, b) => intcmp(b._updated_t, a._updated_t))
  const index = list.reduce((index, p) => {
    index[p.id] = true
    return index
  }, {})
  return { ...state, list, meta, total, matching, loading: false, last, index }
}

export default Browse
