import React from 'react'

export function KarmaIcon({ className = '' }) {
  return (
    <span className={`fas fa-grin ${className}`} style={{ fontSize: '.5rem' }} />
  )
}

export function SparksIcon({ className = '', style = {} }) {
  return (
    <span
      className={`fas fa-bolt ${className}`}
      style={{ fontSize: '.5rem', ...style }}
    />
  )
}
