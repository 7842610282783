// import { NavTabs, NavTabsProvider, useTabs } from 'tools/NavTabs'
// import { ControlIcon } from 'tools/Controls'
// import { UPSERT_PORTFOLIO } from './graphql'
import React, { useContext } from 'react'
// import paths from 'constants/paths'
import { useParams } from 'react-router-dom'

// import { useMutation } from '@apollo/client'
import { Editable, Loader } from 'tools/Editable'

import orgFrame from 'components/Org/frame'
import { OrgContext, OrgProvider } from 'components/Org/reducer'
import studioFrame from 'components/Studio/frame'
import { usePage } from 'reducer/global'

import List from './List'
// import { StudioProvider, StudioContext, R_STUDIO } from './reducer'
import { StudioProvider } from './reducer'

function StudioRoot() {
  const { orgId } = useParams()
  return (
    <OrgProvider>
      <Loader frame={orgFrame} targetId={orgId}>
        <StudioEditor />
      </Loader>
    </OrgProvider>
  )
}

function StudioEditor() {
  const params = useParams()
  const [org] = useContext(OrgContext)

  usePage({
    name: `Portfolio Studio ${org.name ? '— ' + org.name : ''}`,
    saving: false,
    background: 'flat'
  })

  return <Editable frame={studioFrame} loaderVars={params} />
}

// function OrgLoader({ folioId, orgId }) {
//   const [org] = useContext(OrgContext)
//
//   if (!org.id) {
//     return null
//   }
//   return (
//     <StudioProvider>
//       <NavTabsProvider>
//         <Loader
//           frame={studioFrame}
//           targetId={folioId}
//           variables={{ folioId, orgId }}
//         >
//           <StudioTabs />
//         </Loader>
//       </NavTabsProvider>
//     </StudioProvider>
//   )
// }

// function StudioTabs() {
//   const [state, dispatch] = useContext(StudioContext)
//   const [org] = useContext(OrgContext)
//   const { folioId, orgId } = useParams()
//   // const history = useHistory()
//   // const [upsertFolio] = useMutation(UPSERT_PORTFOLIO)
//
//   useTabs({ tabs: state.tabs, param: 'tabId', props: state.tabProps })
//
//   usePage({
//     name: `Portfolio Studio ${org.name ? '— ' + org.name : ''}`,
//     background: 'flat'
//   })
//
//   useEffect(() => {
//     dispatch({ type: R_STUDIO.MERGE_TAB_PROPS, value: { name: state.name } })
//   }, [state.name, dispatch])
//
//   // const onSave = useCallback(
//   //   (args, value, good, bad) => {
//   //     const { token = undefined, component, meta, orig = undefined } = args
//   //
//   //     if (orig !== value) {
//   //       const variables = {
//   //         orgId: org.id,
//   //         [component]: { ...meta, [token]: value }
//   //       }
//   //       upsertFolio({
//   //         variables,
//   //         update(cache, { data: { upsertPortfolio: res } }) {
//   //           if (res.success) {
//   //             dispatch({ type: R_STUDIO.UPSERT, value: res })
//   //             if (res.portfolio && folioId !== res.portfolio.shortId) {
//   //               history.push(
//   //                 `${paths.studio}/${org.shortId}/s/${res.portfolio.shortId}`
//   //               )
//   //             }
//   //             good && good()
//   //           } else {
//   //             bad && bad(res.reason)
//   //           }
//   //         }
//   //       })
//   //     }
//   //   },
//   //   [dispatch, upsertFolio, history, org.id, org.shortId, folioId]
//   // )
//   //
//   // useEffect(() => {
//   //   dispatch({ type: R_STUDIO.MERGE, value: { onSave } })
//   // }, [onSave, dispatch])
//
//   return (
//     <NavTabs
//       className="mt4 max-view-page"
//       raw={true}
//       path={({ tabId }) => {
//         return `${paths.studio}/${orgId}/${tabId}/${folioId}`
//       }}
//       controls={
//         <div className="flex">
//           <ControlIcon icon="fas fa-times" to={`${paths.org}/${orgId}/edit`} />
//         </div>
//       }
//     />
//   )
// }
//
export function StudioDeepLink() {
  return (
    <StudioProvider>
      <List />
    </StudioProvider>
  )
}

export default StudioRoot
