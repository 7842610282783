import { makeTab } from 'tools/NavTabs'

import Application from './Application'
// import List from './List'
import Members from './Members'
import Reports from './Reports'
import Summary from './Summary'
import Workflow from './Workflow'

////////////////////////////////////////////////////////////////////////////////
const mod = [
  ///// TODO: fix circular dependency breaking webpack
  // makeTab({
  //   id: 'l',
  //   name: 'Portfolios',
  //   orig: true,
  //   Component: List
  // }),
  makeTab({
    id: 's',
    name: ({ props: { name } }) => name || 'Summary',
    Component: Summary,
    view: ({ params: { folioId } }) => folioId !== undefined
  }),
  makeTab({
    id: 'app',
    name: 'Application',
    Component: Application,
    view: ({ params: { folioId } }) => folioId !== undefined
  }),
  makeTab({
    id: 'm',
    name: 'Ventures',
    Component: Members,
    view: ({ params: { folioId } }) => folioId !== undefined
  }),
  makeTab({
    id: 'w',
    name: 'Workflow',
    Component: Workflow,
    view: ({ params: { folioId } }) => folioId !== undefined
  }),
  makeTab({
    id: 'r',
    name: 'Reports',
    Component: Reports,
    view: ({ params: { folioId } }) => folioId !== undefined
  })

  // makeTab({
  //   id: 'folios',
  //   name: 'Studio',
  //   Component: Studio,
  //   view: () => false
  // }),
  // makeTab({
  //   id: 'f',
  //   name: 'Portfolio',
  //   Component: Studio,
  //   close: () => false,
  //   view: ({ tabId }) => tabId === 'f'
  // }),
  // makeTab({
  //   id: 'p',
  //   name: 'Project',
  //   Component: Studio,
  //   close: () => false,
  //   view: ({ tabId }) => tabId === 'p'
  // })
]
export default mod
