import gql from 'graphql-tag'

export const fileVars = `
  id
  path
  valid
  name
  size
  subject
  type {
    id
    mime
  }
  updatedAt
`
export const UPSERT_FILE = gql`
  mutation upsertUploadFile($file: InputUploadFile!) {
    upsertUploadFile(file: $file) {
      success
      reason
      result {
        ${fileVars}
        signedUrl
      }
    }
  }
`

export const DELETE_FILE = gql`
  mutation deleteUploadFile($file: InputUploadFile!) {
    deleteUploadFile(file: $file) {
      id
    }
  }
`
