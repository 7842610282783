import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'

import paths from 'constants/paths'

import Link from 'tools/Links'
import { LoadingOverlay as Loading } from 'tools/Loader'
import Markdown from 'tools/Markdown'
import { when } from 'utils/function'

import OrgSummary from 'components/Org/Show/Summary'
import { normalize as normalizeProjects } from 'components/Project/Browse'
import { PROJECTS } from 'components/Project/graphql'
import SignForm from 'components/Signon/SignForm'
import { usePage } from 'reducer/global'
import GlobalContext from 'reducer/global'

import AskUser from './AskUser'
import EditForm from './EditForm'
import SelectExisting from './SelectExisting'
import SelectType from './SelectType'
import { LINK_PROJECT, UPSERT_APPLY_ANSWER } from './graphql'
import { ApplyContext, R_APPLY } from './reducer'

const STATE_NONE = 1
const STATE_APPLY = 2
const STATE_CHOOSE = 3
const STATE_CREATE = 4

function Application() {
  usePage({ name: 'Apply', background: 'flat' })
  const { folioId: portfolioId, projectId } = useParams()
  const [{ apollo, user }] = useContext(GlobalContext)
  const [app, dispatch] = useContext(ApplyContext)
  const [create, setCreate] = useState(false)

  const [projects, setProjects] = useState({
    list: [],
    meta: {},
    total: 0,
    matching: 0,
    sort: {}
  })

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  // link the project into the portfolio
  useEffect(() => {
    let isMounted = true

    if (user.isAuthed && projectId?.length > 0) {
      linkSponsor(apollo, portfolioId, projectId)
        .then(
          when(isMounted, ({ data: { upsertProject } }) => {
            if (upsertProject.success) {
              dispatch({
                type: R_APPLY.LINK_PROJECT,
                value: upsertProject.result,
                user
              })
            }
          })
        )
        .catch((error) => {
          console.log('Error while linking project', error)
        })
    }

    return () => {
      isMounted = false
    }
  }, [portfolioId, projectId, apollo, dispatch, user])

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  // load the user's projects
  useQuery(PROJECTS, {
    skip: !user.isAuthed || projectId,
    variables: {
      filter: { memberId: user.id, memberType: 'admin' },
      fetchPolicy: 'cache-and-network'
    },
    onCompleted(result) {
      if (!result) return
      if (result.projects.success) {
        setProjects((p) => normalizeProjects(p, result.projects))
      }
    }
  })

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  // enrich onChange from our dispatch
  useEffect(() => {
    if (app.onChange === undefined) {
      dispatch({
        type: R_APPLY.MERGE,
        value: {
          onChange: (fieldId, token, value) => {
            dispatch({
              type: R_APPLY.CHANGE_ANSWER,
              value,
              fieldId,
              token
            })
          }
        }
      })
    }
  }, [app.onChange, dispatch])

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  const [mutation] = useMutation(UPSERT_APPLY_ANSWER)

  const onSave = useCallback(
    ({ field, token }, value, good, bad) => {
      let isMounted = true
      mutation({
        variables: {
          fieldId: field.id,
          portfolioId,
          projectId,
          value: JSON.stringify({ [token]: value })
        },
        update(cache, { data }) {
          if (isMounted) {
            const result = data.upsertApplicationAnswer
            if (result.success && result.result) {
              dispatch({
                type: R_APPLY.UPSERT_ANSWER,
                result,
                field
              })
              good && good()
            } else {
              bad && bad(result.reason)
            }
          }
        }
      })
      return () => {
        isMounted = false
      }
    },
    [dispatch, mutation, portfolioId, projectId]
  )

  useEffect(() => {
    dispatch({ type: R_APPLY.MERGE, value: { onSave } })
  }, [onSave, dispatch])

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  let state = STATE_NONE
  if (user.isAuthed) {
    if (app.project) {
      state = STATE_APPLY
    } else if (projects.total > 0 && !create) {
      state = STATE_CHOOSE
    } else {
      state = STATE_CREATE
    }
  }

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  return (
    <div className="max-view-page flex flex-column items-center mt4 mt5-l mb5">
      {app._normal ? (
        <>
          {app.showOrg ? (
            <Link
              type="div"
              className="pointer"
              to={`${paths.org}/${app.org.shortId}`}
            >
              <OrgSummary org={app.org} />
            </Link>
          ) : null}
          <div className="theme-frame theme-bg-flat mt2 mt4-ns relative w-100">
            <div className="pa4">
              {app.showFolio ? <h1>{app.portfolio.name}</h1> : null}
              {app.terms ? (
                <Markdown className="doc">{app.terms}</Markdown>
              ) : null}
              {!user.isAuthed ? (
                <AskUser />
              ) : app.project ? (
                <EditForm />
              ) : (
                <SelectType
                  hasProjects={projects.total > 0}
                  createState={[create, setCreate]}
                />
              )}
            </div>
          </div>
          {!user.isAuthed && app.login !== undefined ? (
            <div className="mt5">
              <SignForm signup={app.login} heading={false} />
            </div>
          ) : null}
          {state === STATE_CHOOSE ? (
            <SelectExisting portfolioId={portfolioId} projects={projects} />
          ) : null}
        </>
      ) : (
        <Loading onlyFull={true} noText={true} />
      )}
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function linkSponsor(apollo, portfolioId, id) {
  return apollo.mutate({
    mutation: LINK_PROJECT,
    variables: {
      portfolioId,
      project: {
        id,
        portfolioId
      }
    }
  })
}

export default Application
