import { normalizeParentFiles } from 'tools/Files/normalize'
import { activeTabs } from 'tools/NavTabs'
import { normalizeTags } from 'tools/SelectTags'
import { put } from 'utils/dict'
import { strcmp } from 'utils/string'

import { normalizeJourneyActor } from 'components/Journey/normalize/journeyActor'

import tabs from './tabs'

////////////////////////////////////////////////////////////////////////////////
export const defaultProject = {
  id: undefined,
  shortId: undefined,
  title: '',
  profile: {},
  tabs,
  tabProps: { activity: {} },
  data: [],
  d: {},
  members: [],
  files: [],
  filesL: [],
  tagsD: {},
  fileTypes: {},
  tags: [],
  journeyMapsL: [],
  journeyMapsD: {},
  // journeys: [],
  milestones: [],
  milestonesD: {},
  milestonesL: [],
  // come in from a second query to reactorRubrics
  challengesL: [],
  challengesD: {},
  requests: [],
  summary: { comments: [] },
  browse: undefined,
  showJourney: window.location.search.includes('showJourney'),
  canEdit: false,
  canView: false,
  canUsers: false,
  can: {},
  avatar: { url: '/project-default.png' },
  allows: () => false,
  refetch: () => {}
}

export function normalizeProject(project, user, orig = {}) {
  let d = (project.data || []).reduce((d, p) => {
    d[p.type] = p
    return d
  }, {})

  const profile = d.profile

  project = {
    ...defaultProject,
    ...orig,
    ...project,
    profile: profile?.value || {},
    elevator: profile?.value?.elevator || '',
    website: profile?.value?.website || '',
    needs: {
      value: {},
      roles: [...(d?.roles || [])].toSorted(strcmp)
    },
    license: d?.license || { quota: 50 * 1024 * 1024, storage: 0, value: {} },
    financials: d?.financials || {},
    contribs: d?.contribs || {},
    ...normalizeProjectJourneyActor(project)
  }

  project = normalizeParentFiles(project, project.files)
  project = normalizeTags(project, project.tags)

  if (project.myAccess && user?.is) {
    const isAdmin = user.is('superadmin')
    project.myAccess = new Set(project.myAccess)
    project.allows = (action) => project.myAccess.has(action) || isAdmin
    project.canEdit = project.allows('project_admin')
    project.canView = project.allows('journey_answer_read')
    project.canUsers = project.allows('admin_user')
    project.can = {
      view: project.canView,
      edit: project.canEdit,
      answerRead: project.allows('journey_answer_read'),
      answerWrite: project.allows('journey_answer_write')
    }
  }

  let active = []
  let inactive = []

  if (project.invites?.length) {
    active.push('members')
  } else {
    inactive.push('members')
  }
  project = activeTabs(project, active, inactive)

  project._ready = project.journeyMapsL?.length > 0
  project._normal = true
  return project
}

function normalizeProjectJourneyActor({ journeyActor }) {
  let journeyMapsL = []
  let journeyMapsD = {}
  // let journeys = {}
  let milestones = []
  let milestonesD = {}

  if (journeyActor) {
    const { journeyMaps, id, type } = journeyActor
    if (journeyMaps && journeyMaps.length > 0) {
      journeyMapsL = journeyMaps.map((m) => m.id)
      journeyMapsD = [...journeyMaps].reduce(
        (dict, jMap) => put(dict, jMap.id, normalizeJourneyActor(jMap)),
        {}
      )

      // journeys = journeyMaps.map((jm) => jm.journey.id)

      // some shenanagins to avoid showing duplicate ribbons
      milestones = journeyMapsL.reduce(
        ({ dups, m }, jmapId) => {
          const jm = journeyMapsD[jmapId]
          const ms = jm.milestone
          if (ms) {
            const k = `${ms.name}.${ms.meta.style.primary}.${ms.meta.style.text}`
            if (!dups[k]) {
              dups[k] = true
              m.push(ms)
            }
          }
          return { dups, m }
        },
        { dups: {}, m: [] }
      ).m

      milestonesD = journeyMapsL.reduce((mDict, jmapId) => {
        const {
          journey: { milestonesL = [], id }
        } = journeyMapsD[jmapId]
        return milestonesL.reduce((mDict, mId) => {
          mDict[mId] = { journeyId: id, jmapId }
          return mDict
        }, mDict)
      }, {})
    }
    journeyActor = Object.freeze({ id, type })
  } else {
    journeyActor = {}
  }

  return Object.freeze({
    journeyActor,
    journeyMapsL,
    journeyMapsD,
    // journeys,
    milestones,
    milestonesD
  })
}
