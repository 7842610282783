import React, { useEffect, useRef, useState } from 'react'

export function Text(props) {
  const { className = '', children, ...rest } = props
  return (
    <text className={`t ${className}`} {...rest}>
      {children}
    </text>
  )
}

// transform={`rotate(-90, ${tx}, ${ty})`}
export function TextCenter(props) {
  return <Text textAnchor="middle" dominantBaseline="central" {...props} />
}

export function TextRight(props) {
  return <Text textAnchor="end" {...props} />
}

export function LabelBox({
  label,
  children = undefined,
  setChildrenProps = (bw, bh) => ({}),
  horizontalPadding = 0,
  verticalPadding = 0,
  weakWidth = undefined,
  weakHeight = undefined,
  gProps = {},
  rectProps = {},
  rectBgProps = {},
  textProps = {}
}) {
  const textRef = useRef(null)
  const [[boxWidth, boxHeight], setTextDimensions] = useState([0, 0])

  useEffect(() => {
    const { height } = textRef.current.getBoundingClientRect()
    const width = textRef.current.getComputedTextLength()

    console.assert(
      !(horizontalPadding && weakWidth),
      'If you specify weakWidth then specifying horizontalPadding does nothing'
    )
    console.assert(
      !(verticalPadding && weakHeight),
      'If you specify weakHeight then specifying verticalPadding does nothing'
    )

    const calculatedWidth = weakWidth
      ? Math.max(width, weakWidth)
      : width + horizontalPadding * 2
    const calculatedHeight = weakHeight
      ? Math.max(height, weakHeight)
      : height + verticalPadding * 2

    setTextDimensions([calculatedWidth, calculatedHeight])
  }, [horizontalPadding, verticalPadding, weakWidth, weakHeight])
  return (
    <g {...gProps}>
      {Object.keys(rectBgProps).length ? (
        <rect
          {...rectBgProps}
          x={-boxWidth * 0.5}
          y={-boxHeight * 0.5}
          width={boxWidth}
          height={boxHeight}
        />
      ) : null}
      <rect
        {...rectProps} // do this first so we can overwrite any props included that should not have
        x={-boxWidth * 0.5}
        y={-boxHeight * 0.5}
        width={boxWidth}
        height={boxHeight}
      />
      <text
        ref={textRef}
        textAnchor="middle"
        dominantBaseline="central"
        {...textProps}
        x="0"
        y="0"
      >
        {label}
      </text>{' '}
      {children ? (
        <svg {...setChildrenProps(boxWidth, boxHeight)}>{children}</svg>
      ) : null}
    </g>
  )
}
