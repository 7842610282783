import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'

import paths from 'constants/paths'

import { useSave } from 'tools/Input'
import { Link } from 'tools/Links'
import { LoadingInline } from 'tools/Loader'
import { capitalize } from 'utils/string'

import { Apply, ApplyInner } from 'components/Apply'
import { FieldHead } from 'components/Apply/EditForm'
import FieldValue from 'components/Apply/FieldValue'
import { LOAD_PROJECT } from 'components/Apply/graphql'
import { ApplyContext, R_APPLY } from 'components/Apply/reducer'
import { FormatProgress } from 'components/Journey/Rubric'
import { normalizeProject } from 'components/Project/normalize'
import { UPSERT_PORTFOLIO_MEMBER } from 'components/Studio/graphql'

function Project({ projectId, folioId }) {
  return (
    <div className="theme-frame-tabbed max-view-page mb6">
      <Apply
        Component={ApplyInner}
        Form={ViewForm}
        name="Founders"
        projectParam="action"
        folioParam="folioId"
      />
    </div>
  )
}

function ViewForm() {
  const history = useHistory()
  const [app, dispatch] = useContext(ApplyContext)
  const { action: projectId, folioId: portfolioId } = useParams()

  useQuery(LOAD_PROJECT, {
    variables: { projectId, portfolioId },
    skip: !projectId || !portfolioId,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data && data.projects.result) {
        const value = normalizeProject(data.projects.result[0])
        dispatch({ type: R_APPLY.LINK_PROJECT, value })
      }
    }
  })

  const [mutate] = useMutation(UPSERT_PORTFOLIO_MEMBER)
  const onSave = useSave({
    id: app.member?.id,
    mutate,
    key: 'upsertPortfolio',
    result: 'member',
    normalize: (value) => dispatch({ type: R_APPLY.UPSERT_MEMBER, value })
  })

  if (!app || !app._answers || !app.project) {
    return (
      <div className="flex-center pv6">
        <LoadingInline />
      </div>
    )
  }

  const { project } = app
  return (
    <>
      <div className="bb theme-b-minimize pa4 flex-items">
        <div className="flex-between w-100">
          <div className="f4 b heading flex-items">
            {project.title}
            <Link
              type="button"
              className="primary ml3"
              to={`${paths.project}/${project.shortId}`}
            >
              <i className="fas fa-eye f6" />
            </Link>
          </div>
          <div className="ml2 mr3 i f6 ba theme-b-minimize br4 ph2 pv1">
            {app.status ? <>{capitalize(app.status)}</> : null}
          </div>
          <div className="i mr2">
            <FormatProgress
              progress={{
                percent: app.progress.percent,
                finished: app.progress.good,
                total: app.progress.required
              }}
            />
          </div>
          <i
            className="fa fa-times gray ph2 pv1 br3 hover-hilite hover-text"
            onClick={() =>
              history.push(
                `${paths.studio}/${app.org.shortId}/p/${app.portfolio.shortId}`
              )
            }
          />
        </div>
      </div>
      <div className="ph4 pb5">
        {app.fields.map((field) => (
          <React.Fragment key={field.id}>
            <FieldHead field={field} app={app} edit={false} />
            <FieldValue field={field} app={app} edit={false} />
          </React.Fragment>
        ))}

        <div className="flex-items mt5">
          <label className="primary f4 flex-items">Application Status:</label>
          <div className="ml2 mr3 i f6 ba theme-b-minimize br4 ph2 pv1">
            {app.status ? <>{capitalize(app.status)}</> : null}
          </div>
        </div>
        <div className="flex-items justify-end mt5">
          <Link
            type="button"
            className="plain large mr-auto"
            to={`${paths.apply}/${app.portfolio.shortId}/${project.shortId}`}
          >
            Application Form
          </Link>
          {nextButtons(app.status).map(([label, status, classes]) => (
            <button
              key={label}
              className={`ml3 large ${classes}`}
              onClick={() =>
                onSave(
                  { token: 'member', meta: { orgId: app.org.id } },
                  { id: app.member.id, status }
                )
              }
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    </>
  )
}

function nextButtons(current) {
  switch (current) {
    case 'preparing':
      return [
        ['Decline', 'declined', 'plain'],
        ['Accept', 'accepted', '']
      ]
    case 'withdrawn':
      return []
    case 'submitted':
      return [
        ['Decline', 'declined', 'plain'],
        ['Accept', 'accepted', '']
      ]
    case 'accepted':
      return [['Decline', 'declined', 'plain']]
    case 'declined':
      return [['Resume', 'preparing', 'plain']]
    default:
      throw new Error(`unrecognized status: ${current}!`)
  }
}

export default Project
