import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import paths from 'constants/paths'

import { AUTHX_ACTIONS } from 'utils/signon'

import GlobalContext, { useTitle } from 'reducer/global'

import SignForm from './SignForm'

export const VALIDATION_KEY = 'exv' // + config.config.lane

export function Login({ signout = false, signup = false, children = undefined }) {
  useTitle('Signon')
  const history = useHistory()
  const [{ user }, dispatch] = useContext(GlobalContext)
  const path7 = history.location.pathname.substring(0, 7)

  useEffect(() => {
    if (signout || path7 === '/signou') {
      dispatch({ type: AUTHX_ACTIONS.SIGN_OUT })
      history.push('/signon')
    } else if (path7 !== '/signoff') {
      if (user.isAuthed) {
        if (user.age < 1) {
          history.push(paths.preferences)
        } else {
          history.push('/')
        }
      }
    }
  }, [history, user.isAuthed, user.age, signout, dispatch, path7])

  return <SignForm children={children} signup={signup || path7 === '/signup'} />
}

export default Login
