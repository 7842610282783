import React, { useContext, useState } from 'react'

import Confirm from 'tools/Confirm'
import { ControlIcon } from 'tools/Controls'
import { sizeOf } from 'utils/string'

import GlobalContext from 'reducer/global'

import Upload from './UploadDialog'
import { deleteFile } from './delete'
import { FILE_TYPES, FILE_TYPE_LIST } from './normalize'

function Files({
  fileTypes,
  onAdd,
  onDelete,
  heading = true,
  refType,
  refId,
  buttonLabel = undefined,
  hideAvatar = true,
  types = FILE_TYPE_LIST
}) {
  const [{ apollo }] = useContext(GlobalContext)
  const [del, setDelete] = useState(undefined)
  return (
    <>
      <table className="list hover" cellSpacing={0} width="100%">
        <tbody>
          <tr>
            <th></th>
            <th className="f7">Type</th>
            <th className="f7 dn dtc-ns">Name</th>
            <th className="f7">Uploaded</th>
            <th className="f7 dn dtc-ns tr">Size</th>
          </tr>
          {Object.keys(fileTypes)
            .sort()
            .map((ftype, x) => {
              if (hideAvatar && ftype === 'avatar') return null
              return (
                <ListFiles
                  key={x}
                  setDelete={setDelete}
                  files={fileTypes[ftype]}
                />
              )
            })}
        </tbody>
      </table>
      {del ? (
        <Confirm
          viewState={[del, setDelete]}
          onConfirm={() => {
            deleteFile({
              file: { id: del.id, type: del.type },
              apollo,
              onSuccess: (result) => {
                setDelete(false)
                onDelete(result)
              }
            })
          }}
        >
          Remove this file?
        </Confirm>
      ) : null}
      <Upload
        className="mt3"
        onSuccess={onAdd}
        refType={refType}
        refId={refId}
        types={types}
        buttonLabel={buttonLabel}
      />
    </>
  )
}

function ListFiles({ files, setDelete }) {
  if (!files) {
    return null
  }
  // const latest = files[0]
  return files.map((f, x) => (
    <FileRow key={x} file={f} onDelete={() => setDelete(f)} />
  ))
}

// <td className="">{TYPES[type]} {files.length > 1 ? <>({files.length})</> : null}</td>
function FileRow({ file, onDelete }) {
  const Less = ({ children }) => (
    <span className="gray" style={{ fontSize: '6pt' }}>
      {children}
    </span>
  )
  const ftype = FILE_TYPES[file.subject]
  return (
    <>
      <tr>
        <td>
          {file.url ? (
            <a href={file.url}>
              <i className="fas fa-download f3" />
            </a>
          ) : null}
        </td>
        <td className="">{ftype.name}</td>
        <td className="dn dtc-ns">
          {file.name}
          <br />
          <Less>{ftype.mime[file.type]}</Less>
        </td>
        <td className="">{file._updated.fromNow()}</td>
        <td className="f7 tr dn dtc-ns">
          <div className="mt1">{sizeOf(file.size)}</div>
        </td>
        <td className="s" style={{ minHeight: '100%' }}>
          <RowControls onDelete={onDelete} />
        </td>
      </tr>
    </>
  )
}

function RowControls({ onDelete }) {
  // <ControlIcon onClick={() => {}} icon="fas fa-eye" />
  return (
    <div className="flex h-100 items-center">
      <ControlIcon onClick={onDelete} icon="fas fa-trash" />
    </div>
  )
}

export default Files
