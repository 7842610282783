import React, { createContext, useReducer } from 'react'

import {
  changeDelete,
  changeField,
  changeUpsert,
  defaultStudio,
  normalizePortfolio
} from './normalize'

export const R_STUDIO = {
  SET: 100,
  CHANGE: 200,
  CHANGE_FIELD: 210,
  UPSERT: 300,
  DELETE: 301,
  MERGE: 400,
  MERGE_TAB_PROPS: 500
}

////////////////////////////////////////////////////////////////////////////////
// micro reducer, maybe eventually switch to that
export function reducer(orig, { type, value, ...args }) {
  switch (type) {
    case R_STUDIO.SET:
      return normalizePortfolio(orig, value)

    case R_STUDIO.RESET:
      return normalizePortfolio(defaultStudio, { onSave: orig.onSave })

    case R_STUDIO.UPSERT:
      return changeUpsert(args.component, orig, value)

    case R_STUDIO.DELETE:
      return changeDelete(args.component, orig, value)

    case R_STUDIO.CHANGE:
      return { ...orig, [args.token]: value }

    case R_STUDIO.CHANGE_FIELD:
      return changeField(orig, value, args)

    case R_STUDIO.MERGE:
      return { ...orig, ...value }

    case R_STUDIO.MERGE_TAB_PROPS:
      return { ...orig, tabProps: { ...orig.tabProps, ...value } }

    default:
      throw new Error(`folio - no such action.type: ${type}!`)
  }
}

export const StudioContext = createContext(null)
export const StudioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultStudio)
  return (
    <StudioContext.Provider value={[state, dispatch]}>
      {children}
    </StudioContext.Provider>
  )
}

export default StudioContext
