import React, { useContext } from 'react'

import paths from 'constants/paths'

import AddJourney from '../AddJourney'
import { ProjectContext } from '../reducer'
import Challenges from './Challenges'
import JourneyHeader from './JourneyHeader'

////////////////////////////////////////////////////////////////////////////////
function Journeys({
  className = 'b f6'

  // challenges = undefined,
  // args = undefined
}) {
  const [project] = useContext(ProjectContext)
  const hasJourneys = project.journeyMapsL.length > 0
  return (
    <>
      {hasJourneys ? (
        <div className="relative mt4 pb4">
          {project.journeyMapsL.map((jMapId) => {
            const jMap = project.journeyMapsD[jMapId]
            return (
              <JourneyHeader
                key={jMapId}
                jmap={jMap}
                path={`${paths.project}/${project.shortId}/rubrics`}
                className={className}
              >
                <Challenges journeyMapId={jMapId} />
              </JourneyHeader>
            )
          })}
        </div>
      ) : null}
      <div className="flex-items justify-end mt4">
        <AddJourney
          refId={project.id}
          minimize={hasJourneys}
          type="project"
          className={`${hasJourneys ? 'plain' : ''} f6 ml-auto`}
          onSave={project.refetch}
          current={project.journeys}
        />
      </div>
    </>
  )
}

export default Journeys
