import React from 'react'

// VERY RUDIMENTARY — admin tools only.  For something more fancy use react-select
function Selector({
  label,
  value,
  args,
  onSave,
  opts = [],
  token,
  className = ''
}) {
  return (
    <div className={`flex-items ${className}`}>
      <label className="mr3">{label}:</label>
      <select
        value={value}
        onChange={(ev) => {
          onSave({ ...args, token }, ev.target.value)
        }}
      >
        {opts.map((t) => (
          <option value={t.value} key={t.value}>
            {t.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Selector
