import gql from 'graphql-tag'

import { fileVars } from 'tools/Files/graphql'

import { inviteValues } from 'components/Person/graphql'

const dataListValues = `
  data(types: ["profile"]) {
    id
    type
    value
  }
`

const dataLoadValues = `
  data(types: ["profile", "needs", "financials", "license", "contribs"]) {
    id
    type
    value
  }
`

const projectBaseValues = `
  id
  shortId
  title
  subtitle
  updatedAt
  insertedAt
  status
  summary
  myAccess
  avatar
  tags {
    id
    tag {
      id
      type
      label
      meta
    }
  }
  files {
    ${fileVars}
  }
`

export const projectListValues = `
  ${projectBaseValues}
  ${dataListValues}
  journeyActor {
    id
    type
    journeyMaps {
      id
      shortId
      milestoneId
      milestone {
        id
        name
        meta
      }
      journey {
        id
        name
      }
    }
  }
  members {
    id
    type
    roles
  }
`

const projectLoadValues = `
  id
  shortId
  title
  subtitle
  updatedAt
  insertedAt
  status
  summary
  ${dataLoadValues}
  ${projectBaseValues}
  members {
    id
    type
    roles
    user {
      id
      handle
      name
      lastSeen
      avatar
    }
  }
  forums {
    id
    shortId
    author {
      id
      handle
      name
      avatar
    }
    type
    subject
    visible
    meta
    insertedAt
    updatedAt
  }
  invites {
    ${inviteValues}
  }
  journeyActor {
    id
    type
    journeyMaps {
      id
      shortId
      milestoneId
      updatedAt
      journey {
        id
        name
        milestones {
          id
          name
          order
          meta
        }
      }
    }
  }
  portfolioMembers {
    id
    status
    application
    insertedAt
    updatedAt
    portfolio {
      id
      shortId
      name
      application
      org {
        id
        name
      }
    }
  }
`

export const PROJECTS = gql`
  query projects($filter: InputProjectFilter!) {
    projects(filter: $filter) {
      success
      reason
      total
      offset
      matching
      result {
        ${projectListValues}
      }
    }
  }
`

export const LOAD_PROJECT_FULL = gql`
  query projects($id: String!) {
    projects(filter: {id: $id}) {
      success
      reason
      total
      matching
      result {
        ${projectLoadValues}
      }
    }
  }
`

export const UPSERT_PROJECT = gql`
  mutation upsertProject($project: InputProject) {
    upsertProject(project: $project) {
      success
      reason
      result {
        ${projectLoadValues}
      }
    }
  }
`

export const UPSERT_PROJECT_MEMBER = gql`
  mutation upsertProjectMember($projectId: String!, $member: InputProjectMember!) {
    upsertProjectMember(projectId: $projectId, member: $member) {
      success
      reason
      meta
      result {
        ${projectLoadValues}
      }
    }
  }
`

////////////////
// id
// order
// challenge {
export const REACTOR_CHALLENGES = gql`
  query reactorChallenges($projectId: String) {
    reactorChallenges(projectId: $projectId) {
      id
      name
      explain
      visible
      context
      links {
        id
        order
        challengeId
      }
      triggers {
        id
      }
      conditions
      actions
      completed
      journal {
        id
        meta
      }
      meta
    }
  }
`
