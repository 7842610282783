import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Notify from 'tools/Notify'
import { AUTHX_ACTIONS } from 'utils/signon'

import GlobalContext, { R_GLOBAL } from 'reducer/global'

import Background from './Background'
import Footer from './Footer'
import NavBar from './NavBar'

// import { useSearchParams } from 'tools/SearchParams'

const SEP = '.'
function Root({ children }) {
  const [
    {
      authx: { redirect },
      querys
    },
    dispatch
  ] = useContext(GlobalContext)
  const history = useHistory()
  const search = useLocation().search

  useEffect(() => {
    if (querys.search !== search) {
      const parsed = new URLSearchParams(search)
      const q = (parsed.get('q')?.split(SEP) || []).filter((i) => i)
      const r = (parsed.get('r')?.split(SEP) || []).filter((i) => i)
      const c = (parsed.get('c')?.split(SEP) || []).filter((i) => i)
      dispatch({ type: R_GLOBAL.UPDATE_QUERY, q, r, c, search, parsed })
    }
  }, [search, dispatch, querys])

  // authx redirect to signin
  useEffect(() => {
    if (redirect) {
      history.replace('/signout')
      dispatch({ type: AUTHX_ACTIONS.ERROR_CLEAR })
    }
  }, [redirect, history, dispatch])

  return (
    <>
      <div className="body">
        <Background />
        {children}
        <NavBar />
      </div>
      <Footer />
      <Notify />
    </>
  )
}

export default Root
