import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import paths from 'constants/paths'

import { ControlIcon, Controls } from 'tools/Controls'

import InputName from '../Profile/InputName'
import { OrgContext } from '../reducer'

function Create() {
  const [org, dispatch] = useContext(OrgContext)
  const history = useHistory()

  return (
    <div className="max-view-page theme-frame relative pa4 mt4">
      <Controls>
        <label className="primary mr-auto">Create Partner Organization</label>
        <ControlIcon icon="fas fa-times" to={paths.org} />
      </Controls>
      <div className="mt4">
        <InputName
          onSave={(props, value, good, bad) => {
            org.onSave(
              props,
              value,
              (x) => {
                good(x)
                history.push(`${paths.org}/${org.shortId}/edit`)
              },
              bad
            )
          }}
          dispatch={dispatch}
          data={org}
        />
      </div>
    </div>
  )
}

export default Create
