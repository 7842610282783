import React, { useCallback, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'

import ICON from 'constants/icons'

import { ControlIcon } from 'tools/Controls'
import { CHG } from 'tools/Input'
import { NavTabs, NavTabsProvider, useTabs } from 'tools/NavTabs'
import notifier from 'tools/Notify/notifier'

import { GlobalContext } from 'reducer/global'

/*

<Editable> is a top level wrapper around larger sub-component modules.  It works
by expecting a few variant "modes" to the experience:

1. "list" mode for listing things out —> Browse component
2. "show" mode for showing details on a thing w/out edit tabs -> Show component
3. "edit" mode for detailed management using NavTabs

Expectations:

# Providers

It expects there to be a provided context/provider for a reducer, and NavTabs
is optional.

# Config / Frame

Everything is configured in a "frame" that is provided to editable
such that it won't cause a lot of refreshes.  Within the frame are configured
attributes, see below for the defaultFrame.  If a component attribute is
undefined it's functionality is effectively disabled, for most cases (see
where marked [optional] in the config below).

# Navigation Path

It has the understanding of a "tab" and "target" element of the path, you can
specify these using the frame config view and targetId elements.  The string
values for these attributes in the config should equal the path router elements,
so if view is set as `frabbleId` in the route/path, ala "/blah/this/:frabbleId"
then it all works.

In addition NavTabs calls a `path()` function callback for each tab, allowing
it to revise the tab attributes at runtime.

# Advanced Usage

Within Editable it has layers, including a <Loader> and <Editor> layer that
can be used independently (for more advanced needs/use cases).

You can also do an <Editable> with in an Editable if using different reducers.

You can also use a <NoTabEdit> component which will give you an editable
ability without <NavTabs> in the mix.

# GraphQL Queries

These are configured in the frame by putting them into DO_LOAD, DO_RESET,
etc, as shown in the configuration below.

# Reducer dispatch actions

There are two actions it will use in dispatching things: LOAD, and SAVE--
set these to match the action types for the dispatch.

# Additional notes:

Browse — with <Provider> but not <Loader>
Create — with <Provider>, <Loader>, but not <Editor
Show — with <Provider> and <Loader> but not <Editor> nor tabs
NoTabEdit — with <Provider>, <Loader>, and <Editor> but not tabs

With none of the above it is <Provider>, <Loader>, <Editor>, <NavTabs>

(note: you can also use NavTabs tab option `tab.hideTab=true` for a
similar full-screen experience as NoTabEdit, but with <NavTabs>)

*/

export const defaultFrame = {
  Context: undefined, // <Context> func [required] —
  Provider: undefined, // <Provider> func [required] -
  Browse: undefined, // <Component> [optional]
  Show: undefined, // <Component> [optional]
  NoTabEdit: undefined, // Editing without using Tabs
  // MiddleWare: PassThrough,
  Create: undefined, // <Create> [optional]
  DO_LOAD: undefined, // flag [required] — reducer flag to use for setting
  DO_RESET: undefined, // flag [required] - reducer flag for resetting state
  DO_DELETE: undefined, // flag — reducer flag for deleting from state
  DO_SAVE: undefined, // flag — reducer for updating
  DO_MERGE: undefined, // flag [required] — reducer flag for merging changes
  LOAD: undefined, // graphql [required] — graphql for loading record
  SAVE: undefined, // graphql [required] — for upserting record
  queryName: undefined, // [required] name of the query returned from graphql
  componentName: undefined, // [required] name of mutation component
  loadResultKey: 'result', // load result key
  saveResultKey: 'result', // save result key
  oneLoadResult: true, // a single result or a list?
  targetKey: 'targetId', // required
  noTarget: undefined, // run this function if a target cannot be found
  tabKey: 'tabId', // what route path element is the tab key for NavTabs
  loadedWait: true, // wait before rendering <Show> and edit/tabs until after load
  controlView: undefined, // Show an icon as an eyeball to go to "view" mode
  controlBack: undefined, // show an icon as an X to go "back" to list mode
  navPath: undefined, // override function for NavTabs.path()
  pathKey: 'shortId', // the item edit ID for the path
  basePath: '', // paths[key] result
  tabShow: 'show', // what is the tab for showing something
  tabCreate: 'create' // what is the tab for creating something
  // debug: true // — optional to enable debugging on this frame
}

////////////////////////////////////////////////////////////////////////////////
export function Editable({ frame, ...pass }) {
  const { Provider } = frame
  return (
    <Provider>
      <EditableWrap frame={{ ...defaultFrame, ...frame }} {...pass} />
    </Provider>
  )
}

export function EditableWrap({ frame, loaderVars = {} }) {
  const params = useParams()
  const targetId = params[frame.targetKey]
  const view = params[frame.tabKey]
  let target = targetId !== '_' ? targetId : undefined
  const { Browse, Context, tabShow, tabCreate, debug, DO_RESET } = frame
  const [state, dispatch] = useContext(Context)
  const [glob] = useContext(GlobalContext)
  const history = useHistory()
  const pathId = state[frame.pathKey]
  const id = state.id

  useEffect(() => {
    if (!target && frame.noTarget) {
      const newTarget = frame.noTarget(params, state, glob)
      if (newTarget) {
        history.replace(newTarget)
      }
    }
  }, [frame, params, state, glob, target, history])

  useEffect(() => {
    if (DO_RESET && id && target !== id && target !== pathId) {
      dispatch({ type: DO_RESET })
    }
  }, [dispatch, target, id, pathId, DO_RESET])

  debug &&
    console.log('FRAME ' + frame.queryName, { target, targetId, view, frame })
  if (!target && Browse && view !== tabShow && view !== tabCreate) {
    return <Browse action={view || 'view'} />
  }

  const { Show, Create, NoTabEdit } = frame

  return (
    <Loader targetId={target} frame={frame} variables={loaderVars}>
      {Show && (!view || view === tabShow) ? (
        <Show targetId={target} />
      ) : Create && frame.SAVE && view === tabCreate ? (
        <Editor frame={frame} create={true}>
          <Create />
        </Editor>
      ) : NoTabEdit ? (
        <Editor frame={frame} targetId={target}>
          <NoTabEdit />
        </Editor>
      ) : !frame.SAVE ? (
        <NavTabsProvider>
          <TabbedInner frame={frame} targetId={target} />
        </NavTabsProvider>
      ) : (
        <Editor frame={frame} targetId={target}>
          <NavTabsProvider>
            <TabbedInner frame={frame} targetId={target} />
          </NavTabsProvider>
        </Editor>
      )}
    </Loader>
  )
}

////////////////////////////////////////////////////////////////////////////////
function doDispatch(dispatch, type, vars, value, key, one) {
  if (!type) {
    console.log('NO HANDLER FOR DISPATCH TYPE!', { vars, value, key, one })
  } else {
    if (key) {
      value = value[key]
    }
    if (one === false) {
      value = value[0]
    }
    value = { ...value }
    value._loaded = true
    // console.log("<doDispatch>", {type, key, value, vars})
    dispatch({ type, ...vars, key, value })
  }
}

export function Loader({
  targetId = undefined,
  frame,
  children,
  variables: vars = {}
}) {
  const {
    LOAD,
    DO_LOAD,
    DO_MERGE,
    Context,
    oneLoadResult: one = false,
    queryName,
    fetchPolicy = 'cache-and-network',
    loadResultKey: key = 'result',
    debug
  } = frame
  const [, notify] = useContext(notifier.Context)
  const [state, dispatch] = useContext(Context)
  const [{ user }] = useContext(GlobalContext)

  const qname = queryName // ? queryName : one ? type : type + 's'

  const variables = { id: targetId, ...vars, ...(state._LoaderVars || {}) }
  debug &&
    console.log('<Loader>', {
      fetchPolicy,
      skip: !variables?.id,
      variables,
      targetId,
      LOAD
    })
  const { refetch } = useQuery(LOAD, {
    variables,
    skip: !variables?.id,
    fetchPolicy,
    onCompleted(result) {
      debug &&
        console.log('<Loader> onCompleted', {
          result: { ...result },
          qname,
          key,
          id: targetId,
          variables,
          frame
        })
      if (result) {
        result = result[qname]
        if (key === false) {
          doDispatch(dispatch, DO_LOAD, { user }, result, key, one)
        } else if (!result.success) {
          notifier.error(notify, result.reason)
        } else {
          doDispatch(dispatch, DO_LOAD, { user }, result, key, one)
        }
      }
    },
    onError(error) {
      console.error('<Editable>', error)
    }
  })

  useEffect(() => {
    dispatch({ type: DO_MERGE, value: { refetch } })
  }, [refetch, DO_MERGE, dispatch])

  // TODO: have DO_LOAD set _loaded, and even link into frame a <Loader> setting
  // this will require reviewing all reducers to verify they set _loader which
  // isn't ideal... perhaps inject result._loaded=true, and verify it makes it
  // through...
  // TODO: Sometimes was causing stack trace dumps :(  Debug later
  // if (!loadedWait || state._loaded) {
  return children
  // } else {
  //   return null
  // }
  // return children
}

////////////////////////////////////////////////////////////////////////////////
export function Editor({
  frame,
  create = false,
  children,
  targetId = undefined
}) {
  const { Context, SAVE, DO_MERGE } = frame
  const params = useParams()
  const [state, dispatch] = useContext(Context)
  const [{ user }] = useContext(GlobalContext)
  const history = useHistory()
  const [mutation] = useMutation(SAVE)
  const id = state.id
  const onSave = useCallback(
    (
      {
        token = undefined,
        orig = undefined,
        meta = {},
        cmeta = {},
        dirty = true,
        component = undefined,
        componentId = true
      },
      value,
      good,
      bad
    ) => {
      const {
        DO_LOAD,
        DO_SAVE,
        DO_DELETE,
        SAVE,
        queryName,
        componentName,
        basePath,
        debug,
        pathKey
      } = frame
      component = component || componentName || false
      debug &&
        console.log('<Editor>', {
          orig,
          component,
          value,
          dirty,
          token,
          meta,
          cmeta,
          SAVE
        })
      if (!dirty) {
        good && good()
      } else if (orig !== value && dirty) {
        let variables
        if (component !== false) {
          if (componentId) {
            variables = { [component]: { id, [token]: value, ...cmeta }, ...meta }
          } else {
            variables = { id, [component]: { [token]: value, ...cmeta }, ...meta }
          }
        } else {
          variables = { id, [token]: value, ...meta }
        }
        debug && console.log(`<Editor> SAVE ${queryName} variables`, variables)
        mutation({
          variables,
          update(cache, { data }) {
            // or saveResultKey?
            const res = data[Object.keys(data)[0]]
            debug &&
              console.log(`<Editor> SAVE ${queryName} result`, {
                data,
                res,
                frame
              })
            if (res.success === false) {
              bad && bad(res.reason)
              return
            }

            let value = res.result
            if (value === undefined && component && res[component]) {
              value = res[component]
            }

            doDispatch(
              dispatch,
              res.deleted ? DO_DELETE : DO_SAVE || DO_LOAD,
              { user, component },
              value
            )

            // dispatch({ type: DO_LOAD, value: result.result })

            const paramId = params[frame.targetKey]
            if (pathKey !== false && paramId !== value[pathKey]) {
              if (frame.tabEdit) {
                history.replace(`${basePath}/${value[pathKey]}/${frame.tabEdit}`)
              } else if (frame.navPath) {
                history.replace(
                  frame.navPath(
                    {
                      tabId: params[frame.tabKey],
                      frame,
                      params: { ...params, targetId: value[pathKey] }
                    },
                    value,
                    basePath
                  )
                )
              } else {
                history.replace(
                  `${basePath}/${value[pathKey]}/${params[frame.tabKey]}`
                )
              }
            }
            good && good(CHG.SAVED, value)
          }
        })
      }
    },
    [dispatch, mutation, history, id, frame, params, user]
  )
  useEffect(() => {
    dispatch({ type: DO_MERGE, value: { onSave } })
  }, [onSave, dispatch, DO_MERGE])

  return children
}

////////////////////////////////////////////////////////////////////////////////
function TabbedInner({
  frame: {
    Context,
    basePath,
    navPath,
    controlView,
    controlClose,
    tabKey = undefined
  },
  targetId
}) {
  const [state] = useContext(Context)
  const params = useParams()
  useTabs({ tabs: state.tabs, param: tabKey ?? 'tabId', props: state.tabProps })
  const path = navPath
    ? (props) => navPath(props, state, basePath)
    : ({ tabId }) => `${basePath}/${targetId}/${tabId}`

  return (
    <NavTabs
      className="mt4 max-view-page"
      raw={true}
      path={path}
      controls={
        <div className="flex">
          {controlView ? (
            <ControlIcon
              icon={ICON.view}
              className="button primary"
              to={controlView(targetId)}
            />
          ) : null}
          {controlClose ? (
            <ControlIcon
              icon="fas fa-times"
              to={controlClose({ targetId, state, params })}
            />
          ) : null}
        </div>
      }
    />
  )
}

export default Editable
