import axios from 'axios'

// TODO: update to allow more variants, such as off-host and on-host
const host = window.location.hostname
let port = window.location.port
let orig_port = ':' + port
let tls = true
if (process.env.NODE_ENV === 'development') {
  port = ':4000'
  tls = false
} else {
  if (
    (port === '80' && window.location.protocol === 'http') ||
    (port === '443' && window.location.protocol === 'https')
  ) {
    port = ''
  } else {
    port = ':' + port
  }
}

const config = {
  lane: "p",
  build: "202311251515",
  name: 'Venturly',
  nav_show: {
    icon: true,
    text: false
  },
  icon: {
    small: '/assets/img/venturly.svg',
    large: '/assets/img/venturly.svg'
  },
  url: {
    http: `http${tls ? 's' : ''}://${host}${port}`,
    ws: `ws${tls ? 's' : ''}://${host}${port}/socket`,
    app: "https://revenant.studio",
    graphql: '/graphql',
    authapi: '/authx/v1/api/',
    pub: 'https://i.venturly.vc/'
    // lib: 'https://l.venturly.vc/'
  },
  auth: {
    local: true,
    federated: ['google'],
    google: {
      appId:
        '901327745766-nupgm9l9rjtqms866p37oqit8f8do62h.apps.googleusercontent.com'
    }
  }
}

// TODO: move to global reducer
console.log(`getting http${tls ? 's' : ''}://${host}${orig_port}/manifest.json`)
axios
  .get(`http${tls ? 's' : ''}://${host}${orig_port}/manifest.json`)
  .then(({ data }) => {
    config.name = data.name
  })

export default config
