import React, { useContext } from 'react'
import { MenuButton, MenuList } from 'react-menu-list'

import config from 'constants/config'
import paths from 'constants/paths'

import { MenuLink, NavLink } from 'tools/Links'
import Activity from 'tools/NavTabs/Activity'

import { avatarUrl } from 'components/Person/tools/Picture'
import GlobalContext from 'reducer/global'

import style from './index.module.scss'

const offset = 'mr3 br2'
export function NavBar() {
  const [
    {
      nav: { bar, menu },
      saving,
      page,
      user
    }
  ] = useContext(GlobalContext)
  const { url } = avatarUrl(user)
  const hasActivity = menu.find((i) => i.activity) ? true : false
  return (
    <>
      <div className={`navbar ${style.navbar} ma0 pa1 flex-between`}>
        <NavLink
          to={paths.home}
          exact={true}
          label=""
          icon={
            <img
              src={config.icon.small}
              alt={config.name}
              style={{ height: '2rem' }}
              className="logo mh2"
            />
          }
        />
        <div className="f3 b">{page.name}</div>
        {saving ? <div className="ml3 gray throb f6">Saving...</div> : null}
        {bar.map((i, x) => (
          <NavLink
            key={i.label}
            to={i.to}
            center={true}
            label={
              <div className="flex flex-column items-center fw3 f6 relative">
                <i className={`fas fa-${i.icon} mb1 f4`} />
                <Activity show={i.activity} />
                {i.label}
              </div>
            }
            className={`${x === 0 ? 'ml-auto' : ''} ${offset} hover-hilite`}
          ></NavLink>
        ))}
        {user.authStatus === 'unknown' ? (
          <NavLink
            to="/signon"
            className={`navlink ${style.navlink} ${offset}`}
            center={true}
            logOrigin="SignOn"
            icon={
              <div className="flex flex-column items-center fw3 f6">
                <i className="fas fa-sign-in-alt mb1 f4" />
                Sign In
              </div>
            }
          />
        ) : (
          <MenuButton
            positionOptions={{
              position: 'bottom',
              vAlign: 'top',
              hAlign: 'left'
            }}
            menu={
              <div
                className={`pv1 pv2-m pv2-l white mt1 navbar-bg menu ${offset}`}
              >
                <MenuList>
                  {menu.map((i) => {
                    if (i.authz && !user.can(i.authz)) {
                      return null
                    }
                    if (i.divider) {
                      return <div key={i.id} className="bt b--gray" />
                    } else {
                      return (
                        <MenuLink
                          key={i.label}
                          to={i.to}
                          label={
                            <div className="relative f5">
                              {i.label}
                              <Activity show={i.activity} offset={true} />
                            </div>
                          }
                          origin={i.label}
                        />
                      )
                    }
                  })}
                </MenuList>
              </div>
            }
            className={`navlink ${style.navlink} ${offset} clear relative`}
          >
            <div className="flex flex-column items-center fw3 f6">
              <div className={style.microPic}>
                <img src={url} alt="" />
              </div>
              <div className="flex-items">
                Me <i className="ml2 fas fa-chevron-down" />
              </div>
              <Activity show={hasActivity} />
            </div>
          </MenuButton>
        )}
      </div>
      <div className={style.betaOuter}>
        <div className={style.betaWrap}>
          <div className={style.beta}>BETA</div>
        </div>
      </div>
    </>
  )
}

export default NavBar
