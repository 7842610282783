import paths from 'constants/paths'

export const bar = [
  {
    to: paths.feed,
    label: 'Feed',
    icon: 'rss'
  },
  {
    to: paths.project,
    label: 'Ventures',
    icon: 'lightbulb'
  },
  {
    to: paths.org,
    label: 'Investors',
    icon: 'rocket'
  },
  {
    to: paths.meet,
    label: 'Connect',
    icon: 'user-friends'
  }
]

export const menu = [
  {
    to: paths.preferences,
    label: 'Preferences'
  },
  {
    to: paths.projectMine,
    label: 'My Ventures'
  },
  {
    to: paths.orgMine,
    label: 'My Organizations'
  },
  {
    to: paths.meetMine,
    label: 'My Events'
  },
  {
    to: paths.docs,
    label: 'Help Docs'
  },
  {
    divider: true,
    id: 'admin-div',
    authz: 'admin_user'
  },
  {
    to: paths.admSummary,
    label: 'Admin',
    // todo: flip to mentor action
    authz: 'admin_user'
  },
  // {
  //   to: paths.admJourney,
  //   label: 'Journey Admin',
  //   authz: 'admin_journey'
  // },
  // {
  //   to: paths.meetAdmin,
  //   label: 'Meetups Admin',
  //   authz: 'meet_admin'
  // },
  // {
  //   to: paths.admUser,
  //   label: 'User Admin',
  //   authz: 'admin_user'
  // },
  // {
  //   to: paths.learn,
  //   label: 'Learning Topics',
  //   authz: 'admin_user'
  // },
  {
    divider: true,
    id: 'signout-div'
  },
  {
    to: paths.signout,
    label: 'Sign Out'
  }
]
