import gql from 'graphql-tag'

export const publicPersonListVars = `
  id
  handle
  name
  avatar
  verified
  lastSeen
`

export const publicPersonValues = `
  ${publicPersonListVars}
  tags {
    id
    type
    public
    tag {
      id
      label
      meta
    }
  }
  data {
    id
    type
    value
  }
  updatedAt
  insertedAt
`

const personValues = `
  ${publicPersonValues}
  emails {id,address,primary,verified}
  phones {id,number,primary,verified}
  settings
  access { roles, actions }
  authStatus
`

export const LOAD_PERSON = gql`
query users($id: String!) {
  users(id: $id) {
    success
    reason
    result {
      ${personValues}
    }
  }
}
`
// journeyActor {
//   id
//   journeyMaps {
//     id
//     journey {
//       id
//       name
//     }
//   }
// }

export const LIST_PUBLIC_PERSON = gql`
  query publicPerson($target: String, $id: String) {
    publicPerson(target: $target, id: $id) {
      success
      reason
      result {
        ${publicPersonListVars}
      }
    }
  }
`

export const PUBLIC_PERSON = gql`
  query publicPerson($target: String!) {
    publicPerson(target: $target) {
      success
      reason
      result {
        ${publicPersonValues}
      }
    }
  }
`

export const LOAD_PUBLIC_PERSON = gql`
  query publicPerson($id: String) {
    publicPerson(id: $id) {
      success
      reason
      result {
        ${publicPersonValues}
        projects {
          id
          type
          project {
            id
            shortId
            title
            status
            subtitle
            updatedAt
            insertedAt
            avatar
          }
        }
        journeys {
          id
        }
      }
    }
  }
`

export const UPDATE_PERSON = gql`
mutation updatePerson(
    $id: String, $action: UserChangeActions,
    $user: InputPerson, $handle: InputHandle, $email: InputEmail,
    $phone: InputPhone, $data: InputUserData, $role: InputRole)
  {
    updatePerson(
      id: $id, action: $action,
      user: $user, handle: $handle, email: $email,
      phone: $phone, data: $data, role: $role)
    {
      success
      reason
      result {
        ${personValues}
      }
    }
  }
`

export const LIST_PUBLIC_PEOPLE = gql`
query publicPeople($filter: PeopleFilter!) {
  publicPeople(filter: $filter) {
    success
    reason
    total
    result {
      ${publicPersonValues}
    }
  }
}
`

export const LIST_PEOPLE = gql`
query people($filter: PeopleFilter!) {
  people(filter: $filter) {
    success
    reason
    total
    results {
      ${personValues}
      last_seen
    }
  }
}
`

export const GEN_APIKEY = gql`
  mutation genApiKey {
    genApiKey {
      access
      validation
    }
  }
`

export const GET_INVITES = gql`
  mutation getInvites {
    getInvites {
      codes
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($current: String, $new: String!, $code: String) {
    changePassword(current: $current, new: $new, code: $code) {
      success
      reason
    }
  }
`

export const MY_FACTORS = gql`
  query myFactors($type: String) {
    self {
      id
      factors(type: $type) {
        id
        type
        expiresAt
      }
    }
  }
`

export const GET_ACCESS = gql`
  query getAccess($type: AccessDomain!, $refId: String!) {
    getAccess(type: $type, refId: $refId)
  }
`

export const inviteValues = `
  id
  shortId
  expiresAt
  requestorId
  requestType
  targetType
  requestAccess
  resourceType
  resourceId
  projectId
  orgId
  email
  targetId
  note
  target {
    ${publicPersonValues}
    id
    name
    handle
  }
`

export const INVITE = gql`
  mutation invite(
    $action: InviteAction!
    $targetType: InviteTargetType
    $requestType: InviteRequestType
    $requestAccess: InviteAccessType
    $responseType: InviteResponseType
    $responseAccess: InviteAccessType
    $resourceType: InviteResourceType
    $resourceId: String
    $projectId: String
    $targetId: String
    $orgId: String
    $note: String
    $email: String
    $shortId: String
  ) {
    invite(
      action: $action
      targetType: $targetType
      requestType: $requestType
      requestAccess: $requestAccess
      responseType: $responseType
      responseAccess: $responseAccess
      resourceType: $resourceType
      resourceId: $resourceId
      targetId: $targetId
      projectId: $projectId
      orgId: $orgId
      note: $note
      email: $email
      shortId: $shortId
    ) {
      success
      reason
      result
    }
  }
`
