import React, { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import paths from 'constants/paths'

import { Input, Textarea } from 'tools/Input'
import { Link } from 'tools/Links'
import Markdown from 'tools/Markdown'
import Toggle from 'tools/Toggle'

import OrgSummary from 'components/Org/Show/Summary'
import { OrgContext } from 'components/Org/reducer'
import { GlobalContext } from 'reducer/global'

import { R_STUDIO, StudioContext } from '../reducer'
import EditField, { AddField } from './EditField'

function cleanVals(folio, key, value, purge = true) {
  const app = { ...folio.application, [key]: value }

  // from enrichment/normalization
  if (purge) {
    delete app['portfolio']
    delete app['fields']
  }

  return app
}

function useChangeHooks(dispatch, folio, token) {
  const change = useCallback(
    (value) =>
      dispatch({
        type: R_STUDIO.CHANGE,
        token: 'application',
        value: cleanVals(folio, token, value, false)
      }),
    [dispatch, folio, token]
  )
  const save = useCallback(
    (args, value, good, bad) =>
      folio.onSave(
        { token: 'application', ...args },
        JSON.stringify(cleanVals(folio, token, value)),
        good,
        bad
      ),
    [folio, token]
  )

  const toggle = useCallback(
    (args) =>
      folio.onSave(
        {
          meta: { orgId: folio.orgId },
          cmeta: { id: folio.id },
          token: 'application',
          component: 'portfolio'
        },
        JSON.stringify(cleanVals(folio, token, !folio.application[token]))
      ),
    [folio, token]
  )

  return [change, save, toggle]
}

function Application() {
  const [folio, dispatch] = useContext(StudioContext)
  const [preview, showPreview] = useState(false)
  const [org] = useContext(OrgContext)
  const [{ user }] = useContext(GlobalContext)
  const [changeTerms, saveTerms] = useChangeHooks(dispatch, folio, 'terms')
  const [, , toggleShowOrg] = useChangeHooks(dispatch, folio, 'showOrg')
  const [, , toggleShowFolio] = useChangeHooks(dispatch, folio, 'showFolio')
  const isSuper = user.access.roles.includes('superadmin')
  const history = useHistory()

  if (!folio._normal) {
    return null
  }
  const args = {
    meta: { orgId: folio.orgId },
    cmeta: { id: folio.id },
    component: 'portfolio'
  }

  return (
    <div className="theme-frame-tabbed max-view-page pa4 mb6">
      {!org.portal ? (
        <i className="f7">
          Enable Application Portal on your organization to configure your portal
        </i>
      ) : (
        <>
          <label className="mb2">Affiliate Application Link</label>
          <div className="flex-items ml2">
            <Link
              className="f4 i pa1 pl2 br3"
              to={`${paths.apply}/${folio.shortId}`}
            >
              <i className="fas fa-link f6 mr2" />
              https://venturly.vc/a/
            </Link>
            <Input
              token="shortId"
              {...args}
              valState={[
                folio.shortId,
                (value) =>
                  dispatch({ type: R_STUDIO.CHANGE, token: 'shortId', value })
              ]}
              onSave={(p, v, good, b) => {
                folio.onSave(
                  p,
                  v,
                  (status, { shortId }) => {
                    good && good(status)
                    // onSave normally would catch this but we have odd paths here
                    // future todo: make it so navPath can be used for this same thing
                    history.replace(
                      `${paths.studio}/${org.shortId}/app/${shortId}`
                    )
                  },
                  b
                )
              }}
            />
          </div>
          <Textarea
            className="mt3"
            label="Terms"
            help="Terms for applying. Markdown formatting supported."
            {...args}
            valState={[folio.application?.terms || '', changeTerms]}
            onSave={saveTerms}
          />
          <label className="mt4 mb2">Header Format</label>
          <Toggle
            className="mt3 mh3 pa2"
            onChange={toggleShowOrg}
            value={folio.application?.showOrg}
          >
            Show Organization Summary
          </Toggle>
          <Toggle
            className="mt2 mh3 pa2"
            onChange={toggleShowFolio}
            value={folio.application?.showFolio}
          >
            Show Portfolio Name
          </Toggle>
          <button
            className="mt3 mb2 medium button border"
            onClick={() => showPreview(!preview)}
          >
            <i className={`fas fa-${preview ? 'minus' : 'plus'} mr2`} /> Preview
          </button>
          {preview ? (
            <div
              className="mt3 pa4 ba br3 b--gray theme-base-flat"
              style={{ marginLeft: '-2rem', marginRight: '-2rem' }}
            >
              {folio.application.showOrg ? <OrgSummary org={org} /> : null}
              <div className="theme-frame pa4">
                {folio.application.showFolio ? <h1>{folio.name}</h1> : null}
                {folio.application?.terms ? (
                  <div className="doc">
                    <Markdown>{folio.application.terms}</Markdown>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          <label className="mt4 mb2">Application Fields</label>
          <div className="mt2 mb3 gray f6">
            These are currently only configurable through a support request.
          </div>
          {(folio.application.fieldsL || []).map((id) => (
            <ApplicationField
              key={id}
              field={folio.application.fieldsD[id]}
              isSuper={isSuper}
            />
          ))}
          <AddField isSuper={isSuper} />
        </>
      )}
    </div>
  )
}

function ApplicationField({ field, isSuper }) {
  const [edit, setEdit] = useState(false)
  return (
    <div className="ba br2 theme-b-minimize pa3 mt3 relative">
      {isSuper ? (
        <>
          <div
            className="absolute top-0 left-0 pointer hover-hilite pa1 gray br2 f6"
            onClick={() => setEdit(!edit)}
          >
            <i className={`fas fa-${edit ? 'minus' : 'plus'}`} />
          </div>
        </>
      ) : null}
      {edit ? (
        <EditField field={field} />
      ) : (
        <>
          <div className="flex-items">
            <div className="">{field.question}</div>
            <div className="ml-auto ba br2 b--gray pa1 f7 mr2">{field.type}</div>
            <div className="ba br2 b--gray pa1 f7 mr2">{field.target}</div>
            <div className="f7 i">[{field.order}]</div>
          </div>
          <div className="f6 gray mt2">{field.explain}</div>
        </>
      )}
    </div>
  )
}

export default Application
