import React from 'react'

import Help from 'tools/Help'

import { FILE_TYPES, FILE_TYPE_LIST } from './normalize'

function HelpWrap(props) {
  return (
    <Help {...props}>
      You can upload a few types of files:
      {FILE_TYPE_LIST.map((t, x) => {
        const type = FILE_TYPES[t]
        return (
          <React.Fragment key={t}>
            <div className="mt2 flex items-start">
              <div
                style={{ minWidth: '2rem' }}
                className="mt1 flex justify-center"
              >
                <i className={type.icon} />
              </div>
              <div>
                <b>{type.name}</b>
                {type.explain ? <>— {type.explain}</> : null}
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem' }}
              className="f7 flex flex-wrap i b commas"
            >
              {Object.values(type.mime)
                .sort()
                .map((n, x) => (
                  <div key={t + n + x}>{n}</div>
                ))}
            </div>
          </React.Fragment>
        )
      })}
    </Help>
  )
}

export default HelpWrap
