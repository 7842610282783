import { activeTabs, closeTab, openTab } from 'tools/NavTabs'
import { normalizeTags } from 'tools/SelectTags'
import { strcmp } from 'utils/string'

import { makeAvatarUrl } from 'components/Person/tools/Picture'
import { defaultPortfolio, normalizePortfolio } from 'components/Studio/normalize'

import tabs from './tabs'

////////////////////////////////////////////////////////////////////////////////
export const MEMBER_TYPES = {
  member: 'read files, use portfolios',
  admin: 'Member + moderate forums and admin files',
  owner: 'Admin + billing and member management'
}

////////////////////////////////////////////////////////////////////////////////
const tagTypes = ['org_type', 'market']
export const defaultOrg = {
  id: undefined,
  portfolioId: undefined,
  tabs,
  tabProps: { activity: {} },
  info: {},
  data: [],
  members: [],
  tags: [],
  tagsD: {
    org_type: [],
    market: []
  },
  summary: {
    comments: []
  },
  avatar: { url: '/project-default.png' },
  portfolios: [],
  accepting: [],
  folio: false,
  portal: false,
  canEdit: false,
  canUsers: false,
  allows: () => false,
  _normal: false
}

////////////////////////////////////////////////////////////////////////////////
export function normalizeOrg(org, user = { is: (x) => false }) {
  org = { ...defaultOrg, ...org }
  const info = org.data.find((p) => p.type === 'info')
  if (info) {
    org.info = info.value
    org.detail = info.value.detail || ''
    org.website = info.value.website || ''
    org.invest_range = info.value.invest_range || ''
    org.locales = info.value.locales || ''
  }
  const location = org.data.find((p) => p.type === 'location')
  if (location) {
    org.location = location.value
    org.city = location.value.city || ''
    org.state = location.value.state || ''
    org.country = location.value.country || ''
  } else {
    org.location = { city: '', state: '', country: '' }
  }

  org.myAccess = new Set(org.myAccess)
  org.allows = (action) => org.myAccess.has(action)
  org.canEdit = org.allows('org_admin') || user.is('superadmin')
  org.canUsers = org.allows('admin_user') || user.is('superadmin')

  // when we switch to allowing files, this can drop w/normalizeParentFiles
  org.avatar = { ...org.avatar, ...makeAvatarUrl(org) }
  org = normalizeOrgTags(org, org.tags)

  org.portfolios = org.portfolios.toSorted((a, b) => strcmp(a.name, b.name))
  org.accepting = org.portfolios.filter((f) => f.status === 'accepting')
  if (org.folio) {
    if (org.portfolios.length === 0) {
      org.tabProps = {
        ...org.tabProps,
        activity: { ...org.tabProps.activity, folios: true }
      }
    }
    org.tabs = openTab(org.tabs, { id: 'folios' })
  } else {
    org.tabs = closeTab(org.tabs, 'folios')
  }

  org.portfolios = org.portfolios.map((p) =>
    normalizePortfolio(defaultPortfolio, p)
  )

  let active = []
  let inactive = []

  if (org.invites?.length) {
    active.push('members')
  } else {
    inactive.push('members')
  }
  org = activeTabs(org, active, inactive)

  // loading for an application form, simplify things...
  if (org.portfolios.length === 1) {
    const portfolio = org.portfolios[0]
    if (portfolio.application) {
      org.application = portfolio.application
    }
  }

  org._ready = true
  org._normal = true
  return org
}

export function normalizeOrgTags(org, tags) {
  org = normalizeTags(org, tags)
  return tagTypes.reduce((org, type) => {
    if (!org.tagsD[type]) {
      org.tagsD[type] = []
    }
    return org
  }, org)
}
