import React from 'react'

import { Input, Textarea } from 'tools/Input'

import { R_ORG } from '../reducer'

////////////////////////////////////////////////////////////////////////////////

function InputChange({
  token,
  data,
  dispatch,
  onSave,
  heading = 'heading mt4 mb2',
  placeholder = '',
  className = '',
  type = 'line',
  label = undefined,
  value = undefined,
  display = undefined,
  children = undefined
}) {
  value = value || data[token] || ''
  const args = {
    token,
    // inputClass: `${errors.errs[token] ? 'error' : ''} w-100`,
    inputClass: 'w-100',
    valState: [value, (value) => dispatch({ type: R_ORG.CHANGE, token, value })],
    onSave,
    placeholder
  }

  return (
    <>
      {label ? <label className={`db ${heading}`}>{label}</label> : null}
      {children ? <div className="i mb3">{children}</div> : null}
      {type === 'line' ? (
        <>
          {/* @ts-ignore */}
          <Input {...args} />
        </>
      ) : (
        <>
          {/* @ts-ignore */}
          <Textarea {...args} />
        </>
      )}
    </>
  )
}

export default InputChange
