import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import paths from 'constants/paths'

import { Link } from 'tools/Links'
import { Loading } from 'tools/Loader'
import { intcmp, strcmp } from 'utils/string'
import { fromNowShort } from 'utils/time'

import { MilestoneRibbons } from 'components/Journey/Milestone/Ribbon'
import { ProjectFileIcons } from 'components/Project/Row'
import style from 'components/Project/Row/index.module.scss'

import { PORTFOLIO_EXPORT } from '../graphql'
import { StudioContext } from '../reducer'
import Project from './Project'
import tstyle from './index.module.scss'

// TODO: move to db pulled enum settings
// const STATUS_ORDER = [
//   'submitted',
//   'accepted',
//   'preparing',
//   'withdrawn',
//   'declined'
// ]

function Members({ id }) {
  const [folio] = useContext(StudioContext)
  // const { membersL } = folio
  // const valState = useState({list: [], token: 'p', ascending: false, orig: membersL})
  // const [list, setList] = useState(undefined)
  const { action } = useParams()
  const sort = useState({ token: 'u', ascending: false, list: undefined })

  if (action) {
    return <Project projectId={action} folioId={folio.id} />
  }

  const notReady =
    !folio?.memberStatus || Object.keys(folio.memberStatus).length === 0

  const list = sort[0].list || folio.membersL
  return (
    <div className="theme-frame-tabbed max-view-page mb6">
      <div className="bb theme-b-minimize flex-items ph4 pv3">
        <label className="b f4">
          Portfolio Members / Applicants ({list.length})
        </label>
        <DownloadMembers className="ml-auto" />
      </div>
      <div className="pa4">
        {notReady ? (
          <i>
            As applicants and other projects are added to this portfolio, they
            will appear here.
          </i>
        ) : (
          <table className={`list ${style.rows} w-100 ${tstyle.table}`}>
            <TableSortHead sort={sort} projs={folio.membersD} />
            <tbody>
              {list.map((id) => (
                <ListMember key={id} member={folio.membersD[id]} />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

function sortTitle(a, b) {
  return strcmp(a.project.title, b.project.title)
}
function sortMilestone(a, b) {
  const am = a.project.milestones[0]?.name || ''
  const bm = b.project.milestones[0]?.name || ''
  return strcmp(am, bm)
}

function sortStatus(a, b) {
  return strcmp(a.status, b.status)
}

function sortLastUpdate(a, b) {
  return intcmp(a.project._updated_t, b.project._updated_t)
}

function sortFunc(token) {
  switch (token) {
    case 'p':
      return sortTitle
    case 'm':
      return sortMilestone
    case 's':
      return sortStatus
    case 'u':
      return sortLastUpdate
    default:
      return null
  }
}

function sortcols(sort, projs, token) {
  let [{ ascending, token: curTok }, setSort] = sort
  let compare = sortFunc(token)
  let list = Object.values(projs)
    .toSorted(compare)
    .map((a) => a.id)
  if (curTok !== token) {
    ascending = true
  } else {
    ascending = !ascending
  }
  if (!ascending) {
    list = list.reverse()
  }

  setSort({ ascending, token, list })
}

function SortedTh({ children, sort, projs, token, colSpan = undefined }) {
  const [{ token: sortTok, ascending }] = sort

  return (
    <th colSpan={colSpan}>
      <div
        className={`${tstyle.head} nowrap hover-hilite pointer br2 pv1 flex-items`}
        onClick={() => sortcols(sort, projs, token)}
      >
        <i
          className={`fas fa-${
            sortTok !== token
              ? 'chevron-right'
              : ascending
              ? 'angle-double-up'
              : 'angle-double-down'
          } f7 mr2`}
        />
        {children}
      </div>
    </th>
  )
}

function TableSortHead({ sort, projs }) {
  return (
    <thead>
      <tr>
        <SortedTh sort={sort} projs={projs} token="s">
          Status
        </SortedTh>
        <SortedTh sort={sort} projs={projs} token="m">
          Milestone
        </SortedTh>
        <SortedTh sort={sort} projs={projs} token="p" colSpan={2}>
          Project
        </SortedTh>
        <SortedTh sort={sort} projs={projs} token="u">
          Updated
        </SortedTh>
        <th></th>
      </tr>
    </thead>
  )
}

function ListMember({ member }) {
  // add to normalize
  const project = member.project
  const age = fromNowShort(project.updatedAt)

  return (
    <Link
      type="tr"
      to={`${paths.project}/${project.shortId}`}
      className="hover-hilite pointer"
    >
      <td className="tc f7">{member.status}</td>
      <td className="tc">
        <MilestoneRibbons milestones={project.milestones} rotate={false} />
      </td>
      <td className={style.pic}>
        <div>
          <img
            src={project.avatar && project.avatar.url}
            alt=""
            className="br1"
          />
        </div>
      </td>
      <td className={style.title}>
        <div>{project.title}</div>
      </td>
      <td className="f7">
        <div className="mr3 flex-center">
          <i className="fas fa-clock mr2" />
          {age}
        </div>
      </td>
      <td className="f7">
        <ProjectFileIcons project={project} />
      </td>
    </Link>
  )
}
// <StatusIcons className="pv3" project={project} age={age} />
//
// export function StatusIcons({ className = '', project, age }) {
//   return (
//     <div className={`flex-items ${className} f7 gray`}>
//       <ProjectFileIcons project={project} />
//       <div className="mr3">
//         <i className={`${ICON.chat} mr1`} />
//         {project.summary?.comments}
//       </div>
//       <div className="">
//         <i className="fas fa-users mr2" />
//         {project.members.length}
//       </div>
//     </div>
//   )
// }

// This is just the download modal -- it handles both actually downloading files and naming those files
const FILE_NAME = 'portfolio-members.csv'
function DownloadMembers({ className }) {
  const [folio] = useContext(StudioContext)
  const [dl, setDownloading] = useState(false)
  const [startDownload] = useMutation(PORTFOLIO_EXPORT)

  return (
    <button
      onClick={(ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        setDownloading(true)
        startDownload({
          variables: { id: folio.id },
          update(
            cache,
            {
              data: {
                portfolioExport: { url }
              }
            }
          ) {
            var element = document.createElement('a')
            document.body.appendChild(element)
            element.setAttribute('href', `${url}&name=${FILE_NAME}`)
            element.style.display = ''
            element.click()
            document.body.removeChild(element)
            setDownloading(false)
          }
        })
      }}
      className={`plain ${className}`}
    >
      {dl ? (
        <Loading babble={false} />
      ) : (
        <i className={`fas fa-${dl ? 'check' : 'download'}`} />
      )}
    </button>
  )
}

export default Members
