import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import config from 'constants/config'
import paths from 'constants/paths'

import { LoadingOverlay } from 'tools/Loader'

import GlobalContext from 'reducer/global'

import style from './SignForm.module.scss'
import SignOnFederated from './SignOnFederated'
import SignOnLocal from './SignOnLocal'

////////////////////////////////////////////////////////////////////////////////

// <config.SiteLogo className="f7" />
// eventually query the backend for this
function Heading() {
  return (
    <div className="pt2 pt4-ns white">
      <div className="pa3 flex-center">
        <div className="pa3 lh-copy fw5 tc text-outline">
          <img
            src="/codex/assets/codex-logo-512.webp"
            style={{ height: '12rem' }}
            className="mr3"
            alt="Codex"
          />
        </div>
      </div>
    </div>
  )
}

function Terms() {
  return (
    <div className={`f6 tc mt2`}>
      <i>
        By using this account you agree to our{' '}
        <a href="/legal/">Privacy and Use Policy</a>
      </i>
    </div>
  )
}

const tabHeaders = [
  { txt: 'Sign in', tok: 'signin' },
  { txt: 'Sign up', tok: 'signup' }
]
const tabHeadersMap = tabHeaders.reduce((map, item) => {
  map[item.tok] = item
  return map
}, {})

////////////////////////////////////////////////////////////////////////////////
function SignForm({ signup = false, children = undefined, heading = true }) {
  const [status, setStatus] = useState(undefined)
  const [currentTab, setCurrentTab] = useState(signup ? 'signup' : 'signin')
  const boxPadding = 'ph3 ph4-ns'
  const elemView = useRef()
  const [{ user }] = useContext(GlobalContext)

  useEffect(() => {
    setCurrentTab(signup ? 'signup' : 'signin')
  }, [signup])

  useEffect(() => {
    if (elemView.current !== undefined) {
      // @ts-ignore
      elemView.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [elemView])

  const tabClick = useCallback(
    (item, index, event) => {
      event.preventDefault()
      setStatus('')
      setCurrentTab(tabHeaders[index].tok)
    },
    [setStatus]
  )

  // <Heading />
  return (
    <div className="mb6 mb0-ns">
      <div ref={elemView} />
      <Heading />
      {children}
      {user.signingIn ? <LoadingOverlay /> : null}
      <div
        className={`${style.authbox} theme-frame flex items-start justify-center mb5 w-100`}
      >
        <div className="w-100">
          <div className={style.tabs}>
            <div className={`${style.tabList}`}>
              {tabHeaders.map((item, index) => {
                return (
                  <div
                    onClick={(e) => tabClick(item, index, e)}
                    className={`${style.tab} ${
                      item.tok === currentTab
                        ? style.selected + ' '
                        : // : 'theme-fade-layer50'
                          'theme-hilite-layer2'
                    } hover-hilite`}
                    key={index}
                  >
                    {item.txt}
                  </div>
                )
              })}
            </div>
            {config.auth.federated && (
              <div
                className={`${boxPadding} items-center ba b--transparent pb1 pt3 pt4-ns`}
              >
                <div className="primary mb3 tc w-100">Sign in with:</div>
                <SignOnFederated status={status} setStatus={setStatus} />
                {config.auth.local && (
                  <div className="hrstrike pt3">
                    <div className="f4 primary b">or</div>
                  </div>
                )}
              </div>
            )}
            {config.auth.local && (
              <div className={`${boxPadding} ${style.tabPanel} pb1 mt4`}>
                {config.auth.federated && (
                  <div className="primary mb3 tc w-100">With {config.name}:</div>
                )}
                <SignOnLocal
                  signup={currentTab === 'signup'}
                  label={tabHeadersMap[currentTab].txt}
                  setStatus={setStatus}
                  status={status}
                />
              </div>
            )}
            <div className={`${boxPadding} tc fw2`}>
              {status ? (
                <div className={`${style.authstatus} visible pa2 mt2`}>
                  {status}
                </div>
              ) : null}
            </div>
            <div
              className={`${boxPadding} pv2 pv3-m pv4-l items-center ba b--transparent`}
            >
              {config.auth.local && (
                <div className="i f6 tc mb2">
                  <a href={paths.password}>Forgot Password?</a>
                </div>
              )}
              <Terms />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignForm
