import gql from 'graphql-tag'

export const LIST_TOPICS = gql`
  query journeyTopics {
    journeyTopics {
      id
      name
    }
  }
`

export const LIST_TOPICS_FULL = gql`
  query journeyTopics {
    journeyTopics {
      id
      name
      audience
      summary
    }
  }
`

export const MATCHING_TOPICS = gql`
  query journeyTopics($matching: String!) {
    journeyTopics(matching: $matching) {
      id
      name
      audience
      summary
    }
  }
`

export const TOPIC_SCHEMA = gql`
  query journeyTopicSchema {
    journeyTopicSchema {
      id
      name
      explanation
      maxlen
      order
    }
  }
`

const journeyTopicVars = `
  id
  name
  audience
  summary
  sections {
    id
    name
    type
    data
  }
  schema {
    id
    name
    order
    type
    maxlen
    explanation
  }
`
export const LOAD_TOPIC = gql`
  query journeyTopic($id: String!) {
    journeyTopic(id: $id) {
      ${journeyTopicVars}
      questions {
        id
        question
      }
    }
  }
`

export const UPSERT_TOPIC = gql`
  mutation upsertJourneyTopic(
    $id: String
    $name: String
    $audience: String
    $summary: String
  ) {
    upsertJourneyTopic(id: $id, name: $name, audience: $audience, summary: $summary) {
      success
      reason
      result {
        ${journeyTopicVars}
      }
    }
  }
`

export const UPSERT_TOPIC_SECTION = gql`
  mutation upsertJourneyTopicSection(
    $id: String
    $topicId: String!
    $name: String
    $type: JourneyTopicSectionType
    $data: Json!
  ) {
    upsertJourneyTopicSection(
      id: $id
      topicId: $topicId
      name: $name
      data: $data
      type: $type
    ) {
      success
      reason
      result {
        id
        name
        type
        data
        topic {
          id
        }
      }
    }
  }
`
