import React, { useContext } from 'react'

import Toggle from 'tools/Toggle'

import { OrgContext } from '../reducer'

function Profile() {
  const [org] = useContext(OrgContext)
  console.log({ org })

  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <ToggleValue
        org={org}
        label="Portfolio Studio"
        className="heading mb3 f4"
        token="folio"
        heading={true}
      >
        Portfolio Studio is a way for you to use venturly in organizing your
        portfolio assets. It allows you to have a collection of ventures,
        organized by their lifecycle, and even helps you manage quarterly reports
        and updates as they mature.
      </ToggleValue>

      <ToggleValue
        org={org}
        label="Application Portal"
        className="heading mt4 mb3 f4"
        token="portal"
        disabled={!org.folio}
      >
        Enable an application portal for your organization.
      </ToggleValue>
    </div>
  )
}

// <label className="f4 mt4">Member Visibility</label>
//
// <table className="mt3 mb4 list">
//   <thead>
//     <tr><th></th>
//     <th>Owner</th>
//     <th>Manager</th>
//     <th>Mentor</th>
//     <th>Sensitive</th>
//     <th>Member</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td className="f6 normal tl">List members <b>on <i>{org.name}</i> profile</b></td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//     </tr>
//
//     <tr>
//       <td className="f6 normal tl">Show <i>{org.name}</i> <b>on member's profile</b></td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//     </tr>
//   </tbody>
// </table>
//
// <label className="f4 mt4 mb3">Notification Preferences</label>
// <i>For each event, whom (by role) should receive notifications?</i>
// <table className="mt3 mb4 list">
//   <thead>
//     <tr><th></th>
//     <th>Owner</th>
//     <th>Manager</th>
//     <th>Mentor</th>
//     <th>Sensitive</th>
//     <th>Member</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr><td className="f6">New Applications</td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//       </tr>
// <tr><td className="f6"> Project updates</td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//       </tr>
// <tr><td className="f6"> Billing</td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//       </tr>
// <tr><td className="f6"> Member access request</td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//       </tr>
// <tr><td className="f6"> Reminders and automatic suggestions</td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//       </tr>
// <tr><td className="f6"> Mentor help request</td>
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" /><ToggleOpt org={org} opt="x" />
//       <ToggleOpt org={org} opt="x" />
//       </tr>
//   </tbody>
// </table>
//
// function ToggleOpt({
//   org,
//   opt
// }) {
//   return (
//     <td>
//     <div className="flex-center">
//       <Toggle
//         onChange={() => org.onSave({ token: opt, orig: org[opt] }, !org[opt])}
//         value={org[opt]}
//       />
//       </div>
//     </td>
//   )
// }

function ToggleValue({
  org,
  label,
  children,
  token,
  heading = undefined,
  className = 'heading b mt4 mb3',
  disabled = false
}) {
  return (
    <>
      {heading ? (
        <h2 className={className}>{label}</h2>
      ) : (
        <div className={className}>{label}</div>
      )}
      <div className={disabled ? 'theme-fg-minimize' : ''}>{children}</div>
      <Toggle
        className="mt3 mh3 pa2"
        onChange={() => org.onSave({ token, orig: org[token] }, !org[token])}
        value={org[token]}
        disabled={disabled}
      >
        Enable {label}
      </Toggle>
    </>
  )
}

export default Profile
