import gql from 'graphql-tag'

import { projectListValues } from 'components/Project/graphql'

export const topicValues = `
  id
  shortId
  author {
    id
    handle
    avatar
  }
  type
  subject
  body
  meta
  visible
  insertedAt
  updatedAt
`

export const messageValues = `
  id
  author {
    id
    handle
    avatar
  }
  parentId
  subject
  body
  meta
  visible
  insertedAt
  updatedAt
`

export const UPSERT_TOPIC = gql`
mutation forumTopicUpsert($topic: InputTopic!) {
  forumTopicUpsert(topic: $topic) {
    success
    reason
    result {
      ${topicValues}
    }
  }
}
`

export const LOAD_TOPIC = gql`
query forumTopic($id: String!) {
  forumTopic(id: $id) {
    success
    reason
    result {
      ${topicValues}
      messages {
        meta
        list {
          ${messageValues}
        }
      }
      project {
        ${projectListValues}
      }
    }
  }
}
`

export const UPSERT_MESSAGE = gql`
mutation forumMessageUpsert($message: InputMessage!) {
  forumMessageUpsert(message: $message) {
    success
    reason
    result {
      ${messageValues}
    }
  }
}
`

export const LIST_MESSAGES = gql`
query forumMessages($type: String!, $linkId: String!) {
  forumMessages(type: $type, linkId: $linkId) {
    success
    total
    reason
    result {
      ${messageValues}
    }
  }
}
`
