import React, { useContext, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'

import ICON from 'constants/icons'

import { Loading } from 'tools/Loader'
import Markdown from 'tools/Markdown'
import scrollTo from 'utils/scrollTo'
import { intcmp } from 'utils/string'
import { enrichPosixTime } from 'utils/time'

import {
  LOAD_TOPIC,
  UPSERT_MESSAGE,
  UPSERT_TOPIC
} from 'components/Forums/graphql'
import { normalizeProject } from 'components/Project/normalize'
import GlobalContext from 'reducer/global'

import SubjectLine from '../SubjectLine'
import TopicHeader from '../TopicHeader'
import style from './index.module.scss'

function Comments({ topicId, parentLink }) {
  const [{ user }] = useContext(GlobalContext)
  const [topic, setTopic] = useState(undefined)

  const [upsert] = useMutation(UPSERT_MESSAGE)
  const upsertMessage = (vars, onSuccess, onFail) => {
    upsert({
      variables: vars,
      update(cache, result) {
        if (result.data.forumMessageUpsert.success) {
          const message = result.data.forumMessageUpsert.result
          setTopic(normalizeTopic(topic, message))
          onSuccess && onSuccess(message)
        } else {
          onFail(result.data.forumMessageUpsert.reason)
        }
      }
    })
  }

  useQuery(LOAD_TOPIC, {
    variables: { id: topicId },
    fetchPolicy: 'cache-and-network',
    onCompleted(result) {
      if (result && result.forumTopic.success) {
        setTopic(normalizeTopic(result.forumTopic.result))
      }
    }
  })

  if (!topic) {
    return <Loading />
  }

  const isAdmin = topic && topic.author.id === user.id
  const context = { topic, isAdmin, upsertMessage }

  return (
    <div className="theme-frame theme-bg-flat mv4-ns pa3 pl2 pa4-ns pl3-ns max-view-page relative hidden">
      <div className="pl2 pl3-ns">
        <ShowTopic topic={topic} context={context} setTopic={setTopic} />
      </div>
      <div className="pb2">
        <ShowComments context={context} list={topic.messages.tree} />
      </div>
    </div>
  )
}

function ShowTopic({ topic, setTopic, context }) {
  const [edit, setEdit] = useState(false)
  const [body, setBody] = useState(topic.body)
  const [error, setError] = useState(undefined)

  const [upsertTopic] = useMutation(UPSERT_TOPIC, {
    update(cache, result) {
      if (result.data.forumTopicUpsert.success) {
        const res = result.data.forumTopicUpsert.result
        setTopic(normalizeTopic({ ...topic, ...res }))
        setError(undefined)
        setEdit(false)
      } else {
        setError(result.data.forumTopicUpsert.reason)
      }
    }
  })

  return (
    <div className={style.topic}>
      <TopicHeader topic={topic} setTopic={setTopic} />
      {edit ? (
        <>
          <Editor
            value={body}
            onChange={(ev) => setBody(ev.target.value)}
            onSave={() =>
              upsertTopic({
                variables: {
                  topic: {
                    id: topic.id,
                    body
                  }
                }
              })
            }
          />
          {error ? <div className="tc red">{error}</div> : null}
        </>
      ) : (
        <div className="mv3 doc">
          <Markdown>{body}</Markdown>
        </div>
      )}
      <div className="flex-items f7 b gray">
        <div>
          <i className={`${ICON.chat} mr2`} />
          {topic.meta.posts} Comments
        </div>
        <div className="ml3 hover-primary pointer" onClick={() => setEdit(!edit)}>
          <i className={`${ICON.edit} mr1`} /> Edit Post
        </div>
        <div
          className="ml3 hover-primary pointer"
          onClick={() =>
            upsertTopic({
              variables: {
                topic: {
                  id: topic.id,
                  visible: !topic.visible
                }
              }
            })
          }
        >
          {topic.visible ? (
            <>
              <i className={`${ICON.hide} mr1`} /> Hide
            </>
          ) : (
            <>
              <i className={`${ICON.view} mr1`} /> Make Visible - This topic is
              hidden from view
            </>
          )}
        </div>
      </div>
      <AddComment context={context} list={topic.messages.tree} />
    </div>
  )
}

function AddComment({ context, parentId = null, onSuccess = undefined, list }) {
  const [{ user }] = useContext(GlobalContext)
  const [msg, setMsg] = useState(undefined)
  const [body, setBody] = useState('')
  const { topic, upsertMessage } = context
  return (
    <>
      <div className="fw2 f6 mt4">Posting as u/{user.handle}</div>
      <div className="mt1">
        <Editor
          value={body}
          onChange={(ev) => setBody(ev.target.value)}
          placeholder="What are your thoughts?"
          onSave={() => {
            upsertMessage(
              {
                message: {
                  topicId: topic && topic.id,
                  body,
                  parentId
                }
              },
              (m) => {
                setMsg(undefined)
                setBody('')
                scrollTo(`msg-${m.id}`)
                onSuccess && onSuccess()
              },
              (err) => {
                setMsg(err)
              }
            )
          }}
          label="Comment"
        />
      </div>
      {msg ? <div className="tc red">{msg}</div> : null}
    </>
  )
}

function Editor({
  value,
  onChange,
  placeholder = undefined,
  onSave,
  label = 'Save'
}) {
  return (
    <>
      <textarea
        className="w-100"
        rows={10}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <div className="flex justify-end mt3">
        <button
          className={`button-inverted pa1 ph2`}
          disabled={!value.length}
          onClick={(ev) => {
            ev.stopPropagation()
            onSave()
          }}
        >
          {label}
        </button>
      </div>
    </>
  )
}

function ShowComments({ context, list }) {
  return list.map((p, x) => <Comment key={p.id} msg={p} context={context} />)
}

function Comment({ msg, context }) {
  const [{ user }] = useContext(GlobalContext)
  const [add, setAdd] = useState(false)
  const { upsertMessage, topic, isAdmin } = context
  const detail = topic.messages.map[msg.id]
  const canEdit = detail.author.id === user.id || isAdmin
  return (
    <div id={`msg-${msg.id}`} className={style.message}>
      <SubjectLine msg={detail} showCount={false} className={style.subject} />
      <div className={style.left}>
        <div className={`${style.bar} theme-b-minimize hover-outline-primary`} />
      </div>
      <div className={style.content}>
        <div className={style.inner}>
          <div className="doc">
            <Markdown>{detail.body}</Markdown>
          </div>
          <div className="flex-items f7 mt3 b gray">
            <div onClick={() => setAdd(!add)} className="pointer hover-primary">
              <i className={`${ICON.chat} mr1`} /> Reply
            </div>
            {canEdit ? (
              <div
                className="pointer hover-primary"
                onClick={() => {
                  upsertMessage({
                    message: {
                      id: detail.id,
                      visible: false
                    }
                  })
                }}
              >
                <i className={`${ICON.hide} mr1 ml3`} /> Hide
              </div>
            ) : null}
          </div>
          {add ? (
            <AddComment
              context={context}
              list={msg.children}
              parentId={detail.id}
              onSuccess={() => setAdd(false)}
            />
          ) : null}
        </div>
        <div className={style.children}>
          <ShowComments context={context} list={msg.children} />
        </div>
      </div>
    </div>
  )
}

function normalizeTopic(topic, message = undefined) {
  const messages = { ...topic.messages, map: {}, tree: {} }
  if (message) {
    message = { ...message }
    enrichPosixTime(message, 'updatedAt')
    messages.list = messages.list
      .filter((m) => m.id !== message.id)
      .map((m) => {
        m = { ...m }
        enrichPosixTime(m, 'updatedAt')
        return m
      })
    if (message.visible) {
      messages.list = messages.list.concat(message)
    }
  }
  messages.map = messages.list.reduce((acc, m) => {
    acc[m.id] = m
    return acc
  }, {})
  messages.tree = build_tree(messages.list)
  return { ...topic, messages, project: normalizeProject(topic.project) }
}

function build_tree(messages) {
  // sort list by date
  const sorted = [...messages].toSorted((b, a) =>
    intcmp(a._updatedAt, b._updatedAt)
  )

  // using sorted list, map IDs
  const parents = sorted.reduce((accum, msg) => {
    const parentId = msg.parentId || '_'
    accum[parentId] = [msg.id, ...(accum[parentId] || [])]
    return accum
  }, {})

  const map = sorted.reduce((map, msg) => {
    msg.children = parents[msg.id] || []
    map[msg.id] = msg
    return map
  }, {})

  return build_tree_walk(parents['_'] || [], map)
}

function build_tree_walk(list, map) {
  return list.reduce(
    ({ accum, map }, id) => {
      const node = map[id]

      const res = {
        id: node.id,
        children: build_tree_walk(node.children, map)
      }

      accum.unshift(res)
      return { accum, map }
    },
    { accum: [], map }
  ).accum
}

export default Comments
