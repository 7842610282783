import React, { createContext, useReducer } from 'react'

import { updateAnswer } from './normalize/answer'
import {
  defaultJourneyActor,
  normalizeJourneyActor
} from './normalize/journeyActor'

export const R_JOURNEY = {
  SET_MAP: 1000,
  SET_ACCESS: 1001,
  UPDATE_ANSWER: 2000,
  MERGE: 3000,
  RESET: 4000
}

////////////////////////////////////////////////////////////////////////////////
export function reducer(state, { type, ...action }) {
  switch (type) {
    case R_JOURNEY.UPDATE_ANSWER:
      return updateAnswer(state, action.value)

    case R_JOURNEY.SET_MAP:
      return { ...state, ...normalizeJourneyActor(action.value) }

    case R_JOURNEY.MERGE:
      return { ...state, ...action.value }

    case R_JOURNEY.RESET:
      return { ...state, ...defaultJourneyActor }

    case R_JOURNEY.SET_ACCESS:
      return { ...state, can: { ...action.value } }

    // case R_JOURNEY.UPDATE_MILESTONE_CHALLENGES: {
    //   const milestone = { ...state.journey.milestonesD[action.milestoneId] }
    //   milestone.challenges = normalizeChallenges(action.value)
    //   const milestonesD = {
    //     ...state.journey.milestonesD,
    //     [action.milestoneId]: milestone
    //   }
    //   return { ...state, journey: { ...state.journey, milestonesD } }
    // }
    // case R_JOURNEY.UPDATE_CHALLENGES: {
    //   const { milestoneId, challengeId, value } = action
    //   const milestone = { ...state.journey.milestonesD[milestoneId] }
    //   milestone.challenges = milestone.challengesD((c) => {
    //     if (c.id === challengeId) {
    //       return { ...c, links: normalizeChallenges(value) }
    //     } else {
    //       return c
    //     }
    //   })
    //   const milestonesD = {
    //     ...state.journey.milestonesD,
    //     [action.milestoneId]: milestone
    //   }
    //   return { ...state, journey: { ...state.journey, milestonesD } }
    //   // return state
    // }
    default:
      throw new Error(`no such action.type: ${type}!`)
  }
}

export const JourneyContext = createContext(null)
export const JourneyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultJourneyActor)
  return (
    <JourneyContext.Provider value={[state, dispatch]}>
      {children}
    </JourneyContext.Provider>
  )
}

export default JourneyContext
