import React, { useContext, useState } from 'react'

import paths from 'constants/paths'

import Confirm from 'tools/Confirm'
import { Link } from 'tools/Links'
import { fromNowShort } from 'utils/time'

import { StatusIcons } from 'components/Project/Row'
import pstyle from 'components/Project/Row/index.module.scss'

import { R_STUDIO, StudioContext } from '../reducer'
import style from './Columns.module.scss'

function Columns() {
  const [studio] = useContext(StudioContext)

  return (
    <div className={pstyle.rows}>
      <div className="flex">
        {studio.columnsL.map((cId) => (
          <Col key={cId}>
            <div className="b pa3">{studio.columnsD[cId].name}</div>
          </Col>
        ))}
      </div>
      {studio.cohortsL.map((cId) => (
        <CohortColumn key={cId} cohortId={cId} />
      ))}
    </div>
  )
}

function Col({ children, className = '' }) {
  return (
    <div
      className={`${style.colWidth} ${className}  theme-b-minimize br2 mr2 theme-bg-minimize`}
    >
      {children}
    </div>
  )
}

function CohortColumn({ cohortId }) {
  const [drop, setDrop] = useState(false)
  const [studio, dispatch] = useContext(StudioContext)
  const cohort = studio.cohortsD[cohortId]
  const open = studio.showCohorts[cohortId]

  return (
    <>
      <div className="mv1 flex-items">
        <button
          className="clear pa2 b flex-items w-100"
          onClick={() =>
            dispatch({
              type: R_STUDIO.MERGE,
              value: { showCohorts: { ...studio.showCohorts, [cohortId]: !open } }
            })
          }
        >
          <i className={`fas fa-angle-${open ? 'right' : 'down'} mr2`} />
          {cohort.name}
        </button>
        <button
          className="clear ml-auto ph2 pa1 f6"
          onClick={() => setDrop(true)}
        >
          <i className="fas fa-trash gray" />
        </button>
        {drop ? (
          <Confirm
            viewState={[drop, setDrop]}
            onConfirm={() => {
              studio.onSave(
                {
                  component: 'cohort',
                  meta: { orgId: studio.orgId },
                  cmeta: { portfolioId: studio.id, id: cohort.id },
                  token: 'action'
                },
                'REMOVE'
              )
              setDrop(false)
            }}
          >
            Remove this Cohort?
            <div className="bl bw2 pa2 pl3 mv3">{cohort.name}</div>
          </Confirm>
        ) : null}
      </div>
      {open ? (
        <div className="flex">
          {studio.columnsL.map((colId) => (
            <Column key={colId} colId={colId} cohortId={cohortId} />
          ))}
        </div>
      ) : null}
    </>
  )
}

function Column({ colId, cohortId }) {
  const [studio] = useContext(StudioContext)
  return (
    <Col className={style.colHeight}>
      {(studio.memberCohorts[colId] || []).map((id) => (
        <ProjectCard key={id} projectId={id} cohortId={cohortId} />
      ))}
    </Col>
  )
}

function ProjectCard({ projectId, cohortId }) {
  const [studio] = useContext(StudioContext)
  const m = studio.membersD[projectId]
  if (!m.cohorts.includes(cohortId)) return null

  // add to normalize
  const project = m.project
  const age = fromNowShort(project.updatedAt)

  return (
    <Link
      type="div"
      to={`${paths.project}/${project.shortId}`}
      className="theme-b-minimize hover-outline-primary br2 ma2 pa2 theme-bg-hilite  ba b--transparent hover-hilite pointer"
    >
      <div className="flex-items">
        <div className={pstyle.pic}>
          <div>
            <img
              src={project.avatar && project.avatar.url}
              alt=""
              className="br1"
            />
          </div>
        </div>
        <div className="ml3 b">{project.title}</div>
      </div>
      <div className="">
        <StatusIcons className="pv3" project={project} age={age} />
      </div>
      <div className="f6">
        {(project.milestones || []).map((m) => m.name).join(', ')}&nbsp;
      </div>
    </Link>
  )
}
export default Columns
