import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { LOAD_APPLICATION } from 'components/Org/graphql'
import { usePage } from 'reducer/global'
import GlobalContext from 'reducer/global'

import Application from './Application'
import { APPLY_ANSWERS } from './graphql'
import { ApplyContext, ApplyProvider, R_APPLY } from './reducer'

export function Apply({ Component = ApplyWithTitle, ...props }) {
  return (
    <ApplyProvider>
      <Component {...props} />
    </ApplyProvider>
  )
}

function ApplyWithTitle({ name = 'Apply', ...props }) {
  usePage({ name, background: 'flat' })
  return <ApplyInner {...props} />
}

export function ApplyInner({
  Form = Application,
  projectParam = 'projectId',
  folioParam = 'folioId'
}) {
  const params = useParams()
  const portfolioId = params[folioParam]
  const projectId = params[projectParam]
  const [{ user }] = useContext(GlobalContext)
  const [app, dispatch] = useContext(ApplyContext)

  useQuery(LOAD_APPLICATION, {
    variables: { portfolioId },
    skip: !portfolioId,
    fetchPolicy: 'cache-and-network',
    onCompleted({ orgPublic: { success, reason, result: value } }) {
      if (success && value) {
        dispatch({ type: R_APPLY.SET_ORG, value, user })
      } else {
        dispatch({
          type: R_APPLY.MERGE,
          value: { error: `${portfolioId} is not a valid Application` }
        })
      }
    }
  })

  // load answers
  useQuery(APPLY_ANSWERS, {
    variables: { portfolioId, projectId },
    skip: !portfolioId || !projectId || !app._init,
    fetchPolicy: 'cache-and-network',
    onCompleted(d) {
      if (d) {
        dispatch({ type: R_APPLY.SET_ANSWERS, value: d.applicationAnswers })
      } else {
        dispatch({ type: R_APPLY.RESET, value: undefined })
      }
    }
  })

  if (app.error) {
    return (
      <div className="flex-center mt5">
        <div>
          <h1>{app.error}</h1>
          <div className="mt3 i">
            Look for <b>Investors</b> in the navbar to search for investors with
            open applications
          </div>
        </div>
      </div>
    )
  }
  return <Form />
}

export default Apply
