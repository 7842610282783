import React, { useCallback, useContext } from 'react'

import ProjectContext, { R_PROJECT } from '../reducer'
import FinancialSummary from './FinancialSummary'
import FundingGoal from './FundingGoal'
import Valuation from './Valuation'

function Summary(props) {
  const [project, dispatch] = useContext(ProjectContext)
  const psave = project?.onSave
  const onSave = useCallback(
    ({ token, component }, value, ...args) => {
      psave({ token: component }, JSON.stringify({ [token]: value }), ...args)
      return
    },
    [psave]
  )

  const onChange = useCallback(
    (token, value, component) => {
      dispatch({
        type: R_PROJECT.CHANGE_DATA,
        value,
        token,
        component
      })
    },
    [dispatch]
  )

  if (!project) {
    return null
  }
  const args = { onSave, onChange }
  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <FundingGoal {...args} />
      <Valuation {...args} />
      <FinancialSummary {...args} />
    </div>
  )
}

export default Summary
