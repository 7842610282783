import React, { useContext, useState } from 'react'

import { useQuery } from '@apollo/client'

// import Events from 'components/Meet/Browse'
import paths from 'constants/paths'

import { ExtLink, Link } from 'tools/Links'
import Markdown from 'tools/Markdown'
import { intcmp } from 'utils/string'

import GlobalContext from 'reducer/global'

import { LOAD_TOPIC } from '../../components/Learn/graphql'

function ViewTopic({ topicId }) {
  const [{ user }] = useContext(GlobalContext)
  const canEdit = user.can('learn_edit')
  const [topic, setTopic] = useState({
    id: '',
    schema: [],
    name: '',
    audience: '',
    summary: '',
    sections: {}
  })
  useQuery(LOAD_TOPIC, {
    variables: { id: topicId },
    fetchPolicy: 'cache-and-network',
    skip: !topicId,
    onCompleted(data) {
      if (data && data.journeyTopic) {
        const topic = data.journeyTopic
        const schema = topic.schema.toSorted((a, b) => intcmp(a.order, b.order))
        const sections = topic.sections.reduce(
          (acc, { id, name, type, data }) => {
            acc[name] = Object.freeze({ id, name, type, data })
            return acc
          },
          {}
        )
        setTopic(Object.freeze({ ...topic, schema, sections }))
      }
    }
  })

  return (
    <div className="pa2 pa3-m pa4-l relative">
      {canEdit ? (
        <div className="absolute top-1 right-1">
          <Link to={`${paths.learn}/${topicId}/edit`}>
            <i className="fas fa-edit" />
          </Link>
        </div>
      ) : null}
      <div className="f2">{topic.name}</div>
      <div className="mv2">
        <b>Audience:</b> {topic.audience}
      </div>
      <div className="mv2 mv3-m mv4-l">{topic.summary}</div>
      {topic.schema.map((item) => {
        const section = topic.sections[item.name]
        if (!section) return null
        switch (section.type) {
          case 'text':
            return <SectionText key={item.id} section={section} item={item} />
          case 'links':
            return <SectionLinks key={item.id} section={section} item={item} />
          default:
            return <>UNKNOWN TYPE '{section.type}'</>
        }
      })}
    </div>
  )
}

function SectionText({ section, item }) {
  const content = section.data?.text || ''
  if (!content.length) {
    return null
  }
  return (
    <div className="mt1 mt2-m mt2-l lh-copy">
      {item.name !== 'Summary' ? (
        <div className="f5 mt4 heading">{item.name}</div>
      ) : (
        <></>
      )}
      <div className="mt4">
        <Markdown>{content}</Markdown>
      </div>
    </div>
  )
}

function SectionLinks({ section, item }) {
  const links = section.data?.links || []
  if (!links.length) {
    return null
  }
  return (
    <div className="mt1 mt2-m mt2-l lh-copy">
      <div className="f5 mt4 heading">{item.name}</div>
      <ul>
        {links.map((l) => (
          <li key={l.id}>
            <ExtLink to={l.link}>
              {l.name}
              {l.source && <i className="f6 ml1">&lt;{l.source}&gt;</i>}
              <i className="fa fa-external-link-alt f7 ml1" />
            </ExtLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

// <div className="f5 mt4 heading mb3">Upcoming Events for this Topic</div>
// <Events
//   action="PUBLIC"
//   chrome={false}
//   filter={{ topics: [topic.id] }}
//   limit={5}
// />

export default ViewTopic
