import React, { useContext, useState } from 'react'

import Input from 'tools/Input'

import { R_STUDIO, StudioContext } from '../reducer'

function Cohorts({ className }) {
  const [studio] = useContext(StudioContext)
  return (
    <div className={`${className} flex`}>
      {studio.cohortsL.map((id) => (
        <CohortName key={id} cohort={studio.cohortsD[id]} />
      ))}
      <CohortName />
    </div>
  )
}

function CohortName({ cohort = undefined }) {
  const [studio, dispatch] = useContext(StudioContext)
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(cohort?.name || '')
  const hasCohorts = studio.cohortsL.length > 0

  let classes = 'ph2 pv1 mr2 '
  let onClick = () => setEdit(true)
  let on2Click = undefined
  if (cohort) {
    const f = studio.showCohorts
    classes += f[cohort.id] ? 'primary' : 'plain'
    on2Click = onClick
    onClick = () =>
      dispatch({
        type: R_STUDIO.MERGE,
        value: { showCohorts: { ...f, [cohort.id]: !f[cohort.id] } }
      })
  } else {
    classes += hasCohorts ? 'clear' : 'plain'
  }
  return (
    <div>
      {edit ? (
        <Input
          token="name"
          autoFocus={true}
          component="cohort"
          cmeta={{ portfolioId: studio.id, id: cohort?.id }}
          meta={{ orgId: studio.orgId }}
          valState={[name, (value) => setName(value)]}
          onSave={(args, value, good, bad) => {
            if (value === '') {
              setEdit(false)
            } else {
              !cohort && setName('')
              studio.onSave(
                args,
                value,
                () => {
                  good && good()
                  setEdit(false)
                },
                bad
              )
            }
          }}
          placeholder="Cohort Name"
        />
      ) : (
        <button className={classes} onClick={onClick} onDoubleClick={on2Click}>
          <div className={!cohort && hasCohorts ? 'theme-fg-minimize' : ''}>
            {cohort ? (
              cohort.name
            ) : (
              <>
                <i className="fas fa-plus" />
                {hasCohorts ? <></> : <span className="ml2">Add Cohort</span>}
              </>
            )}
          </div>
        </button>
      )}
    </div>
  )
}

export default Cohorts
