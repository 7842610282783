import React, { useCallback, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'

import Modal from 'tools/Modal'
import { CheckIcon } from 'tools/Uniform'

import { JOURNEYS, JOURNEY_ACTOR_ADD } from 'components/Journey/graphql'

export function AddJourney({
  onSave,
  refId,
  type,
  minimize = false,
  className = '',
  current = []
}) {
  const [add, setAdd] = useState(false)
  if (!add) {
    return (
      <div>
        <button
          className={`${className} pv1 ph2 ${minimize ? 'fader' : ''}`}
          onClick={() => setAdd(true)}
        >
          <i className="fa fa-plus" />
          <span className="fades disappear ml2">Add/Remove Journey</span>
        </button>
      </div>
    )
  }
  return (
    <Modal viewState={[add, setAdd]} width="fw-75">
      <div className="pa4">
        <SelectJourney
          refId={refId}
          type={type}
          onSave={(data) => {
            onSave(data)
            setAdd(false)
          }}
          current={current}
        />
      </div>
    </Modal>
  )
}

export function SelectJourney({
  refId,
  type,
  onSave,
  current,
  label = true,
  explain = true
}) {
  const { data } = useQuery(JOURNEYS, {
    variables: { type },
    fetchPolicy: 'cache-and-network'
  })
  if (!data) return null

  const { journeys } = data

  return (
    <>
      {label ? (
        <label className="heading db mb3">Available Journeys</label>
      ) : null}
      {explain ? (
        <i className="db mb3">Choose a journey to add to your venture:</i>
      ) : null}
      {journeys.map((j, x) => (
        <JourneyRow
          key={j.id}
          journey={j}
          refId={refId}
          type={type}
          onSave={onSave}
          current={current}
        />
      ))}
    </>
  )
}

export function JourneyRow({ journey, type, refId, onSave, current }) {
  const checked = current.includes(journey.id)

  const [addActorMutation] = useMutation(JOURNEY_ACTOR_ADD, {
    update(cache, data) {
      onSave(data, refId)
    }
  })
  const journeyId = journey.id
  const addActor = useCallback(
    (checked) => {
      const variables = {
        action: checked ? 'UPSERT' : 'REMOVE',
        journeyId,
        type,
        refId
      }
      addActorMutation({ variables })
    },
    [addActorMutation, journeyId, refId, type]
  )

  if (journey.type !== type || journey.status !== 'open') {
    return null
  }
  return (
    <button
      className={`clear w-100 flex-between ${checked ? 'primary' : ''}`}
      onClick={() => {
        addActor(!checked)
      }}
    >
      <div className="b">
        <CheckIcon checked={checked} className="mr3" />
        {journey.name}
      </div>
      <div className="ml3 i">
        <span className="mr3">&mdash;</span>
        {journey.description}
      </div>
      <div className="ml-auto">
        <img src="/assets/img/venturly.svg" style={{ height: '1.5rem' }} alt="" />
      </div>
    </button>
  )
}

export default AddJourney
