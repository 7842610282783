import React, { useState } from 'react'

import { useQuery } from '@apollo/client'

import { LoadingInline } from 'tools/Loader'

import { LIST_PUBLIC_PEOPLE } from 'components/Person/graphql'
import PersonLabel from 'components/Person/tools//Label'

import { defaultEdit } from './Edit'
import style from './Search.module.scss'

// ...ref should be either orgId or projectId
function Search({ editState: [edit, setEdit], close, target, ...ref }) {
  const [search, setSearch] = useState('')
  const isEmail = search.includes('@')
  const { data, loading } = useQuery(LIST_PUBLIC_PEOPLE, {
    variables: { filter: { name: search } },
    skip: !search || isEmail
  })
  const results = loading || (data && data.publicPeople.result)
  let existing = target.members.reduce((ex, mbr) => {
    ex[mbr.user.id] = true
    return ex
  }, {})
  existing = target.invites.reduce((ex, inv) => {
    ex[inv.targetId] = true
    return ex
  }, existing)
  const notMyMembers =
    data && data.publicPeople.result
      ? data.publicPeople.result.filter((p) => !existing[p.id])
      : []
  return (
    <div className="mt3">
      <input
        className="w-100"
        value={search}
        onChange={(v) => setSearch(v.target.value)}
        placeholder="Search for user by handle"
      />
      <div className="relative">
        <div
          className={`${style.results} ${
            results || isEmail ? style.visible : ''
          } theme-bg-flat ba b--theme-faint menu`}
        >
          {loading ? (
            <LoadingInline />
          ) : notMyMembers.length > 0 ? (
            notMyMembers.map((p) => (
              <PersonLabel
                noLink={true}
                key={p.id}
                onClick={() => {
                  setSearch('')
                  setEdit({ ...defaultEdit, user: p, isNew: true })
                  close()
                }}
                person={p}
              />
            ))
          ) : isEmail ? (
            <div
              className="primary b hover pa2 ph3 hover-hilite br2"
              onClick={() => {
                setEdit({ ...defaultEdit, isNew: true, email: search })
                setSearch('')
                close()
              }}
            >
              Send email invite to: <code>{search}</code>
            </div>
          ) : (
            <div>
              Nobody found matching `{search}` who is not already a member.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Search
