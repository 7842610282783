import React, { useContext } from 'react'

import SignForm from 'components/Signon/SignForm'
import GlobalContext from 'reducer/global'

function IdentifiedUser({ children, className = '', alt = undefined }) {
  const [{ user }] = useContext(GlobalContext)

  if (user.isIdentified) {
    return children
  }
  return <UnidentifiedUser>{alt}</UnidentifiedUser>
}

function UnidentifiedUser({ children }) {
  return (
    <SignForm signup={true}>
      <div className="flex flex-column items-center">
        <h1 className="mb3">Welcome!</h1>
        <div className="theme-frame pa2 pa3-m pa4-l mh4 mb4 w-50-l">
          <div className="f4 tc ph2 w-100">
            {children ? (
              children
            ) : (
              <div className="mv3">
                For this feature to work properly, we need you to first sign in.
                Thanks!
              </div>
            )}
          </div>
        </div>
      </div>
    </SignForm>
  )
}

export default IdentifiedUser
