export const MEMBER_TYPES = {
  follower: 'can view non-public documents and is NOT listed on venture.',
  investor: 'can view non-public documents and is listed on venture.',
  // sponsor: 'can view non-public documents and is listed on venture.',
  advisor: 'can view non-public documents and is listed on venture.',
  contributor: 'can view and change documents and is listed on venture.',
  admin: 'has all privileges and is listed on venture'
}

// future: have this pull from profileTags:roles
export const ROLES = Object.freeze(
  [
    {
      value: 'techdev',
      label: 'Technical Development',
      type: 'maker',
      info: 'Engineering and Construction'
    },
    {
      value: 'bizdev',
      label: 'Business Development',
      type: 'maker',
      info: 'Logistics, Partnerships, Funding'
    },
    {
      value: 'design',
      label: 'Product Design',
      type: 'maker',
      info: 'Visual, Product, UX and other design needs'
    },
    {
      value: 'sales',
      label: 'Sales',
      type: 'growth',
      info: 'Contacting and closing with customers'
    },
    {
      value: 'marketing',
      label: 'Marketing',
      type: 'growth',
      info: 'Setup and run campaigns and services to find customers'
    },
    {
      value: 'product',
      label: 'Product Management',
      type: 'facilitator',
      info: 'Overall product team and roadmap management'
    },
    {
      value: 'project',
      label: 'Project Management',
      type: 'facilitator',
      info: 'Managing venture efforts'
    },
    {
      value: 'techops',
      label: 'Technical Operations',
      type: 'sustainer',
      info: 'IT - servers, services and other non-engineering tech'
    },
    {
      value: 'bizops',
      label: 'Business Operations',
      type: 'sustainer',
      info: 'HR, Finance, etc'
    },
    {
      value: 'customer',
      label: 'Customer Success & Support',
      type: 'sustainer',
      info: 'Post sales customer support and sustainment'
    }
  ].map((r) => {
    r.text = r.info
    return r
  })
)

export const ROLES_MAP = Object.freeze(
  ROLES.reduce((a, r) => {
    a[r.value] = r
    return a
  }, {})
)
