import React, { useState } from 'react'

import Modal from 'tools/Modal'

function Confirm({ onConfirm, children = undefined, title = '', viewState }) {
  return (
    <Modal viewState={viewState} width="fw-min" className="pa4">
      <div className="f3 b">{title || 'Are you sure?'}</div>
      <div className="mt3">{children}</div>
      <div className="mt3 flex justify-between">
        <button className="large border" onClick={() => viewState[1](false)}>
          No
        </button>
        <button className="large" onClick={onConfirm}>
          Yes
        </button>
      </div>
    </Modal>
  )
}

export function Delete({ className = '', onConfirm, children }) {
  const [drop, setDrop] = useState(false)
  return (
    <>
      <div
        className={`${className} pointer hover-hilite pa1 gray br2 f6`}
        onClick={(ev) => {
          ev.stopPropagation()
          ev.preventDefault()
          setDrop(!drop)
        }}
      >
        <i className="fas fa-trash" />
      </div>
      {drop ? (
        <Confirm
          viewState={[drop, setDrop]}
          onConfirm={() => {
            onConfirm()
            setDrop(false)
          }}
        >
          {children}
        </Confirm>
      ) : null}
    </>
  )
}

export default Confirm
