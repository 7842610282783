import React from 'react'
import { createRoot } from 'react-dom/client'

import config from 'constants/config'

import { NotifyProvider } from 'tools/Notify/resolver'

import AuthXProvider from 'components/Signon/Provider'
import { GlobalProvider } from 'reducer/global'

import App from './App'
import './styles/index.scss'

console.log(`BUILD=${config.build} APP=${config.url.app}`)
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <GlobalProvider>
    <NotifyProvider>
      <AuthXProvider>
        <App />
      </AuthXProvider>
    </NotifyProvider>
  </GlobalProvider>
)
