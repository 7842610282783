import React, { useContext } from 'react'

import ICON from 'constants/icons'
import paths from 'constants/paths'

import { ControlIcon, Controls } from 'tools/Controls'
import { Link } from 'tools/Links'
import Markdown from 'tools/Markdown'

import { OrgContext } from 'components/Org/reducer'

import Summary from './Summary'

////////////////////////////////////////////////////////////////////////////////
function Show() {
  const [org] = useContext(OrgContext)

  if (!org._normal) {
    return null
  }
  return (
    <div className="theme-frame theme-bg-flat mv4-ns max-view-page relative hidden">
      <div className="relative pa4">
        <Controls>
          {org.canEdit ? (
            <ControlIcon
              icon={ICON.edit}
              className="button primary"
              to={`${paths.org}/${org.shortId}/edit`}
            />
          ) : null}
          <ControlIcon icon="fas fa-times" to={paths.org} />
        </Controls>
        <Summary org={org} />
        <Applications org={org} />
        <div className="mt2">
          <Markdown>{org.detail}</Markdown>
        </div>
      </div>
    </div>
  )
}

function Applications({ org }) {
  if (!org.folio || !org.portal || org.accepting.length === 0) {
    return null
  }
  if (org.accepting.length === 1) {
    return (
      <div className="mt2 flex-center">
        <Apply folio={org.accepting[0]} label="Apply for Sponsorship" />
      </div>
    )
  }
  return (
    <div>
      <div className="flex justify-center heading f6">Accepting Applications</div>
      <div className="mt2 mb4 flex-center">
        {org.accepting.map((f) => (
          <Apply folio={f} key={f.id} />
        ))}
      </div>
    </div>
  )
}

function Apply({ folio, label = '' }) {
  return (
    <Link
      className="medium mh2"
      type="button"
      to={`${paths.apply}/${folio.shortId}`}
    >
      {label ? label : folio.name}
    </Link>
  )
}

export default Show
