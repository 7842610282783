import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef
} from 'react'

import { LoadingInline as Loading } from 'tools/Loader'

////////////////////////////////////////////////////////////////////////////////
export const defaultBrowser = {
  list: [],
  last: -1,
  total: 0,
  matching: 0,
  limit: 10,
  offset: 0,
  index: {},
  loading: true,
  scrolling: false,
  filter: {}
}

////////////////////////////////////////////////////////////////////////////////
export function normalizeBrowser(state, { value, normalize }) {
  if (normalize) return normalize(state, value)

  return Object.freeze({ ...state, scrolling: false, loading: false, ...value })
}

export function Browser({ children, className = '', top = 'top-0' }) {
  return (
    <BrowserProvider>
      <InnerBrowser className={className} top={top}>
        {children}
      </InnerBrowser>
    </BrowserProvider>
  )
}

const observerOpts = {
  root: null,
  rootMargin: '20px',
  threshold: 0
}

function InnerBrowser({ children, className, top }) {
  const [browse, dispatch] = useContext(BrowserContext)
  const loadRef = useRef(null)
  const { loading, last, matching, list } = browse
  const shouldPaginate = !loading && last !== 0 && matching !== list.length

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0]
      if (target.isIntersecting && shouldPaginate) {
        dispatch({ type: R_BROWSER.NEXT })
      }
    },
    [dispatch, shouldPaginate]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, observerOpts)
    if (loadRef.current) observer.observe(loadRef.current)
  }, [handleObserver])

  return (
    <div className={`relative ${className}`}>
      {browse.loading && (
        <div className={`flex-center absolute ${top} w-100`}>
          <Loading />
        </div>
      )}
      {children}
      <div className="flex-center w-100">
        {browse.list.length > 0 ? (
          browse.loading ? (
            <Loading />
          ) : (
            <>
              {browse.list.length} most active of {browse.matching} matches (
              {browse.total} total)
            </>
          )
        ) : null}
      </div>
      {!browse.loading && <div ref={loadRef} />}
    </div>
  )
}

export const R_BROWSER = {
  SET: 100,
  SET_FILTER: 101,
  RESET: 200,
  MERGE: 300,
  NEXT: 400
}

export function reducer(state, { type, ...args }) {
  switch (type) {
    case R_BROWSER.SET:
      // @ts-ignore
      return normalizeBrowser(state, args)

    case R_BROWSER.RESET:
      // @ts-ignore
      return normalizeBrowser(defaultBrowser, args)

    case R_BROWSER.NEXT:
      return {
        ...state,
        loading: true,
        offset: state.offset + state.limit,
        scrolling: true
      }

    case R_BROWSER.SET_FILTER: {
      const { key, value } = args
      return {
        ...state,
        scrolling: false,
        offset: 0,
        filter: { ...state.filter, [key]: value }
      }
    }

    case R_BROWSER.MERGE:
      return { ...state, ...args.value }

    default:
      throw new Error(`browser - no such action.type: ${type}!`)
  }
}

export const BrowserContext = createContext(null)
export const BrowserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultBrowser)
  return (
    <BrowserContext.Provider value={[state, dispatch]}>
      {children}
    </BrowserContext.Provider>
  )
}
