import React, { useCallback, useContext } from 'react'

import FileEditor from 'tools/Files'
import { useSaveUrl } from 'tools/FormHelpers/Url'
import Input from 'tools/Input'
import { INPUT_TYPES } from 'tools/Input'
import Toggle from 'tools/Toggle'
import { CheckIcon } from 'tools/Uniform'
import { capitalize } from 'utils/string'
import { formatDateTime } from 'utils/time'

import { AnswerStatusLabel } from 'components/Journey/Rubric/Questions'
import { ANSWER_STATUS as JOURNEY_ANSWER_STATUS } from 'components/Journey/normalize/answer'
import ShowFiles from 'components/Project/Show/Files'
import GlobalContext from 'reducer/global'

import SelectJourney from './SelectJourney'
import { ApplyContext, R_APPLY } from './reducer'

function FieldValue({ app, field, edit = true }) {
  const { answersD = {} } = app
  const answer = answersD[field.id] || {}

  let args = { field, app, answer, edit }
  switch (field.type) {
    case 'journey':
      return <FieldJourney {...args} />
    case 'line':
    case 'email':
    case 'phone':
      return edit ? <Edit {...args} /> : <View {...args} />
    case 'text':
      return edit ? (
        <Edit type={INPUT_TYPES.TEXTAREA} {...args} />
      ) : (
        <View {...args} />
      )
    case 'status':
      return <ApplicationStatus {...args} />
    case 'files':
      return <Files {...args} />
    case 'website':
      return edit ? <EditUrl {...args} /> : <View {...args} />
    case 'toggle':
      return <Toggled {...args} />
    case 'shareContact':
      return <ShareContact {...args} />
    case 'rubricQuestion':
      return edit ? (
        <RubricQuestion {...args} />
      ) : (
        <View {...args} token="answer" />
      )
    default:
      throw new Error(`unrecognized field.type: ${field.type}!`)
  }
}

function RubricQuestion({ field, answer, app, edit }) {
  const isWorking = answer.statusA !== JOURNEY_ANSWER_STATUS.WORKING
  return (
    <>
      {isWorking ? (
        <textarea
          disabled={true}
          className="mv2 w-100"
          defaultValue={answer.answer}
        />
      ) : (
        <Edit
          field={field}
          type={INPUT_TYPES.TEXTAREA}
          token="answer"
          className="mv2"
          app={app}
          answer={answer}
          edit={edit}
        />
      )}
      {answer.jmap ? (
        <div className="i f6 flex items-start justify-end">
          <div className="nowrap flex mr1 items-start">
            <AnswerStatusLabel answer={answer} className="mr0" />:
          </div>
          <div>
            {isWorking ? (
              <>
                This is a Journey answer which has advanced beyond 'Work in
                Progress.' Changes must be made in Your {answer.jname}.
              </>
            ) : (
              <>This is part of your {answer.jname}.</>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

function FieldJourney({ field, app, answer }) {
  if (field.meta.fixed) {
    return null
  }

  return (
    <>
      <div className="primary b mt5 f4">Choose a Journey</div>
      <div className="f6 i mt3 mb3">
        Journeys help you as a guide to provide information and demonstrate
        readiness to investors. Choose one that best fits your offering:
      </div>
      <SelectJourney type="project" field={field} />
    </>
  )
}

function View({ token = 'value', answer, app }) {
  return <div className="input w-100 mt2 disabled">{answer[token]}</div>
}

function Edit({ field, token = 'value', answer, app, edit, ...args }) {
  return (
    <Input
      className="w-100 mt2"
      token={token}
      field={field}
      valState={[answer[token] || '', (v) => app.onChange(field.id, token, v)]}
      onSave={app.onSave}
      {...args}
    />
  )
}

function EditUrl({
  field,
  app: { onChange, onSave },
  token = 'value',
  answer,
  edit
}) {
  const saveUrl = useSaveUrl(onSave)
  return (
    <Input
      className="w-100 mt2"
      token={token}
      field={field}
      valState={[answer[token] || '', (v) => onChange(field.id, token, v)]}
      onSave={saveUrl}
    />
  )
}

function ShareContact({ field, app, answer, edit }) {
  let label = 'Authorized'
  if (answer.ok) label = `${label} on ${formatDateTime(answer.date)}`

  if (!edit) return <ReadToggle value={answer.ok} label={label} />

  return (
    <>
      <Toggle
        className="mt3 pl3 pv1"
        onChange={() => app.onSave({ field, token: 'ok' }, !answer.ok)}
        value={!!answer.ok}
      >
        <div>
          I authorize Venturly to provide my contact email and/or phone number to{' '}
          {app.org.name}.
        </div>
      </Toggle>
      {answer.ok ? <div className="ml5 f6 i">{label}</div> : null}
    </>
  )
}

function ReadToggle({ value, label = 'Selected' }) {
  return (
    <div className="mt2 ml3">
      <CheckIcon checked={value} className="mr3" />
      {value ? label : 'NOT ' + label}
    </div>
  )
}

function Toggled({ field, app, answer, edit }) {
  if (!edit) return <ReadToggle value={answer.value} />

  return (
    <Toggle
      className="mt3 pl3 pv1"
      onChange={() => app.onSave({ field, token: 'value' }, !answer.value)}
      value={!!answer.value}
    >
      {field.meta.label}
    </Toggle>
  )
}

function Files({ field, answer, edit }) {
  const [{ project }, dispatch] = useContext(ApplyContext)
  const [{ user }] = useContext(GlobalContext)

  const fileTypes = answer.fileTypes || {}
  const refType = field.meta.link
  const types = field.meta.types

  // TODO: add secondary call to applicationAnswer to trigger progress/status updates
  const onAdd = useCallback(
    (value) => dispatch({ type: R_APPLY.UPSERT_FILE, value, field }),
    [dispatch, field]
  )
  const onDelete = useCallback(
    (value) => dispatch({ type: R_APPLY.DELETE_FILE, value, field }),
    [dispatch, field]
  )

  if (!edit) return <ShowFiles project={project} types={types} className="mt2" />

  return (
    <div className="mt2">
      <FileEditor
        onAdd={onAdd}
        onDelete={onDelete}
        fileTypes={fileTypes}
        refType={refType}
        refId={refType === 'project' ? project.id : user.id}
        heading={false}
        types={types}
      />
    </div>
  )
}

function ApplicationStatus({ field, app, answer, edit }) {
  if (!app.status || !edit) {
    return null
  }
  if (app.status === 'decline') {
    return (
      <>
        <label className="primary f4 mt5 mb3 flex-items">
          Application Declined
        </label>
        This application has been declined.
      </>
    )
  } else {
    const preparing = app.status === 'preparing'
    const ready = app.progress.good >= app.progress.required
    const buttons = nextButtons(app.status)
    return (
      <>
        <label className="primary f4 mt5 flex-items">
          {preparing ? (
            'Are you done?'
          ) : (
            <>
              Application Status:
              <span className="i theme-fg-hilite ml2">
                {capitalize(app.status)}
              </span>
            </>
          )}
        </label>
        {app.progress.percent < 100 ? <div className="mb3 f6 i"></div> : null}
        {preparing ? (
          <div className="f6 i mt3 mb3">
            {ready ? (
              "When you are ready click 'Submit'"
            ) : (
              <>
                Your changes are automatically saved as you work through this
                form, and you can come back as time allows.
                <div className="mv3 b accent">
                  Your application is incomplete.
                </div>
                Please review your answers, looking for those marked as required
                with an asterisk (<span className="red">*</span>).
              </>
            )}
          </div>
        ) : null}
        <div className="flex mt3 items-center">
          {buttons.map(([label, state, classes]) => (
            <button
              key={label}
              className={`mr3 large ${classes}`}
              onClick={() => app.onSave({ field, token: 'status' }, state)}
              disabled={!ready && label === 'Submit'}
            >
              {label}
            </button>
          ))}
        </div>
      </>
    )
  }
}

function nextButtons(current) {
  switch (current) {
    case 'preparing':
      return [
        ['Submit', 'submitted', ''],
        ['Withdraw', 'withdrawn', 'plain']
      ]
    case 'withdrawn':
      return [['Restart', 'preparing', 'plain']]
    case 'submitted':
      return [
        ['Restart', 'preparing', 'plain'],
        ['Withdraw', 'withdrawn', 'plain']
      ]
    case 'accepted':
      return [['Withdraw', 'withdrawn', 'plain']]
    case 'declined':
      return []
    default:
      throw new Error(`unrecognized status: ${current}!`)
  }
}

export default FieldValue
