import gql from 'graphql-tag'

import { fileVars } from 'tools/Files/graphql'

export const APPLY_ANSWERS = gql`
  query applicationAnswers($projectId: String!, $portfolioId: String!) {
    applicationAnswers(projectId: $projectId, portfolioId: $portfolioId) {
      status
      progress
      answers
    }
  }
`

export const UPSERT_APPLY_ANSWER = gql`
  mutation upsertApplicationAnswer(
    $fieldId: String!
    $projectId: String!
    $portfolioId: String!
    $value: Json!
  ) {
    upsertApplicationAnswer(
      fieldId: $fieldId
      projectId: $projectId
      portfolioId: $portfolioId
      value: $value
    ) {
      success
      reason
      result
      status
      progress
    }
  }
`

const projectApplicationVars = `
id
shortId
title
files {
  ${fileVars}
}
portfolioMembers(id: $portfolioId) {
  id
  status
  application
}
journeyActor {
  id
  type
  journeyMaps {
    id
    shortId
    milestoneId
    milestone {
      id
      name
      meta
    }
    journey {
      id
      name
    }
  }
}
`

export const LINK_PROJECT = gql`
  mutation upsertProject($project: InputProject!, $portfolioId: String!) {
    upsertProject(project: $project) {
      success
      reason
      result {
        ${projectApplicationVars}
      }
    }
  }
`

export const LOAD_PROJECT = gql`
  query projects($projectId: String!, $portfolioId: String!) {
    projects(filter: {id: $projectId}) {
      success
      reason
      total
      matching
      result {
        ${projectApplicationVars}
      }
    }
  }
`
