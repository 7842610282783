import React, { createContext, useReducer } from 'react'

import { normalizeParentFiles } from 'tools/Files/normalize'
import { closeTab, openTab } from 'tools/NavTabs'
import { removeItem, upsertItem } from 'utils/array'

import { defaultOrg, normalizeOrg, normalizeOrgTags } from './normalize'

export const R_ORG = {
  SET_TAGS: 1,
  SET_ORG: 2,
  UPSERT_FILE: 4,
  DELETE_FILE: 5,
  CHANGE: 6,
  MERGE: 7,
  OPEN_TAB: 8,
  CLOSE_TAB: 9,
  REFRESH: 10
}

////////////////////////////////////////////////////////////////////////////////
export function reducer(state, { type, value, ...args }) {
  switch (type) {
    case R_ORG.UPSERT_FILE:
      return normalizeParentFiles(state, upsertItem(state.files, value))

    case R_ORG.DELETE_FILE:
      return normalizeParentFiles(state, removeItem(state.files, value))

    case R_ORG.SET_ORG:
      return { ...state, ...normalizeOrg(value, args.user) }

    case R_ORG.SET_TAGS:
      return normalizeOrgTags(state, value)

    case R_ORG.CHANGE:
      return { ...state, [args.token]: value }

    case R_ORG.MERGE:
      return { ...state, ...value }

    case R_ORG.OPEN_TAB:
      return { ...state, tabs: openTab(state.tabs, value) }

    case R_ORG.CLOSE_TAB:
      return { ...state, tabs: closeTab(state.tabs, value) }

    default:
      throw new Error(`org - no such action.type: ${type}!`)
  }
}

export const OrgContext = createContext(null)
export const OrgProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultOrg)
  return (
    <OrgContext.Provider value={[state, dispatch]}>
      {children}
    </OrgContext.Provider>
  )
}

export default OrgContext
