import { put } from 'utils/dict'
import { intcmp } from 'utils/string'

import { normalizeChallengeLinks } from './challenge'
import { normalizeRubrics } from './rubric'

export const defaultJourney = Object.freeze({
  id: '',
  name: '',
  rubricsL: [],
  rubricsD: {},
  rubricsShort: {},
  rubricsShow: [],
  q2r: {},
  milestones: [],
  milestonesD: {},
  milestonesShort: {},
  milestonesOpen: {}
})

export function normalizeJourney(
  {
    milestones,
    rubrics,
    id,
    name,
    meta = {},
    status,
    description,
    type,
    org,
    ...rest
  },
  actor
) {
  const { milestonesD, milestonesL, milestonesOpen } = normalizeMilestones(
    milestones
  )
  const { rubricsL, rubricsD, rubricsShort, rubricsShow } = normalizeRubrics(
    rubrics,
    milestonesOpen,
    actor
  )
  return Object.freeze({
    id,
    name,
    meta,
    status,
    description,
    type,
    org: { ...org },
    rubricsL,
    rubricsD,
    rubricsShort,
    rubricsShow,
    milestonesD,
    milestonesL,
    milestonesOpen
  })
}

function reduceMilestones(acc, { id, name, order, meta, challengeLinks }) {
  const { linksD: challengesD, linksL: challengesL } = normalizeChallengeLinks(
    challengeLinks
  )
  return put(
    acc,
    id,
    // TODO: refactor everything looking at .challenges to use the D/L syntax
    Object.freeze({ id, name, order, meta, challengesD, challengesL })
  )
}

export function normalizeMilestones(milestones) {
  let milestonesOpen = {}
  let milestonesD = {}
  let milestonesL = []
  if (milestones !== undefined) {
    milestonesD = milestones.reduce(reduceMilestones, {})
    milestonesL = milestones
      .toSorted((a, b) => intcmp(a.order, b.order))
      .map((m) => {
        milestonesOpen[m.id] = true
        // what was this for...
        // if (jamap.milestoneId === jamap.journey.milestonesL[x]) {
        //   break
        // }
        return m.id
      })
  }
  return { milestonesOpen, milestonesD, milestonesL }
}
