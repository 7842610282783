import React, { useContext, useState } from 'react'

import paths from 'constants/paths'

import { Link } from 'tools/Links'
import Modal from 'tools/Modal'

import GlobalContext, { R_GLOBAL } from 'reducer/global'

import style from './index.module.scss'

function Filters() {
  const [edit, setEdit] = useState(false)
  const [
    {
      prefs: { orgList: prefs }
    },
    dispatch
  ] = useContext(GlobalContext)
  const setPref = (key, value) => {
    dispatch({
      type: R_GLOBAL.PREFS_SET,
      key: 'orgList',
      value: { [key]: value }
    })
  }

  // <div className={`${style.filtersMobile} db dn-ns tc`}>
  //   <div>
  //     <button className="navlink" onClick={() => setEdit(true)}>
  //       Filters <i className="fas fa-chevron-down" />
  //     </button>
  //   </div>
  // </div>
  return (
    <>
      <div className="dn db-ns">
        <div className={`navbar filters ${style.filters} flex justify-center`}>
          <input
            value={prefs.name || ''}
            onChange={(e) => setPref('name', e.target.value)}
            placeholder="Search investor org names..."
            className="mr3"
            style={{ width: '15rem' }}
          />
          <Link to={paths.orgCreate} className="button border ph2 pv1">
            Create
          </Link>
        </div>
      </div>
      {edit ? (
        <Modal
          viewState={[edit, setEdit]}
          width="full"
          className={`filters ${style.filtersModal}`}
        >
          <div className="f4 b pa4 mb4">Filters</div>
          <div className="pa4 flex-column justify-center"></div>
        </Modal>
      ) : null}
    </>
  )
}

export default Filters
