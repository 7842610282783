import { percent } from 'utils/math'
import { putShortId } from 'utils/minIds'
import { intcmp } from 'utils/string'

export const defaultProgress = Object.freeze({
  total: 0,
  percent: 0,
  finished: 0,
  feedback: 0,
  returned: 0,
  submitted: 0,
  working: 0
})

export const ANSWER_STATUS = {
  WORKING: 0,
  SUBMITTED: 1,
  ACCEPTED: 2,
  RETURNED: 3
}

function normalizeRubric(
  { id, name, order, questionMaps, visibleId },
  milestonesOpen,
  actor
) {
  let questionMapsSort = {}

  const questionMapsD = questionMaps.reduce((qmapD, qmap) => {
    // defaultQuestionMap?
    qmap = { ...qmap }
    qmapD[qmap.id] = qmap

    if (actor) {
      let answerId = actor.answersQD[qmap.question.id]
      if (answerId) {
        qmap.answerId = answerId
      } else {
        qmap.answerId = undefined
      }
    }
    putShortId(qmapD, questionMapsSort, qmap)

    return qmapD
  }, {})

  const questionMapsL = questionMaps
    .toSorted((b, a) => intcmp(b.order, a.order))
    .map((a) => a.id)

  const questionMapShow = questionMapsL.filter((id) => {
    const qmap = questionMapsD[id]
    const thisId = qmap.visibleId || visibleId
    if (thisId && milestonesOpen[thisId]) {
      return true
    }
    return false
  })

  // calculate progress within this rubric
  let progress = { ...defaultProgress }
  if (actor) {
    progress = questionMapsL.reduce((progress, qMapId) => {
      progress.total++
      const qmap = questionMapsD[qMapId]
      const answer = actor.answersD[qmap.answerId]
      switch (answer?.statusA) {
        case ANSWER_STATUS.ACCEPTED:
          progress.finished++
          break
        case ANSWER_STATUS.RETURNED:
          progress.returned++
          break
        case ANSWER_STATUS.SUBMITTED:
          progress.submitted++
          break
        case ANSWER_STATUS.WORKING:
          progress.working++
          break
        default:
      }

      if (answer?.feedback) {
        progress.feedback++
      }
      return progress
    }, progress)

    progress.percent = percent(progress.finished / progress.total)
  }

  return Object.freeze({
    id,
    name,
    order,
    visibleId,
    questionMapsL,
    questionMapsD,
    questionMapShow,
    progress
  })
}

//////////////////////////////////////////////////////////////////////////////
export function normalizeRubrics(rubrics, milestonesOpen, actor) {
  let rubricsL = []
  let rubricsD = {}
  let rubricsShort = {}
  let rubricsShow = []

  if (rubrics !== undefined) {
    rubricsL = rubrics
      .toSorted((a, b) => intcmp(a.order, b.order))
      .map((r) => r.id)
    ;[rubricsD, rubricsShort] = rubrics.reduce(
      ([rubricsD, rubricsShort], rubric) => {
        putShortId(
          rubricsD,
          rubricsShort,
          normalizeRubric(rubric, milestonesOpen, actor)
        )
        return [rubricsD, rubricsShort]
      },
      [{}, {}]
    )

    rubricsShow = rubricsL.filter((id) => {
      const rubric = rubricsD[id]
      if (rubric.visibleId && milestonesOpen[rubric.visibleId]) {
        return true
      }
      return false
    })
  }
  return Object.freeze({ rubricsL, rubricsD, rubricsShow, rubricsShort })
}
