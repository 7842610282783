import React from 'react'

import {
  TAGS_CHANGE_MINE,
  TAGS_CHANGE_ORG,
  TAGS_CHANGE_PROJECT,
  TAGS_CHANGE_USER
} from 'tools/SelectTags/graphql'

import TagSelector from './TagSelector'

function SelectTags({
  title = '',
  mapType,
  tagType,
  selectTag,
  children = undefined,
  target,
  className = 'mt3',
  create = true,
  multi = undefined,
  ...args
}) {
  return (
    <>
      {title ? (
        <div className="mt4 mb2">
          <label className="f5 b db mb2 mt3 heading">{title}</label>
        </div>
      ) : null}
      <div className="">
        <i>{children}</i>
        <TagSelector
          className={className}
          mapType={mapType}
          tagType={tagType}
          multi={true}
          create={create}
          value={target.tagsD[mapType]}
          setValue={(v) => selectTag(v, mapType, tagType, multi)}
          {...args}
        />
      </div>
    </>
  )
}

////////////////////////////////////////////////////////////////////////////////
function tagUpdate(tagChgMutation, args, onUpdate) {
  let tags = args.value
  const { mapType, tagType, ref, refId } = args
  if (!tags) {
    tags = []
  } else if (tags.constructor !== Array) {
    tags = [tags]
  }
  tags = {
    tags: tags.map((tag) => {
      return { id: tag.id, label: tag.label }
    }),
    mapType,
    tagType,
    ref,
    refId
  }
  tagChgMutation({
    variables: { tags },
    update(cache, { data }) {
      onUpdate(args, data)
    }
  })
}

export function normalizeTags(state, tags = []) {
  const tagsD = tags.reduce((acc, tag) => {
    const current = acc[tag.tag.type] || []
    acc[tag.tag.type] = current.concat(tag.tag)
    return acc
  }, {})
  return { ...state, tagsD, tags }
}

export default SelectTags
export {
  TAGS_CHANGE_USER,
  TAGS_CHANGE_PROJECT,
  TAGS_CHANGE_MINE,
  TAGS_CHANGE_ORG,
  tagUpdate
}
