import { useContext, useMemo } from 'react'

import { GlobalContext } from 'reducer/global'

export const PALETTE = {
  dark: {
    primary: '#8e42f0',
    accent1: '#3CC5FE',
    accent2: '#DE354C',
    error: '#932432',
    warning: '#ffb700',
    okay: '#19a974',
    disabled: '#888',
    dark: '#444',
    inherit: 'inherit',
    green: '#19a974',
    green1: '#60A917',
    orange1: '#F0A209',
    red1: '#A20125',
    pool1: '#38BB44',
    pool2: '#26B8C1',
    pool3: '#9927C1',
    pool4: '#A62B2B',
    opposite: '#CCCCCC',
    peach: '#df8a95'
  },
  light: {
    primary: '#8e42f0',
    accent1: '#3CC5FE',
    accent2: '#DE354C',
    error: '#932432',
    dark: '#444',
    warning: '#ffb700',
    okay: '#19a974',
    disabled: '#eee',
    inherit: 'inherit',
    green: '#19a974',
    green1: '#60A917',
    orange1: '#F0A209',
    red1: '#A20125',
    pool1: '#38BB44',
    pool2: '#26B8C1',
    pool3: '#9927C1',
    pool4: '#A62B2B',
    opposite: '#333333',
    peach: '#df8a95'
  }
}

export function paletteMap(theme, keyMap) {
  const palette = PALETTE[theme]

  return Object.keys(keyMap).reduce((acc, key) => {
    acc[key] = palette[keyMap[key]]
    return acc
  }, {})
}

export function usePaletteSet(colorMap) {
  const [state] = useContext(GlobalContext)
  const theme = state.page.theme || state.user.settings.theme || 'dark'

  return useMemo(() => {
    const palette = PALETTE[theme]
    let colors = palette
    if (colorMap) colors = paletteMap(theme, colorMap)
    return { theme, colors, palette }
  }, [theme, colorMap])
}
