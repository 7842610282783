import React, { useCallback, useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import paths from 'constants/paths'

import Input from 'tools/Input'

import { UPSERT_PROJECT } from 'components/Project/graphql'
import GlobalContext from 'reducer/global'

import Welcome from './Welcome'
import { ApplyContext, R_APPLY } from './reducer'

function SelectType({ hasProjects, createState: [create, setCreate] }) {
  const titleState = useState('')

  return (
    <>
      <h2 className="heading mt4 mb3">Venture</h2>
      <div className="doc">
        {!hasProjects ? <Welcome /> : null}
        <div className="flex-items mt2">
          <div className="i">
            {hasProjects && !create ? (
              <>Choose one of your current ventures listed below, or:</>
            ) : (
              <>
                Create a new Venture
                {hasProjects ? ', or:' : ':'}
              </>
            )}
          </div>
          {hasProjects ? (
            <button className="medium ml3" onClick={() => setCreate(!create)}>
              {create ? 'Choose existing Venture' : 'Create a New Venture'}
            </button>
          ) : null}
        </div>
      </div>
      {create || !hasProjects ? (
        <Create
          className="mt3"
          placeholder="Venture Name"
          token="title"
          valState={titleState}
          auto={false}
        />
      ) : null}
    </>
  )
}

function Create({ token, valState, auto = true, ...args }) {
  const [{ user }] = useContext(GlobalContext)
  const history = useHistory()
  const [, dispatch] = useContext(ApplyContext)
  const { folioId: portfolioId, projectId } = useParams()
  const [mutation] = useMutation(UPSERT_PROJECT)
  const onSave = useCallback(
    (args, value, good, bad) => {
      let isMounted = true

      mutation({
        variables: { project: { portfolioId, [token]: value } },
        update(cache, { data }) {
          if (isMounted) {
            const res = data.upsertProject
            if (res.success && res.result) {
              dispatch({
                type: R_APPLY.LINK_PROJECT,
                value: res.result,
                user
              })
              if (res.result.shortId !== projectId) {
                history.replace(
                  `${paths.apply}/${portfolioId}/${res.result.shortId}`
                )
              }
              good && good()
            } else {
              bad && bad(res.reason)
            }
          }
        }
      })

      return () => {
        isMounted = false
      }
    },
    [portfolioId, mutation, user, history, token, dispatch, projectId]
  )

  return (
    <Input
      valState={valState}
      onSave={onSave}
      startDirty={!auto}
      dirtyText={auto ? undefined : 'Save'}
      {...args}
    />
  )
}

export default SelectType
