import React from 'react'
import { useHistory } from 'react-router-dom'

import ICON from 'constants/icons'
import paths from 'constants/paths'

import { capitalize, strcmp } from 'utils/string'

import PersonLabel from 'components/Person/tools//Label'

import { defaultEdit } from './Edit'

function sortMembers(a, b) {
  return strcmp(a.user.name || '', b.user.name || '')
}

export function Show({ editState, target, canChange, roles }) {
  return (
    <>
      <table className="list hover w-100">
        <tbody>
          {target.members.sort(sortMembers).map((mbr, x) => (
            <MemberList
              editState={editState}
              key={x}
              mbr={mbr}
              roles={roles}
              canChange={canChange}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}

function MemberList({ editState: [edit, setEdit], mbr, canChange, roles }) {
  const history = useHistory()
  return (
    <>
      <tr
        key={`member-role-${mbr.id}`}
        onClick={() => {
          history.push(`${paths.personPublic}/${mbr.user.handle || mbr.user.id}`)
        }}
      >
        <td>
          <PersonLabel
            person={mbr.user}
            icon={ICON.edit}
            showHandle={true}
            showLast={true}
            showDesc={false}
            showName={true}
            showPic={true}
            float={false}
            noLink={true}
          />
        </td>
        <td>{mbr.roles ? mbr.roles.join(', ') : ''}</td>
        <td className="pr2 i gray tr middle">{capitalize(mbr.type)}</td>
        {canChange ? (
          <td className="">
            <div className="flex-items">
              <div
                className="button plain clear"
                onClick={(ev) => {
                  ev.preventDefault()
                  ev.stopPropagation()
                  setEdit({ ...defaultEdit, member: mbr, user: mbr.user })
                }}
              >
                <i className={`${ICON.edit} pa1`} />
              </div>
            </div>
          </td>
        ) : null}
      </tr>
    </>
  )
}

export default Show
