import React, { useState } from 'react'

import { useQuery } from '@apollo/client'

import config from 'constants/config'
import paths from 'constants/paths'

import { Link } from 'tools/Links'
import { LoadingInline } from 'tools/Loader'
import { fromNowShort } from 'utils/time'
import { normalizePublicUser } from 'utils/user'

import { LIST_PUBLIC_PERSON } from 'components/Person/graphql'

import istyle from '../Picture/index.module.scss'
import lstyle from './index.module.scss'

export function LoadPersonLabel({ person, personId, ...args }) {
  const [data, setData] = useState(person)

  useQuery(LIST_PUBLIC_PERSON, {
    variables: { id: personId },
    skip: !!person,
    onCompleted(data) {
      if (data) {
        const { reason, result } = data.publicPerson
        if (reason) {
          setData({ _error: reason })
        } else {
          setData(normalizePublicUser(result))
        }
      }
    }
  })

  if (!data || data._error) {
    return (
      <div
        className={`${lstyle.person} theme-bg-hilite`}
        style={{ height: '2rem' }}
      >
        {data?._error ? (
          <div className="f7 red i">{data._error}</div>
        ) : (
          <LoadingInline babble={false} />
        )}
      </div>
    )
  }
  return <PersonLabel person={data} {...args} />
}

function PersonLabel({
  person,
  link = undefined,
  className = '',
  children = undefined,
  ...args
}) {
  person = normalizePublicUser(person)
  if (link) {
    return (
      <Link
        type="div"
        className="pointer hover-hilite br3 flex-items ph2 pv1 mr3"
        to={`${paths.personPublic}/${person.handle}`}
      >
        <PersonLabelInner person={person} {...args} noLink={true} />
        {children}
      </Link>
    )
  }
  return (
    <div
      className={`${lstyle.person} ${
        args.onClick ? 'pointer hover-hilite pr5' : ''
      } ${className}`}
      onClick={args.onClick}
    >
      <PersonLabelInner person={person} {...args} />
      {children}
    </div>
  )
}

function PersonLabelInner({
  person,
  onClick = undefined,
  noLink = false,
  icon = 'fas fa-plus',
  showHandle = true,
  showLast = false,
  showDesc = true,
  showPic = true,
  showName = true
}) {
  return (
    <>
      {showPic && <Avatar person={person} />}
      {showLast && <LastSeen person={person} />}
      {showName && <Name person={person} noLink={noLink} />}
      {showHandle && <Handle person={person} />}
      {showDesc && <Desc person={person} />}
      {onClick ? (
        <div
          className={`${lstyle.icon} ml-auto theme-fg-hilite flex-items nowrap`}
        >
          <i className="fas fa-plus mr2" /> <b>Invite</b>
        </div>
      ) : null}
    </>
  )
}

export function LastSeen({ person }) {
  return <div className={lstyle.time}>{fromNowShort(person.lastSeen)}</div>
}

export function Avatar({ person, className = '', round = true, size = 'small' }) {
  normalizePublicUser(person)
  let avatar = person.avatar.url
  if (!avatar) {
    if (person.avatar.path) {
      avatar = `${config.url.pub}${person.avatar.path}`
    } else {
      let loc = parseInt(person.id.slice(0, 8)) % 16
      loc = loc ? loc + 1 : 1
      avatar = `${config.url.pub}default/avatar${loc}.gif`
    }
  }
  return (
    <div
      className={`${lstyle.image} ${istyle.image} ${className} ${istyle[size]} ${
        avatar ? istyle.round : ''
      }`}
    >
      <img src={avatar} alt="" />
    </div>
  )
}
// <i className="fas fa-user gray f3" />

export function Handle({ person }) {
  return <span className={lstyle.handle}>u/{person.handle}</span>
}

export function Name({ person, noLink = false }) {
  return (
    <span className={`${lstyle.name} nowrap`}>
      {person.handle && !noLink ? (
        <Link to={`/u/${person.handle}`} className="plain">
          {person.name}
        </Link>
      ) : (
        person.name
      )}
    </span>
  )
}

export function Desc({ person, prefix = <b>&#xb7;&nbsp;</b> }) {
  const hilights = person.dataTypes.profile
  return (
    <span className={lstyle.desc}>
      {hilights ? (
        <>
          {prefix}
          {trim(hilights.value.desc)}
        </>
      ) : null}
    </span>
  )
}

function trim(s) {
  if (s && s.length > 45) {
    return s.slice(0, 40) + '...'
  }
  return s
}

export default PersonLabel
export { lstyle }
