import React, { useContext, useState } from 'react'

import { LoadingInline } from 'tools/Loader'

import GlobalContext from 'reducer/global'

import FileDialog from './FileDialog'
import style from './UploadDialog.module.scss'
import { FILE_TYPES } from './normalize'
import { uploadFile } from './upload'

function UploadDialog({
  refType,
  refId,
  types,
  className = '',
  onSuccess,
  buttonClass = 'button plain medium mr3 f7',
  buttonLabel = (
    <>
      <i className="fas fa-file mr2" />
      Add File
    </>
  )
}) {
  const [error, setError] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [{ apollo }] = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)

  // need a way to figure out content type
  const upload = (e, type) => {
    const files = [...e.target.files]
    const src = files[0]
    // reset so we can upload again
    e.target.value = ''
    const ftype = FILE_TYPES[type]
    if (!ftype.mime[src.type]) {
      setError(
        `Sorry, ${ftype.name} files can only be: ${Object.values(ftype.mime).join(
          ', '
        )}`
      )
      return
    }
    setLoading(true)
    setError(false)
    uploadFile({
      apollo,
      type,
      refType,
      refId,
      name: src.name,
      size: src.size,
      mimeType: src.type,
      src,
      onSuccess: (r) => {
        setOpen(false)
        setLoading(false)
        setError(undefined)
        onSuccess(r)
      },
      onFailure: (e) => {
        setLoading(false)
        setError(e)
      }
    })
  }
  if (types.length === 1) {
    const type = types[0]
    return (
      <>
        <div className={`${className} flex-items`}>
          <FileDialog
            key={type}
            idRef={`upload-${type}`}
            label={buttonLabel}
            onChange={(e) => upload(e, type)}
            className={buttonClass}
          />
        </div>
        {error ? <div className="mt3 red i">{error}</div> : null}
      </>
    )
  } else {
    buttonClass += ' mb3'
    return (
      <>
        <div className={`${className} ${style.files}`}>
          {open ? (
            types.map((type) => (
              <FileDialog
                key={type}
                idRef={`upload-${type}`}
                label={<TypeLabel type={type} />}
                onChange={(e) => upload(e, type)}
                className={buttonClass}
              />
            ))
          ) : (
            <button
              className={`${buttonClass} ${open ? 'disabled' : ''}`}
              onClick={() => setOpen(!open)}
            >
              {buttonLabel}
            </button>
          )}
        </div>
        {open ? (
          <div>
            {error ? (
              <span className="red i">{error}</span>
            ) : loading ? (
              <LoadingInline>Uploading...</LoadingInline>
            ) : (
              <>&nbsp;</>
            )}
          </div>
        ) : null}
      </>
    )
  }
}

function TypeLabel({ type, children = undefined }) {
  return (
    <>
      <i className={`${FILE_TYPES[type].icon} mr2`} />
      {children} {FILE_TYPES[type].name}
    </>
  )
}

export default UploadDialog
