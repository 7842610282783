import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'

import { capitalize, plural } from 'utils/string'
import { fromNow } from 'utils/time'

import { INVITE } from 'components/Person/graphql'
import PersonLabel from 'components/Person/tools//Label'

export function Invites({ target, onChange }) {
  if (target.invites && target.invites.length === 0) {
    return null
  }
  return (
    <div className="mb4">
      <label className="db heading mt4 mb2">
        {plural(target.invites.length, 'Request')}
      </label>
      {target.invites.map((r, key) => (
        <ApproveRequest request={r} key={key} onChange={onChange} />
      ))}
    </div>
  )
}

function ApproveRequest({ request, onChange }) {
  const [err, setErr] = useState(null)
  const [makeInvite] = useMutation(INVITE, {
    update(cache, { data: { invite: result } }) {
      if (result.success) {
        onChange(result)
      } else {
        setErr(result.reason)
      }
    }
  })
  const saveAccess = useCallback(
    (shortId, responseType, args = {}) => {
      makeInvite({
        variables: { shortId, action: 'RESPONSE', responseType, ...args }
      })
    },
    [makeInvite]
  )

  let ref = null
  switch (request.resourceType) {
    case 'file':
      ref = <>files</>
      break
    default:
      ref = <>details (including journey answers)</>
  }
  const isInvite = request.requestType === 'owner_ask'
  const requestAccess = request.requestAccess
  return (
    <div className="ba b--theme-faint br2 mv3">
      <div className="theme-bg-hilite f7 pa2 flex-items">
        <label>
          {isInvite ? 'Invitation' : 'Request'} ({capitalize(requestAccess)})
        </label>
        <div className="gray ml-auto i">Expires {fromNow(request.expiresAt)}</div>
      </div>
      <div className="pa3">
        <div className="flex flex-column">
          {request.target ? (
            <PersonLabel person={request.target} />
          ) : request.email ? (
            <Obfuscated email={request.email}>Email Invite</Obfuscated>
          ) : (
            'Unknown?'
          )}
          {isInvite ? null : (
            <div className="f7 mt3">
              <i className="fa fa-arrow-right mh3" />
              Requested access to {ref}
              {request.note ? ', with a note:' : null}
            </div>
          )}
        </div>
        {request.note ? (
          <div className="theme-bg-hilite pa3 br2 mt3">{request.note}</div>
        ) : null}
        <div className="mt3 flex-items">
          {isInvite ? null : (
            <button
              className="plain ml-auto f6 pv1"
              onClick={() =>
                saveAccess(request.shortId, 'approve', {
                  responseAccess: requestAccess
                })
              }
            >
              Accept
            </button>
          )}
          <button
            className="plain ml-auto f6 pv1"
            onClick={() => saveAccess(request.shortId, 'deny')}
          >
            {isInvite ? 'Cancel' : 'Deny'}
          </button>
        </div>
        {err ? <div className="error">{err}</div> : null}
      </div>
    </div>
  )
}

function Obfuscated({ email, children }) {
  const { d, t, u } = email
  return (
    <div className="flex-items">
      <div className="gray mr2">{children}</div>
      <code className="flex-items">
        &lt;
        <FormatObfuscated part={u} />@
        <FormatObfuscated part={d} />.{t}
        &gt;
      </code>
    </div>
  )
}
function FormatObfuscated({ part: [begin, len, end] }) {
  len /= 2
  return (
    <>
      {begin}
      {len > 0 ? (
        <div style={{ minWidth: `${len}rem` }} className="theme-bg-hilite">
          &nbsp;
        </div>
      ) : null}
      {end}
    </>
  )
}

export default Invites
