import { useCallback } from 'react'

export function useSaveUrl(onSave) {
  return useCallback(
    (props, value, good, bad) => {
      const { url, error } = parseUrl(value)
      if (error) {
        bad(error)
      } else {
        onSave(props, url, good, bad)
      }
    },
    [onSave]
  )
}

export function parseUrl(str) {
  if (!str) {
    return { url: '' }
  }
  if (!str.match(/^(https?):\//)) {
    str = 'https://' + str
  }
  try {
    return { url: new URL(str).toString() }
  } catch (err) {
    return { error: 'Invalid URL' }
  }
}
