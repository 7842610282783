import React, { useContext, useEffect, useState } from 'react'

import Markdown from 'tools/Markdown'
import Modal from 'tools/Modal'
import { PivotIcon } from 'tools/Uniform'

import { SparksIcon } from 'components/Journey/Icons'
import { GlobalContext } from 'reducer/global'
import { useExpandQuery2 } from 'reducer/global/hooks'

import { ProjectContext } from '../reducer'

function Challenges({ journeyMapId }) {
  const [{ challengesL }] = useContext(ProjectContext)

  return (
    <div className="flex-center mt3 w-100">
      <table className="list altrows w-100">
        <tbody>
          <tr>
            <td colSpan={2} className="w-100 b f6 pl0 pb2">
              Challenges
            </td>
            <td className="label b f6 pb2">Achievements</td>
            <td className="label b f6 pb2">Sparks</td>
          </tr>
          {challengesL.map((id) => {
            return (
              <Challenge
                key={`${id}:${journeyMapId}`}
                journeyMapId={journeyMapId}
                challengeId={id}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function Challenge({ journeyMapId, challengeId }) {
  const [state] = useContext(GlobalContext)
  const [{ challengesD, milestonesD }] = useContext(ProjectContext)
  const challenge = challengesD[challengeId] || {}

  const { expand, pivot } = useExpandQuery2(state, 'c')

  // challenge.jmapId comes from milestonesD[challenge.milestoneId].jmapId
  if (journeyMapId !== milestonesD[challenge.milestoneId]?.jmapId) return null

  const { linksL } = challenge
  const isExpanded = expand.includes(challengeId)
  const hasLinks = linksL?.length > 0
  const xdone = linksL.reduce((tot, id) => {
    const l = challengesD[id]
    if (l?.completed) return tot + 1
    return tot
  }, 0)

  let {
    conditions: { required }
  } = challenge
  if (required === 'all') {
    required = linksL.length
  }

  // one level deep for now
  return (
    <>
      <tr>
        <td
          className={
            hasLinks ? `${isExpanded ? 'br b--gray' : ''} hover pointer` : ''
          }
          onClick={
            hasLinks
              ? (ev) => {
                  ev.preventDefault()
                  ev.stopPropagation()
                  pivot(challengeId)
                }
              : undefined
          }
        >
          {hasLinks ? <PivotIcon open={isExpanded} /> : null}
        </td>
        <ChallengeRow
          challenge={challenge}
          count={
            hasLinks ? (
              <span className="ml2">
                ({xdone}/{required})
              </span>
            ) : null
          }
        />
      </tr>
      {isExpanded
        ? linksL.map((cId, x) => {
            const e = challengesD[cId]
            if (!e) return null
            return (
              <tr key={cId}>
                <td className="br b--gray"></td>
                <ChallengeRow challenge={e} />
              </tr>
            )
          })
        : null}
    </>
  )
}

function ChallengeRow({
  challenge: { name, actions, context, completed, explain, id, triggers },
  count = null
}) {
  const [awards, setAwards] = useState(undefined)
  const [showHelp, setShowHelp] = useState(false)

  useEffect(() => {
    if (awards === undefined) {
      setAwards(normalizeAwards(actions, context))
    }
  }, [actions, context, setAwards, awards])

  const active = triggers.length > 0 ? '' : 'theme-fg-minimize'
  return (
    <>
      <td className={`${active} v-top w-100`}>
        {completed ? <i className="fas fa-check mr2" /> : null}
        <Markdown components={{ p: React.Fragment }}>{name}</Markdown>
        {count}
        {explain ? (
          <i
            className="fas fa-question-circle ml2 hover-primary pointer"
            onClick={(ev) => {
              ev.preventDefault()
              ev.stopPropagation()
              setShowHelp(true)
            }}
          />
        ) : null}
      </td>
      {awards ? (
        <>
          <Award
            Cell={Achievements}
            awards={awards.achievements}
            className={active}
          />
          <Award Cell={Sparks} awards={awards.sparks} className={active} />
        </>
      ) : null}
      {showHelp ? (
        <Modal
          viewState={[showHelp, setShowHelp]}
          width="fw-shrink"
          className="pa4"
        >
          <div className="b primary f4 mb3">
            <Markdown components={{ p: React.Fragment }}>{name}</Markdown>
          </div>
          <div>
            <Markdown>{explain}</Markdown>
          </div>
        </Modal>
      ) : null}
    </>
  )
}

function Award({ Cell, awards, className }) {
  return (
    <td className={`${className} v-top`}>
      {awards?.project ? <Cell val={awards.project} /> : null}
      {awards?.user ? (
        <Cell val={awards.user}>
          <i className="fas fa-user f7" />
        </Cell>
      ) : null}
    </td>
  )
}

function Sparks({ val, children = undefined, className = '' }) {
  return (
    <div className={`${className} nowrap`}>
      <SparksIcon className="gray" style={{ marginRight: '0.1rem' }} />
      {val} <span className="gray">{children}</span>
    </div>
  )
}

function Achievements({ val, children = undefined }) {
  return (
    <div className="nowrap">
      <b>{val}</b> <span className="gray">{children}</span>
    </div>
  )
}

function noKeyUndef(obj) {
  if (Object.keys(obj).length === 0) {
    return undefined
  }
  return obj
}

function normalizeAwards(actions, context) {
  return {
    sparks: noKeyUndef(
      actions
        .filter((a) => a.sparks)
        .reduce((x, a) => normalizeSpark(x, a, context), {})
    ),
    achievements: noKeyUndef(
      actions
        .filter((a) => a.achievement)
        .reduce((x, a) => normalizeAchievement(x, a, context), {})
    )
  }
}

function normalizeSpark(accum, award, context) {
  if (award?.sparks?.user || award?.sparks?.project) {
    const sparks = award.sparks
    if (sparks?.user) {
      accum = { ...accum, user: (accum?.user || 0) + sparks.user }
    }
    if (sparks?.project) {
      accum = { ...accum, project: (accum?.project || 0) + sparks.project }
    }
  } else {
    if (context.hasOwnProperty('user_id')) {
      accum = { ...accum, user: (accum?.user || 0) + award.sparks }
    }
    if (context.hasOwnProperty('project_id')) {
      accum = { ...accum, project: (accum?.user || 0) + award.sparks }
    }
  }
  return accum
}

function normalizeAchievement(accum, award, context) {
  if (award?.achievement?.user || award?.achievement?.project) {
    accum = { ...accum, ...award.achievement }
  } else {
    if (context.hasOwnProperty('user_id')) {
      accum = { ...accum, user: award.achievement }
    }
    if (context.hasOwnProperty('project_id')) {
      accum = { ...accum, project: award.achievement }
    }
  }
  return accum
}

export default Challenges
