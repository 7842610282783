import { percent } from 'utils/math'

import { normalizeActor } from './actor'
import { defaultJourney, normalizeJourney } from './journey'
import { defaultProgress } from './rubric'

// Journey Actor Map (JAMap)
export const defaultJourneyActor = Object.freeze({
  id: '',
  shortId: '',
  milestone: {},
  milestoneId: '',
  progress: defaultProgress,
  journey: defaultJourney,
  actor: {},
  _normal: false
})

////////////////////////////////////////////////////////////////////////////////
export function normalizeJourneyActor({
  journey,
  actor,
  milestoneId,
  id,
  shortId,
  updatedAt
}) {
  actor = normalizeActor(actor || {})
  journey = normalizeJourney(journey, actor)
  let milestone = undefined
  if (milestoneId) {
    milestone = journey.milestonesD[milestoneId]
  }
  let milestonesOpen = {}
  for (let x = 0; x !== journey.milestonesL.length; x++) {
    const curId = journey.milestonesL[x]
    milestonesOpen[curId] = true
    if (milestoneId === curId) break
  }

  const { progress, q2r } = summarizeJourneyProgress(journey)

  return Object.freeze({
    id,
    shortId,
    progress,
    q2r,
    journey,
    actor,
    milestonesOpen,
    milestone,
    milestoneId,
    updatedAt,
    _normal: true
  })
}

////////////////////////////////////////////////////////////////////////////////
// input: Journey with enriched rubricsL/D
// output: {progress, q2r} // q2r=question2rubric
export function summarizeJourneyProgress({ rubricsL, rubricsD }) {
  const [progress, q2r] = rubricsL.reduce(
    ([progress, q2r], rId) => {
      const r = rubricsD[rId]
      if (r.progress) {
        progress.total += r.progress.total
        progress.finished += r.progress.finished
        progress.feedback += r.progress.feedback
        progress.returned += r.progress.returned
        progress.submitted += r.progress.submitted
        progress.working += r.progress.working
      }

      q2r = r.questionMapsL.reduce((q2r, qmId) => {
        const qm = r.questionMapsD[qmId]
        q2r[qm.question.id] = [r.shortId, qm.shortId]
        return q2r
      }, q2r)
      return [progress, q2r]
    },
    [{ ...defaultProgress }, {}]
  )
  progress.percent = percent(progress.finished / progress.total)
  return { progress, q2r }
}
