import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'

import { Input, Textarea } from 'tools/Input'

import ProjectContext, { R_PROJECT } from '../reducer'

////////////////////////////////////////////////////////////////////////////////

function IfEdit({
  token,
  heading = 'heading mt4 mb2',
  type = 'line',
  onChange: inOnChange = undefined,
  onSave: inOnSave = undefined,
  decorate = true,
  value = undefined,
  display = undefined,
  ...rest
}) {
  const [project, dispatch] = useContext(ProjectContext)
  value = value || project[token] || ''
  const onSave = inOnSave ? inOnSave : project.onSave
  const onChange = inOnChange
    ? (v) => inOnChange(token, v, rest.component)
    : (v) => dispatch({ type: R_PROJECT.MERGE, value: { [token]: v } })
  const args = {
    ...rest,
    token,
    valState: [value, onChange],
    onSave
  }

  return (
    <>
      {type === 'line' ? (
        <Input {...args} />
      ) : type === 'number' ? (
        <NumberFormat {...args} />
      ) : (
        <Textarea {...args} />
      )}
    </>
  )
}

export default IfEdit
