import React, { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import paths from 'constants/paths'

import Input from 'tools/Input'
import { ErrorBox, Loading } from 'tools/Loader'
import { strcmp } from 'utils/string'

import { OrgContext, R_ORG } from 'components/Org/reducer'

import { PORTFOLIOS } from '../graphql'
import { R_STUDIO, StudioContext } from '../reducer'

function List() {
  const [org, dispatch] = useContext(OrgContext)
  const history = useHistory()
  const path = `${paths.studio}/${org.shortId}`

  const closeTab = useCallback(
    (tabId) => {
      dispatch({ type: R_ORG.HIDE_TAB, value: tabId })
      history.push(`${path}/`)
    },
    [dispatch, history, path]
  )

  const openTab = useCallback(
    (folio) => {
      history.push(`${path}/s/${folio.shortId}`)
    },
    [history, path]
  )

  const { data, loading, error } = useQuery(PORTFOLIOS, {
    variables: { orgId: org.id },
    skip: !org.id,
    fetchPolicy: 'cache-and-network'
  })

  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <i>
        Portfolios are how you organize your studio. Reporting and Cohorts are
        organized at the portfolio level.
      </i>
      <div className="mt3 w-100">
        {error ? (
          <ErrorBox>{error}</ErrorBox>
        ) : loading || data === undefined ? (
          <Loading />
        ) : (
          data.portfolios
            .toSorted((a, b) => strcmp(a.name, b.name))
            .map((f) => (
              <ListPortfolio
                folio={f}
                key={f.id}
                openTab={openTab}
                closeTab={closeTab}
              />
            ))
        )}
      </div>
      <AddPortfolio className="mt4" />
    </div>
  )
}

function ListPortfolio({ folio, openTab, closeTab }) {
  let statusColor
  switch (folio.status) {
    case 'preparing':
      statusColor = 'blue'
      break
    case 'accepting':
      statusColor = 'green'
      break
    default:
      statusColor = 'gray'
  }
  return (
    <div
      className="flex button plain large mt3 w-100 items-center"
      onClick={() => openTab(folio)}
    >
      <div>{folio.name}</div>
      <div className="ml-auto normal">
        <code>/a/{folio.shortId}</code>
      </div>
      <div className={`i ml3 mr2 ${statusColor}`}>{folio.status}</div>
      <div className="ml3 normal">({folio.summary.projects})</div>
    </div>
  )
}

function AddPortfolio({ className }) {
  const [create, setCreate] = useState(false)
  const [folio, dispatch] = useContext(StudioContext)

  return (
    <div className={className}>
      {create ? (
        <Input
          token="name"
          component="portfolio"
          valState={[
            folio.name,
            (value) => dispatch({ type: R_STUDIO.CHANGE, token: 'name', value })
          ]}
          onSave={(...args) => {
            folio.onSave(...args)
          }}
          placeholder="Portfolio Name"
        />
      ) : (
        <div
          className="button clear large"
          onClick={() => {
            dispatch({ type: R_STUDIO.RESET })
            setCreate(true)
          }}
        >
          <i className="fas fa-plus f4 mr2" /> Add Portfolio
        </div>
      )}
    </div>
  )
}

export default List
