import React, { useContext, useState } from 'react'

import paths from 'constants/paths'

import { BrowserContext, R_BROWSER } from 'tools/Browser'
import Chooser from 'tools/Chooser'
import { Link } from 'tools/Links'
import Modal from 'tools/Modal'

import { ROLES } from 'components/Project/defs'

import style from './Filters.module.scss'

// const goalOptions = [
//   { value: 'gt1000', label: '> $1mm' },
//   { value: 'r500', label: '$500k .. $1mm' },
//   { value: 'r250', label: '$250k .. $500k' },
//   { value: 'r100', label: '$100k .. $250k' },
//   { value: 'lt100', label: '< $100k' },
//   { value: 'none', label: 'With no goal' },
//   { value: 'any', label: 'With any goal' }
// ]

const fileOptions = [
  { label: 'Lightning Pitch', value: 'lightning' },
  { label: 'Pitch Deck', value: 'pitchPdf' },
  { label: 'Pitch Video', value: 'pitchVideo' }
]

const rangeOptions = [
  { label: 'Active today', value: 'today' },
  { label: 'Active this week', value: 'week' },
  { label: 'Active this month', value: 'month' },
  { label: 'Active this quarter', value: 'quarter' }
]

// const stageOptions = STAGES.map((s) => {
//   if (s.value === 'ember') {
//     s['default'] = true
//   }
//   return s
// })
//           <Chooser
//             {...chooseArgs}
//             type="stages"
//             options={stageOptions}
//             multi="Stages"
//           />
// <Chooser {...chooseArgs} type="goal" options={goalOptions} />

function Filters() {
  const [edit, setEdit] = useState(false)
  const [{ filter }, dispatch] = useContext(BrowserContext)
  const setPref = (key, value) =>
    dispatch({ type: R_BROWSER.SET_FILTER, key, value })

  const chooseArgs = {
    prefs: filter,
    setPref,
    className: 'plain primary clear mr3'
  }

  return (
    <>
      <div className={`${style.filtersMobile} db dn-ns tc`}>
        <div>
          <button className="navlink" onClick={() => setEdit(true)}>
            Filters <i className="fas fa-chevron-down" />
          </button>
        </div>
      </div>
      <div className="dn db-ns">
        <div className={`navbar filters ${style.filters} flex justify-center`}>
          <input
            value={filter.name || ''}
            onChange={(e) => setPref('name', e.target.value)}
            placeholder="Search venture names..."
            className="mr3"
            style={{ width: '15rem' }}
          />
          <Chooser
            {...chooseArgs}
            type="needs"
            options={ROLES}
            multi="Need Co-Founder"
          />
          <Chooser
            {...chooseArgs}
            type="files"
            options={fileOptions}
            multi="Pitches"
          />
          <Chooser
            {...chooseArgs}
            type="activity"
            options={rangeOptions}
            defaultLabel="Last Active"
          />
          <Link
            to={`${paths.project}/${paths.noId}/create`}
            className="button plain primary ph2 pv1"
          >
            Create
          </Link>
        </div>
      </div>
      {edit ? (
        <Modal
          viewState={[edit, setEdit]}
          width="full"
          className={`filters ${style.filtersModal}`}
        >
          <div className="f4 b pa4 mb4">Filters</div>
          <div className="pa4 flex-column justify-center"></div>
        </Modal>
      ) : null}
    </>
  )
}

export default Filters
