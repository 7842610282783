import React, { useContext } from 'react'

import { Input } from 'tools/Input'
import RadioButtons, { useSaveRadio } from 'tools/RadioButtons'
import { capitalize } from 'utils/string'

import { R_STUDIO, StudioContext } from '../reducer'

const radioOpts = [
  { value: 'preparing', class: 'blue', msg: 'Does not accept applications' },
  {
    value: 'private',
    class: 'green',
    msg: 'Applications accepted by direct link only'
  },
  { value: 'accepting', class: 'green', msg: 'Publicly listed' },
  { value: 'closed', class: 'gray', msg: 'Portfolio is closed' }
]

const opts = radioOpts.reduce((acc, opt) => {
  acc[opt.value] = opt
  return acc
}, {})

function Summary({ id }) {
  // const [config, setConfig] = useState(false)
  const [folio, dispatch] = useContext(StudioContext)
  const saveRadio = useSaveRadio(folio.onSave)

  // const closeTab = useCallback(
  //   (tabId) => {
  //     oDispatch({ type: R_ORG.CLOSE_TAB, value: tabId })
  //     history.push(`${paths.org}/${org.shortId}/edit/folios`)
  //   },
  //   [history, oDispatch, org.shortId]
  // )

  // useQuery(LOAD_PORTFOLIO, {
  //   variables: { orgId: org.id, portfolioId: id },
  //   skip: !id,
  //   fetchPolicy: 'cache-and-network',
  //   onCompleted(result) {
  //     if (result) {
  //       const folio = result.portfolios[0]
  //       if (folio) {
  //         oDispatch({
  //           type: R_ORG.OPEN_TAB,
  //           value: {
  //             id: 'f',
  //             name: folio.name,
  //             close: closeTab,
  //             more: folio.shortId
  //           }
  //         })
  //         dispatch({ type: R_STUDIO.SET, value: folio })
  //       }
  //     }
  //   }
  // })

  const status = opts[folio.status] || {}
  const args = {
    cmeta: { id: folio.id },
    meta: { orgId: folio.orgId },
    component: 'portfolio'
  }
  return (
    <>
      <div className="theme-frame-tabbed max-view-page pa4 mb6">
        <div className="flex-items ml-auto">
          <RadioButtons
            className="b f7"
            buttonClass=""
            value={folio.status}
            opts={radioOpts}
            onSave={saveRadio}
            token="status"
            {...args}
          />
          <div className={`ml4 i f6 b ${status.class}`}>{status.msg}</div>
        </div>
        <label className="mt4 mb3">Name</label>
        <Input
          className="w-100"
          token="name"
          valState={[
            folio.name,
            (value) => dispatch({ type: R_STUDIO.CHANGE, token: 'name', value })
          ]}
          onSave={folio.onSave}
          {...args}
        />
        <div className="mt4 flex justify-between items-center">
          <label>Applications ({folio.membersL.length})</label>
        </div>
        <div className="pv3 mt2 flex-items">
          {folio.memberStatus
            ? Object.keys(folio.memberStatus)
                .toSorted()
                .map((k) => (
                  <div key={k} className="mr3">
                    {capitalize(k)} ({folio.memberStatus[k].length})
                  </div>
                ))
            : null}
        </div>
        <label className="mt3 mb3"> Eligibility</label>
        <i>
          When an applicant reaches this milestone, you can receive a notification
          and they will be visible in your workflow:
        </i>

        <div
          className="dib mt3 br2 white pa2 b"
          style={{ backgroundColor: 'rgb(222, 53, 76)' }}
        >
          Ember
        </div>
      </div>
    </>
  )
}

// function ListCohort({ cid }) {
//   const [folio] = useContext(StudioContext)
//
//   const cohort = folio.cohortsD[cid]
//   // more to come here
//   return <li className="">{cohort.name}</li>
// }

export default Summary
