import React from 'react'

import style from './Ribbon.module.scss'

export function MilestoneRibbons({
  milestones = [],
  rotate = true,
  classNames = ''
}) {
  return (
    <div
      className={`${style.milestones} ${
        rotate ? style.rotate : ''
      } ${classNames}`}
    >
      {milestones.map((m) => (
        <MilestoneRibbon milestone={m} key={m.id} />
      ))}
    </div>
  )
}

// TODO: Have normalizer make the style a static object instead of dynamic
// forcing updates all the time
export function MilestoneRibbon({
  milestone: {
    name,
    meta: {
      style: { primary, text }
    }
  },
  children = null
}) {
  return (
    <div
      className={style.milestone}
      style={{ backgroundColor: primary, color: text }}
    >
      {name}
    </div>
  )
}
