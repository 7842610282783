// export const defaultActor = Object.freeze({
//   id: '',
//   refId: '',
//   type: 'invalid',
//   project: undefined,
//   person: undefined,
//   answersD: {},
//   answersQD: {}
// })
import { put } from 'utils/dict'

import { normalizeAnswer } from './answer'

export function normalizeActor({
  id,
  refId,
  type = 'invalid',
  project,
  person,
  answers
}) {
  let answersD = {}
  let answersQD = {}
  if (answers) {
    // by answer ID
    answersD = answers.reduce((d, a) => put(d, a.id, normalizeAnswer(a)), {})
    // by question ID
    answersQD = answers.reduce((d, a) => put(d, a.questionId, a.id), {})
  }
  return Object.freeze({ id, refId, type, project, person, answersD, answersQD })
}
