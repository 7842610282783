import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import paths from 'constants/paths'

import Link from 'tools/Links'
import { fromNowShort } from 'utils/time'

import ProjectContext from '../reducer'

function Applications() {
  const [project] = useContext(ProjectContext)
  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <label className="mb3 heading f4">
        <i className="fas fa-handshake mr2" />
        Partner Applications
      </label>

      {project.portfolioMembers?.map((mbr) => (
        <Application pm={mbr} key={mbr.id} pId={project.shortId} />
      ))}
      <div className="mt3">
        <Link className="" to={paths.org}>
          Search for Investors open to applications.
        </Link>
      </div>
    </div>
  )
}

function Application({ pm, pId }) {
  const history = useHistory()
  const folio = pm.portfolio
  const org = folio.org
  const app = folio.application
  return (
    <div
      className="flex-items pointer hover-hilite pv2 ph3 br2"
      onClick={() => history.push(`${paths.apply}/${folio.shortId}/${pId}`)}
    >
      <div className="ba br2 pv1 ph2 f7 mr2">
        {pm.status} - {fromNowShort(pm.updatedAt)} ago
      </div>
      <div className="mr2">{org.name}</div>
      <div className="mr2">{app?.showFolio ? <>&mdash; {folio.name}</> : ''}</div>
      <div className="f7 ml-auto mr2">{fromNowShort(pm.insertedAt)}</div>
      <div className="f7"></div>
    </div>
  )
}
export default Applications
