import React, { useCallback } from 'react'

import { capitalize } from 'utils/string'

import style from './index.module.scss'

export function useSaveRadio(onSave) {
  return useCallback(
    (args, selected) => {
      const { orig, type, boolTrue } = args
      let value
      switch (type) {
        case 'multiple':
          if (orig.includes(selected)) value = orig.filter((i) => i !== selected)
          else value = orig.concat(selected)
          break

        case 'boolean':
          value = selected === boolTrue
          break

        default:
          value = selected
      }

      onSave({ ...args }, value)
    },
    [onSave]
  )
}

function RadioButtons(props) {
  const { opts, variant = 'buttons', className } = props
  const columnar = variant === 'column'
  return (
    <div
      className={`${
        variant === 'buttons' ? 'flex flex-wrap items-center' : style.cols
      } ${className}`}
    >
      {opts.map((opt, x) => (
        <MapOption
          key={opt?.value || opt}
          opt={opt}
          columnar={columnar}
          {...props}
        />
      ))}
    </div>
  )
}

function MapOption({
  opt,
  onSave,
  capitalize: doCaps = true,
  token = undefined,
  type = 'one',
  boolTrue = undefined,
  className = '',
  buttonClass = 'mb2',
  opts,
  columnar,
  value,
  ...args
}) {
  // let bvalue = columnar ? but.value : but
  let bvalue = opt?.value || opt
  let blabel = opt?.label || bvalue
  let bclass = opt?.class || ''

  let isActive = false
  switch (type) {
    case 'boolean':
      isActive = (value && boolTrue === bvalue) || (!value && boolTrue !== bvalue)
      break
    case 'multiple':
      isActive = (value || []).includes(bvalue)
      break
    default:
      isActive = value === bvalue
      break
  }
  return (
    <div
      className="flex items-start"
      onClick={() =>
        onSave({ ...args, orig: value, opts, boolTrue, token, type }, bvalue)
      }
    >
      <button
        className={`${style.rb} nowrap ${
          isActive ? `${style.active} ${bclass}` : 'plain border'
        } ${buttonClass}`}
      >
        {doCaps ? capitalize(blabel) : blabel}
      </button>
      {columnar ? <i className="fw2 pt1"> &mdash; {opt.text}</i> : null}
    </div>
  )
}
export default RadioButtons
