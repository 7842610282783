import React from 'react'

import paths from 'constants/paths'

import { Link } from 'tools/Links'
import { fromNowShort } from 'utils/time'

import style from './index.module.scss'

function Tile({ org }) {
  const age = fromNowShort(org.updatedAt)
  return (
    <Link
      type="div"
      to={`${paths.org}/${org.shortId}`}
      className={`${style.tile} hover-outline-primary theme-frame pointer theme-bg-flat mv4 mh4`}
    >
      <TileBody org={org} age={age} />
    </Link>
  )
}

function TileBody({ org, age }) {
  return (
    <div className="pa3 h-100">
      <div className="flex justify-start">
        <div className={style.pic}>
          <img src={org.avatar && org.avatar.url} alt="" />
        </div>
        <div className="ml3 flex flex-column justify-between">
          <div className="f4 b">{org.name}</div>
          <StatusIcons className="pv3" org={org} age={age} />
          <div className="f6">{org.tags.map((t) => t.tag.label).join(', ')}</div>
        </div>
      </div>
    </div>
  )
}

function StatusIcons({ className = '', org, age }) {
  // <div className="mr3">
  //   <i className={`${ICON.chat} mr1`} />
  //   {org.summary.comments}
  // </div>
  return (
    <div className={`flex-items ${className} f7 gray`}>
      <div className="mr3 flex">
        <i className="fas fa-clock mr2" />
        {age}
      </div>
      <div className="">
        <i className="fas fa-users mr2" />
        {org.members.length}
      </div>
    </div>
  )
}

export default Tile
