import React, { createContext, useReducer } from 'react'

import { normalizeFiles } from 'tools/Files/normalize'
import { removeItem, upsertItem } from 'utils/array'

import {
  defaultApply,
  normalizeAnswer,
  normalizeAnswers,
  normalizeApply,
  normalizeLinkProject,
  normalizeMember
} from './normalize'

export const R_APPLY = {
  SET_ORG: 1,
  LINK_PROJECT: 2,
  SET_ANSWERS: 3,
  MERGE: 4,
  CHANGE_ANSWER: 5,
  UPSERT_ANSWER: 6,
  UPSERT_FILE: 7,
  DELETE_FILE: 8,
  RESET: 9,
  UPSERT_MEMBER: 10
}

////////////////////////////////////////////////////////////////////////////////
// micro reducer, maybe eventually switch to that
export function reducer(state, { type, value, ...args }) {
  switch (type) {
    case R_APPLY.SET_ORG:
      return normalizeApply(state, value)

    case R_APPLY.RESET:
      return { ...state, project: undefined, answers: [], answersD: {} }

    case R_APPLY.LINK_PROJECT:
      return normalizeLinkProject(state, value)

    case R_APPLY.SET_ANSWERS:
      return normalizeAnswers(state, value)

    case R_APPLY.CHANGE_ANSWER:
      return changeAnswer(state, args.fieldId, { [args.token]: value })

    case R_APPLY.UPSERT_ANSWER:
      return saveAnswer(state, args)

    case R_APPLY.MERGE:
      return { ...state, ...value }

    case R_APPLY.UPSERT_FILE:
      return changeAnswerFile(state, args.field.id, upsertItem, value)

    case R_APPLY.DELETE_FILE:
      return changeAnswerFile(state, args.field.id, removeItem, value)

    case R_APPLY.UPSERT_MEMBER:
      return normalizeMember({ ...state }, value)

    default:
      throw new Error(`apply - no such action.type: ${type}!`)
  }
}

function saveAnswer(state, args) {
  const { result, status, progress } = args.result
  return {
    ...state,
    status,
    progress,
    answersD: {
      ...state.answersD,
      [result.fieldId]: normalizeAnswer(args.field, result)
    }
  }
}

function changeAnswer(state, id, value) {
  const answer = { ...state.answersD[id], ...value }
  return { ...state, answersD: { ...state.answersD, [id]: answer } }
}

function changeAnswerFile(state, id, changer, value) {
  const files = state.answersD[id].files
  return changeAnswer(state, id, normalizeFiles(changer(files, value)))
}

export const ApplyContext = createContext(null)
export const ApplyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultApply)
  return (
    <ApplyContext.Provider value={[state, dispatch]}>
      {children}
    </ApplyContext.Provider>
  )
}

export default ApplyContext
