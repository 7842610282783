import React, { useContext } from 'react'

import paths from 'constants/paths'

import { ExtLink, Link } from 'tools/Links'
import { Label } from 'tools/Uniform'
import { capitalize, interpolate } from 'utils/string'

import { FormatProgress } from 'components/Journey/Rubric'

import FieldValue from './FieldValue'
import { ApplyContext } from './reducer'

function EditForm() {
  const [app] = useContext(ApplyContext)

  if (!app.project) {
    return null
  }

  const { progress } = app
  return (
    <>
      <h2 className="tc heading mb3">
        Application {app.status ? <>({capitalize(app.status)})</> : null}
      </h2>
      <div className="tc mb4 i">
        <FormatProgress
          progress={{
            percent: progress.percent,
            finished: progress.good,
            total: progress.required || progress.total
          }}
        />
      </div>
      <div className="f6 i mt3 mb3">
        Your changes are automatically saved as you work through this form.
      </div>
      {app.fieldsL.map((id) => (
        <React.Fragment key={id}>
          <FieldHead field={app.fieldsD[id]} app={app} />
          <FieldValue field={app.fieldsD[id]} app={app} />
        </React.Fragment>
      ))}
      <label className="primary f4 mt5 flex-items">What Next?</label>
      <div className="mt3 mb3 doc pb1">
        <Label className="f5 mt4 mb3">There is a lot more to Venturly!</Label>
        <div className="f6 i mb4">Stand out from the crowd:</div>
        <ol className="bullet-circles ml2">
          <li>
            <p className="ma0">
              <Link to={`${paths.project}/${app.project.shortId}/edit`}>
                <b>Demonstrate your investability</b>
              </Link>{' '}
              by achieving the next milestone in your journey (review the
              challenges for a guide on what to do next)
            </p>
          </li>
          <li>
            <p className="ma0">
              <Link to={paths.preferences}>
                <b>Update your personal profile</b>
              </Link>
              , perhaps adding a photo of yourself.
            </p>
          </li>
          <li>
            <p className="ma0">
              <Link to={`${paths.project}/${app.project.shortId}/profile`}>
                <b>Complete your venture profile.</b>
              </Link>
            </p>
          </li>
          <li>
            <p className="ma0">
              <Link to={`${paths.project}/${app.project.shortId}/goals`}>
                <b>Update your venture goals</b>
              </Link>
              .
            </p>
          </li>
          <li>
            <p className="ma0">
              <Link to={`${paths.docs}/support`}>
                <b>Visit our community chat server</b>
              </Link>{' '}
              for support, or even to help others in same journey as you.
            </p>
          </li>
        </ol>
      </div>
      <div className="flex mv4">
        <Link to={paths.preferences} className="button large mr3 plain">
          My Personal Profile
        </Link>
        <Link
          to={`${paths.project}/${app.project.shortId}/edit`}
          className="button large plain"
        >
          {app.project.title}'s Profile
        </Link>
      </div>
    </>
  )
}

function ContactToS({ app, field }) {
  return (
    <div className="mt2 f6">
      {field.meta.tos ? (
        <>Venturly commits to protect your data within our systems. </>
      ) : null}
      At your request we will provide your name and email to {app.org.name}.{' '}
      {field.meta.tos ? (
        <>
          This copy is managed under their{' '}
          <ExtLink to={field.meta.tos} className="i">
            Privacy Policy
          </ExtLink>
          .{' '}
          {field.meta.unsub ? (
            <>
              You can manage this information at their{' '}
              <ExtLink to={field.meta.unsub} className="i">
                Subscription Page
              </ExtLink>
              .
            </>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export function FieldHead({ field, app, edit = true }) {
  if (field.type === 'journey' || field.type === 'status') {
    return null
  }
  const { answersD = {} } = app
  const answer = answersD[field.id] || {}

  if (field.type === 'shareContact') {
    return (
      <>
        <div className="primary b mt5 f4">{interpolate(field.question, app)}</div>
        {edit ? <ContactToS app={app} field={field} /> : null}
      </>
    )
  }
  return (
    <>
      <div className="primary b mt5 f4">
        {field.required && !answer.good ? (
          <span className="red mr2">*</span>
        ) : null}
        {interpolate(field.question, app)}
      </div>
      {field.explain && edit ? (
        <div className="mv3 f6">{interpolate(field.explain, app)}</div>
      ) : null}
    </>
  )
}

export default EditForm
