export const FLOAT_DECIMALS = 5

// the tracked value state default
export const defaultTracked = {
  label: 'none',
  last: 0,
  max: 0,
  history: [],
  plot: []
}

// add a new value to our historical plotline -- does not update `plot`
export function trackedValue({
  label,
  state,
  last,
  width,
  height,
  reverse = false,
  more
}) {
  if (last) state.history.unshift(last)
  const history = state.history.filter((h) => !isNaN(h)).slice(0, width)
  const max = Math.max(...history) * 1.1
  return {
    ...more,
    label,
    last,
    history,
    max,
    plot: []
  }
}

// update `plot` across an array of tracked plots, using the shared max height
export function plotValues(plots, max, width, height, reverse) {
  return plots.map((state) => {
    return {
      ...state,
      plot: plotXY(state.history, max, width, height, reverse)
    }
  })
}

// build the XY plots for a historical array
export function plotXY(history, max, width, height, reverse) {
  if (max) {
    const step = height / max
    return history.slice(0, width).map((d, index) => {
      const x = reverse ? width - index : index
      return x + ',' + (height - d * step).toFixed(FLOAT_DECIMALS)
    })
  } else {
    return []
  }
}
