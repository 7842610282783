import React, { useEffect } from 'react'

import paths from 'constants/paths'

import Link from 'tools/Links'

import { avatarUrl } from 'components/Person/tools/Picture'
import { normalizeProject } from 'components/Project/normalize'

import SubjectLine from '../SubjectLine'
import style from './index.module.scss'

export function TopicHeader({ topic, setTopic }) {
  useEffect(() => {
    if (!topic.project._normal) {
      const project = normalizeProject(topic.project)
      setTopic({ ...topic, project })
    }
  }, [topic.project._normal, setTopic, topic])
  const { url } = avatarUrl(topic.project)
  return (
    <div className="flex justify-between">
      <SubjectLine
        msg={topic}
        subjectClass={`f3 b ${topic.visible ? '' : 'strike'}`}
        className={style.subject}
        showCount={false}
      />
      <Link
        className={`${style.parent} ml-auto pointer`}
        to={`${paths.project}/${topic.project.shortId}`}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        type="div"
      >
        <img src={url} alt="" />
      </Link>
    </div>
  )
}

export default TopicHeader
