import React, { Suspense, lazy, useContext, useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'

import paths from 'constants/paths'

import IdentifiedUser from 'tools/IdentifiedUser'
import { LoadingOverlay, LoadingTop } from 'tools/Loader'
import NotifyContext, { notify } from 'tools/Notify/resolver'
import { when } from 'utils/function'
import { AUTHX_ACTIONS, AUTHX_STATUS, asyncRefreshToken } from 'utils/signon'

import { LOAD_PERSON } from 'components/Person/graphql'
import Root from 'components/Root'
import Studio from 'components/Studio'
import GlobalContext, { R_GLOBAL } from 'reducer/global'

const Docs = lazy(() => import('components/Docs'))
const Home = lazy(() => import('components/Home'))
const Project = lazy(() => import('components/Project'))
const Org = lazy(() => import('components/Org'))
const Person = lazy(() => import('components/Person'))
const PublicPerson = lazy(() => import('components/Person/Public'))
const Apply = lazy(() => import('components/Apply'))
const Signon = lazy(() => import('components/Signon'))
const Feed = lazy(() => import('components/Feed'))
const Learn = lazy(() => import('components/Learn'))
const Meet = lazy(() => import('components/Meet'))
const ReturnCode = lazy(() => import('components/ReturnCode'))
const FormatTest = lazy(() => import('components/Admin/FormatTest'))
const ShowError = lazy(() => import('components/Admin/ShowError'))
const AdminSummary = lazy(() => import('components/Admin/Summary'))
const AdminUser = lazy(() => import('components/Admin/User'))
const AdminJourney = lazy(() => import('components/Admin/Journey'))
const Mentor = lazy(() => import('components/Admin/Mentor'))

const LOADING = <LoadingOverlay />

export function App() {
  const [state, dispatch] = useContext(GlobalContext)
  const [, notifyDispatch] = useContext(NotifyContext)

  useEffect(() => {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        dispatch({ type: R_GLOBAL.PAGE_SET, value: { theme: 'light' } })
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          dispatch({
            type: R_GLOBAL.PAGE_SET,
            value: { theme: e.matches ? 'dark' : 'light' }
          })
        })
    }
  }, [dispatch])

  // initialize apollo
  useEffect(() => {
    if (!state.apolloInit) {
      dispatch({ type: R_GLOBAL.APOLLO_RESET, dispatch })
    }
  }, [state.apolloInit, dispatch])

  // Load user, refetch when we are able to do so (have a validation token)
  const { isAuthN, refresh } = state.authx
  useEffect(() => {
    if (refresh) {
      asyncRefreshToken(dispatch)
    }
  }, [refresh, dispatch])

  // Fetch profile after done signing in
  useEffect(() => {
    let isMounted = true
    if (isAuthN) {
      state.apollo.query({ query: LOAD_PERSON, variables: {id: "self"}, fetchPolicy: 'network-only' }).then(
        when(isMounted, ({ data: { users } }) => {
          if (users.success) {
            dispatch({ type: R_GLOBAL.USER_SET, value: users.result })
          }
        })
      )
    }
    return () => {
      isMounted = false
    }
  }, [isAuthN, state.apollo, dispatch])

  // convert authx errors to notify
  const authx_error = state.authx.error
  useEffect(() => {
    if (authx_error) {
      notify(notifyDispatch, { content: authx_error })
      dispatch({ type: AUTHX_ACTIONS.ERROR_CLEAR })
    }
  }, [authx_error, dispatch, notifyDispatch])

  const theme = `theme-${state.user.settings.theme || state.page.theme}`
  useEffect(() => {
    document.body.classList.remove('theme-dark')
    document.body.classList.remove('theme-light')
    document.body.classList.add(theme)
  }, [theme])

  return (
    <ApolloProvider client={state.apollo}>
      <Router>
        <Root>
          <Switch>
            <Route exact path="/">
              {state.authx.status === AUTHX_STATUS.INITIAL ? (
                <LoadingTop />
              ) : state.authx.status === AUTHX_STATUS.ANONYMOUS ? (
                <Suspense fallback={LOADING}>
                  <Home />
                </Suspense>
              ) : (
                <Suspense fallback={LOADING}>
                  <Feed />
                </Suspense>
              )}
            </Route>
            <Route path={paths.feed}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <Feed />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.personRoute}>
              <Suspense fallback={LOADING}>
                <Person />
              </Suspense>
            </Route>
            <Route path={paths.orgRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <Org />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.studioRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <Studio />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.applyRoute}>
              <Suspense fallback={LOADING}>
                <Apply />
              </Suspense>
            </Route>
            <Route path={paths.projectRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <Project />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.personPublicRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <PublicPerson />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.learnRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <Learn />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.meetRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <Meet />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.home}>
              <Suspense fallback={LOADING}>
                <Home />
              </Suspense>
            </Route>
            <Route path={paths.docsRoute}>
              <Suspense fallback={LOADING}>
                <Docs />
              </Suspense>
            </Route>
            <Route path={paths.signon}>
              <Suspense fallback={LOADING}>
                <Signon signout={false} />
              </Suspense>
            </Route>
            <Route path={paths.signup}>
              <Suspense fallback={LOADING}>
                <Signon signout={false} />
              </Suspense>
            </Route>
            <Route path={paths.signout}>
              <Suspense fallback={LOADING}>
                <Signon signout={true} />
              </Suspense>
            </Route>
            <Route path={paths.returnCodeRoute}>
              <Suspense fallback={LOADING}>
                <ReturnCode />
              </Suspense>
            </Route>
            <Route path={paths.admJourneyRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminJourney />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.admMentorRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <Mentor />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.admUserRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminUser />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.admSummaryRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminSummary />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path="/format">
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <FormatTest />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path="/err">
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <ShowError />
                </Suspense>
              </IdentifiedUser>
            </Route>
          </Switch>
        </Root>
      </Router>
    </ApolloProvider>
  )
}

export default App
