import paths from 'constants/paths'

import Browse from './Browse'
import Create from './Create'
import Show from './Show'
import { LOAD_ORG_FULL, UPSERT_ORG } from './graphql'
import { OrgContext, OrgProvider, R_ORG } from './reducer'

const mod = {
  Context: OrgContext,
  Provider: OrgProvider,
  Browse,
  Show,
  Create,
  DO_LOAD: R_ORG.SET_ORG,
  DO_MERGE: R_ORG.MERGE,
  LOAD: LOAD_ORG_FULL,
  SAVE: UPSERT_ORG,
  targetKey: 'orgId',
  queryName: 'orgs',
  componentName: 'org',
  oneLoadResult: false,
  basePath: paths.org,
  controlView: (id) => `${paths.org}/${id}`,
  controlClose: (id) => paths.org
}
export default mod
