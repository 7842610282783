import React from 'react'

import { Text } from '../Text'

// plots struct:
// {
//    label: "name"
//    plot: []
//    stroke: "color" -- optional
//    fill: "color" -- optional
//    strokeWidth: "size" -- optional
// }
export function Plot({
  stroke = 'none',
  strokeWidth = '0',
  fill = 'none',
  x,
  y,
  width,
  height,
  plots,
  horiz = []
}) {
  return (
    <>
      <g transform={`translate(${x},${y})`}>
        {horiz.map((h) => (
          <Horizline key={h.label} {...h} width={width} height={height} />
        ))}
        {plots.map((p) => (
          <Sparkline key={p.label} {...p} width={width} height={height} />
        ))}
        <polyline
          points={`0,0 0,${height} ${width},${height}`}
          className="theme-minimize-stroke"
          strokeWidth="1"
          fill="none"
        />
      </g>
    </>
  )
}

export function Horizline({
  y,
  width,
  label = '',
  labelRight = true,
  textStyle = {},
  ...args
}) {
  const x = labelRight ? width : 4
  return (
    <>
      <polyline points={`0,${y} ${width},${y}`} {...args} />
      {label ? (
        <Text
          x={x}
          y={y - 2}
          style={textStyle}
          textAnchor={labelRight ? 'end' : null}
        >
          {label}
        </Text>
      ) : null}
    </>
  )
}

export function Sparkline({
  plot = [],
  stroke = 'none',
  strokeWidth = '0',
  fill = 'none',
  width,
  height,
  ...args
}) {
  return (
    <polyline
      points={plot.join(' ')}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      fill={fill}
      {...args}
    />
  )
}

export default Plot
