import React from 'react'

import { ICON } from 'constants/icons'
import paths from 'constants/paths'

import { Link } from 'tools/Links'
import { fromNowShort } from 'utils/time'

import style from './index.module.scss'

export function Rows({ children }) {
  return (
    <table className={`${style.rows} list hover altrows w-100`}>
      <tbody>{children}</tbody>
    </table>
  )
}

// TODO: move to files component as re-usable piece
// const SHOW_TYPES = ['lightning', 'pitchPdf', 'pitchVideo']
export function ProjectFileIcons({ project }) {
  return (
    <>
      {project.fileTypes.lightning?.length && (
        <i
          className="fas fa-bolt mr2 gold"
          key={project.fileTypes.lightning[0].id}
        />
      )}
      {project.fileTypes.pitchPdf?.length && (
        <i
          className="fas fa-file-powerpoint mr2 gold"
          key={project.fileTypes.pitchPdf[0].id}
        />
      )}
      {project.fileTypes.pitchVideo?.length && (
        <i
          className="fas fa-video mr2 gold"
          key={project.fileTypes.pitchVideo[0].id}
        />
      )}
    </>
  )
}

export function Row({ project, children = undefined, link = undefined }) {
  const age = fromNowShort(project.updatedAt)
  return (
    <Link type="tr" to={link ? link : `${paths.project}/${project.shortId}`}>
      <td className={style.pic}>
        <div>
          <img
            src={project.avatar && project.avatar.url}
            alt=""
            className="br1"
          />
        </div>
      </td>
      <td className={style.title}>
        <div>{project.title}</div>
      </td>
      <td className={style.icons}>
        <StatusIcons className="pv3" project={project} age={age} />
      </td>
      <td className={style.milestones}>
        <div>{project.milestones.map((m) => m.name).join(', ')}&nbsp;</div>
      </td>
      {children}
    </Link>
  )
}

export function StatusIcons({ className = '', project, age }) {
  return (
    <div className={`flex-items ${className} f7 gray`}>
      <ProjectFileIcons project={project} />
      <div className="mr3">
        <i className={`${ICON.chat} mr1`} />
        {project.summary?.comments}
      </div>
      <div className="mr3 flex">
        <i className="fas fa-clock mr2" />
        {age}
      </div>
      <div className="">
        <i className="fas fa-users mr2" />
        {project.members.length}
      </div>
    </div>
  )
}
