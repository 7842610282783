// import Browse from './Browse'
// import Navigator from './Navigator'
// import Create from './Create'
import paths from 'constants/paths'

import Rubric from './Rubric'
import { JOURNEY_ACTOR_MAP_LOAD } from './graphql'
import { JourneyContext, JourneyProvider, R_JOURNEY } from './reducer'

const mod = {
  // debug: true,
  Context: JourneyContext,
  Provider: JourneyProvider,
  Show: Rubric,
  tabShow: 'rubrics',
  DO_RESET: R_JOURNEY.RESET,
  DO_LOAD: R_JOURNEY.SET_MAP,
  DO_MERGE: R_JOURNEY.MERGE,
  LOAD: JOURNEY_ACTOR_MAP_LOAD,
  targetKey: 'subId',
  oneLoadResult: true,
  basePath: paths.journey,
  queryName: 'journeyActorMap'
}
export default mod
