import React, { createContext, useReducer } from 'react'

import { normalizeParentFiles } from 'tools/Files/normalize'
import { normalizeTags } from 'tools/SelectTags'
import { removeItem, upsertItem } from 'utils/array'
import { inspect } from 'utils/function'

import { normalizeChallenges } from 'components/Journey/normalize/challenge'

import { defaultProject, normalizeProject } from './normalize'

export const R_PROJECT = {
  SET_TAGS: 100,
  SET_PROJECT: 101,
  SET_CHALLENGES: 102,
  RESET_PROJECT: 103,
  SET_FILES: 200,
  UPSERT_FILE: 201,
  DELETE_FILE: 202,
  MERGE: 300,
  OPEN_TAB: 400,
  CLOSE_TAB: 401,
  CHANGE_DATA: 500
}

////////////////////////////////////////////////////////////////////////////////
export function reducer(state, { type, value, ...args }) {
  switch (type) {
    case R_PROJECT.SET_PROJECT:
      return normalizeProject(value, args.user, state)

    case R_PROJECT.RESET_PROJECT:
      return normalizeProject(defaultProject, args.user, state)

    case R_PROJECT.SET_TAGS:
      return normalizeTags(state, value)

    case R_PROJECT.UPSERT_FILE:
      return normalizeParentFiles(state, upsertItem(state.files, value))

    case R_PROJECT.DELETE_FILE:
      return normalizeParentFiles(state, removeItem(state.files, value))

    case R_PROJECT.SET_FILES:
      if (value) return normalizeParentFiles(state, value)
      return state

    case R_PROJECT.CHANGE_DATA: {
      const { component, token } = args
      const current = state.d[component] || {}
      return {
        ...state,
        d: { ...state.d, [component]: { ...current, [token]: value } }
      }
    }

    case R_PROJECT.MERGE:
      return { ...state, ...value }

    case R_PROJECT.SET_CHALLENGES:
      return {
        ...state,
        ...inspect(normalizeChallenges(value, state.milestonesD, state.shortId))
      }

    // case R_PROJECT.OPEN_TAB:
    //   return { ...state, tabs: openTab(state.tabs, value) }
    //
    // case R_PROJECT.CLOSE_TAB:
    //   return { ...state, tabs: closeTab(state.tabs, value) }

    default:
      throw new Error(`project - no such action.type: ${type}!`)
  }
}

export const ProjectContext = createContext(null)
export const ProjectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultProject)
  return (
    <ProjectContext.Provider value={[state, dispatch]}>
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectContext
