import React, { useContext, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'

import paths from 'constants/paths'

import { CHG, INPUT_TYPES, Input } from 'tools/Input'
import Link from 'tools/Links'
import Markdown from 'tools/Markdown'
import Modal from 'tools/Modal'
import Toggle from 'tools/Toggle'
import { fromNow } from 'utils/time'

import ViewTopic from 'components/Learn/ViewTopic'
import ProjectContext from 'components/Project/reducer'
import { GlobalContext } from 'reducer/global'
import { useExpandQuery2 } from 'reducer/global/hooks'

import { UPSERT_ANSWER } from '../graphql'
import { ANSWER_STATUS } from '../normalize/answer'
import JourneyContext, { R_JOURNEY } from '../reducer'
import style from './Questions.module.scss'

function RubricQuestions({ rubric }) {
  const [
    {
      querys: { q: expand }
    }
  ] = useContext(GlobalContext)
  const [show, setShow] = useState('questionMapShow')

  const hidden =
    show === 'questionMapShow'
      ? rubric.questionMapsL.length - rubric.questionMapShow.length
      : 0
  return (
    <div className="">
      {rubric[show].map((qId, x) => {
        const qm = rubric.questionMapsD[qId]
        const expanded = expand.includes(qm.shortId)
        return (
          <RubricQuestion
            rubric={rubric}
            questionMap={qm}
            key={qId}
            inner={x !== 0 && x !== rubric.questionMapsL.length}
            expanded={expanded}
          />
        )
      })}
      {hidden > 0 ? (
        <div
          className="link pl5 i pb3 pt1 gray f6"
          onClick={() => setShow('questionMapsL')}
        >
          (+{hidden} questions unlocked in future milestones)
        </div>
      ) : null}
    </div>
  )
}

function isRequired({ milestonesOpen }, { acceptedId }) {
  return milestonesOpen[acceptedId]
}

function RubricQuestion({ rubric, questionMap, inner, expanded }) {
  const [jmap] = useContext(JourneyContext)
  const [state] = useContext(GlobalContext)
  const { pivot } = useExpandQuery2(state, 'q')
  const myRef = React.useRef()
  const answer = jmap.actor.answersD[questionMap.answerId] || {}

  const required = isRequired(jmap, questionMap)
  useEffect(() => {
    const [, href] = window.location.hash.split('#')
    if (href === questionMap.shortId && myRef.current) {
      myRef.current.scrollIntoView()
    }
  }, [questionMap.shortId])

  return (
    <>
      <div
        className={`pl4 pointer hover-hilite
          ${expanded ? 'bt b--gray' : ''}
        `}
        ref={myRef}
        onClick={() => pivot(questionMap.shortId)}
      >
        <div className="fader flex-items pv2">
          <div className="f6 pa2 pr3 gray">
            <i className={`fas fa-${expanded ? 'minus' : 'plus'} f7`} />
          </div>
          <div
            className={`${expanded ? 'fades hide' : ''} b w-100 ${
              required ? 'warning' : ''
            }`}
          >
            {questionMap.question.question}
            {required ? '*' : null}
          </div>
          <AnswerStatusLabel
            answer={answer}
            short={true}
            className="f7 mr2 mr4-l"
          >
            {answer.feedback ? <i className="fas fa-comment-alt mr2" /> : null}
          </AnswerStatusLabel>
        </div>
      </div>
      <div
        className={`${style.detail} ${expanded ? style.expand : style.collapse}
        ${expanded ? 'bb b--gray' : ''}`}
      >
        {expanded ? (
          <QuestionDetail questionMap={questionMap} inner={inner} />
        ) : null}
      </div>
    </>
  )
}

function QuestionDetail({ questionMap, inner }) {
  const [jmap, dispatch] = useContext(JourneyContext)
  const [project] = useContext(ProjectContext)

  // TODO: normalize should create defaults for answers that aren't answered...
  const answer = jmap.actor.answersD[questionMap.answerId] || {}
  const question = questionMap.question
  const [answerInput, setAnswerInput] = useState(answer.answer || '')
  const [show, setShow] = useState({
    learn: false,
    topic: undefined,
    feedback: project.can.answerWrite,
    error: undefined
  })
  const [upsertAnswer] = useMutation(UPSERT_ANSWER)

  const makeChange = ({ token }, value, good, bad) => {
    if (token === 'ready' || answerInput !== answer.answer) {
      const vars = {
        id: answer.id,
        actorId: jmap.actor.id,
        journeyMapId: jmap.id,
        questionId: questionMap.question.id
      }
      vars[token] = value
      upsertAnswer({
        variables: { answer: vars },
        update(cache, { data: { upsertRubricAnswer: result } }) {
          if (result.success) {
            dispatch({
              type: R_JOURNEY.UPDATE_ANSWER,
              value: result.result
            })
            good && good(CHG.SAVED)
          } else {
            bad && bad(result.reason)
          }
        }
      })
    }
  }

  const args = { answer, question, jmap, show, setShow, makeChange }
  return (
    <div className="">
      <div className="ph2 ph3-m ph5-l pv5 pv5-l relative hidden">
        <form>
          <div className="pb3">
            <div className="f4 b mb4 flex-items w-100">
              <div>{question.question}</div>
              {question.type === 'checkbox' && (
                <div className="ml-auto">CHECK</div>
              )}
            </div>
            <div className="fader">
              <div className="i pv3 mb4 pl4">
                <Markdown>{question.explain}</Markdown>
              </div>
            </div>
          </div>
          <div className="mv3">
            {question.type !== 'checkbox' && (
              <div className="flex w-100">
                <Input
                  type={question.type === 'answer' && INPUT_TYPES.TEXTAREA}
                  style={{ resize: 'vertical' }}
                  valState={[answerInput, setAnswerInput]}
                  disabled={!project.can.answerWrite || answer.ready}
                  token="answer"
                  onSave={(...args) => {
                    setShow({ ...show, error: undefined })
                    makeChange(...args)
                  }}
                  className="w-100"
                  placeholder={question.type === 'answer' ? 'Answer...' : 'Link'}
                  inputClass="lh-copy pa3 w-100"
                />
              </div>
            )}
            <div className="flex-items mt3 justify-between">
              <AnswerStatus {...args} canWrite={project.can.answerWrite} />
              <Feedback {...args} />
              <LearningTopics {...args} canWrite={project.can.answerWrite} />
            </div>
            {show.error ? <div className="red">{show.error}</div> : null}
            <ShowFeedback {...args} />
            <ShowLearn {...args} />
          </div>
        </form>
      </div>
    </div>
  )
}

function ShowFeedback({ show, answer }) {
  if (!show.feedback || !answer.feedback) {
    return null
  }
  return (
    <div className="">
      <div className="f7 gray i">Feedback {fromNow(answer.feedbackOn)}:</div>
      <div className="bl bw2 b--gray pl2 i mt2 lh-copy">
        <Markdown>{answer.feedback}</Markdown>
      </div>
    </div>
  )
}

function ShowLearn({ show, question }) {
  if (!show.learn) {
    return null
  }
  return (
    <>
      <div className="mb2 mt4 i f5 heading">Need Help? Explore these topics:</div>
      <ListTopics topics={question.topics} />
    </>
  )
}

export function ListTopics({ topics }) {
  const [show, setShow] = useState(undefined)
  return (
    <>
      <table className="list hover">
        <tbody>
          {topics.map((topic) => (
            <tr key={topic.id} className="pointer" onClick={() => setShow(topic)}>
              <td className="b nowrap" style={{ verticalAlign: 'top' }}>
                {topic.name}
              </td>
              <td className="f7" style={{ maxWidth: '50%' }}>
                &mdash; {topic.summary}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {show ? (
        <Modal
          viewState={[show, setShow]}
          width="fw-95"
          header={
            <Link to={`${paths.learn}/${show.id}`}>
              {show.name}
              <i className="ml2 fas fa-link" />
            </Link>
          }
          className="theme-bg-flat"
        >
          <ViewTopic topicId={show.id} />
        </Modal>
      ) : null}
    </>
  )
}

export function AnswerStatusLabel({
  answer,
  className = '',
  short = false,
  children = undefined
}) {
  const classes = `b i flex-items`
  switch (answer.statusA) {
    case ANSWER_STATUS.SUBMITTED:
      return (
        <div className={`blue ${classes} ${className}`}>
          {children} Pending {short ? '' : 'Review'}
        </div>
      )
    case ANSWER_STATUS.RETURNED:
      return (
        <div className={`orange ${classes} ${className}`}>
          {children} {short ? 'Returned' : 'Needs Improvement'}
        </div>
      )
    case ANSWER_STATUS.ACCEPTED:
      return (
        <div className={`green ${classes} ${className}`}>
          {children} <i className="fas fa-check mr2" /> Accepted
        </div>
      )
    default:
      return (
        <div className={`gray ${classes} ${className}`}>
          {children} {short ? 'WIP' : 'Work in Progress'}
        </div>
      )
  }
}

function AnswerStatus({ answer, jmap, show, setShow, makeChange, canWrite }) {
  if (!canWrite) {
    return <AnswerStatusLabel answer={answer} />
  }
  const canComplete = answer.length > 0
  return (
    <div className="flex-items w-100">
      <AnswerStatusLabel answer={answer} className="mr-auto" />
      {answer.statusA !== undefined && (
        <div
          className={`flex-items
          button border ml3 mr3 ${
            !answer.ready && canComplete ? 'primary' : 'clear disabled'
          } br3`}
          onClick={() => {
            if (canComplete) {
              setShow({ ...show, error: undefined })
              makeChange(
                { token: 'ready' },
                !answer.ready,
                () => {},
                () => {}
              )
            } else {
              setShow({ ...show, error: 'Your answer is incomplete' })
            }
          }}
        >
          <Toggle
            value={answer.ready}
            disabled={!canWrite || !canComplete}
            hilite=""
          />
          <div className="pl1 f7 pr3">
            {answer.statusA === ANSWER_STATUS.WORKING ||
            answer.statusA === ANSWER_STATUS.RETURNED
              ? 'Mark Ready'
              : 'Reset for Edit'}
          </div>
        </div>
      )}
    </div>
  )
}

function Feedback({ answer, show, setShow }) {
  if (!answer.feedback) {
    return null
  }
  return (
    <button
      className="border pv2 ph3 mv1 flex f7 i tc h-100 ml-auto mr3"
      onClick={(e) => {
        e.preventDefault()
        setShow({ ...show, feedback: !show.feedback })
      }}
    >
      {show.feedback ? 'Hide' : 'Show'} Feedback
    </button>
  )
}

function LearningTopics({ question, jmap, show, setShow, canWrite }) {
  return (
    <div>
      {question.topics.length > 0 ? (
        <button
          className={`pv2 ph3 mv1 flex f7 i tc h-100`}
          onClick={(e) => {
            e.preventDefault()
            setShow({ ...show, error: undefined, learn: !show.learn })
          }}
        >
          {show.learn
            ? 'Hide Learning Topics'
            : canWrite
            ? 'Need Help?'
            : 'Show Learning Topics'}
        </button>
      ) : null}
    </div>
  )
}

export default RubricQuestions
