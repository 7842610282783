import React from 'react'

export const ICON = {
  edit: 'fas fa-pen',
  chat: 'fas fa-comment-alt',
  hide: 'fas fa-ban',
  close: 'fas fa-times',
  view: 'fas fa-eye'
}

export const GLYPH = {
  dot: <>&#183;</>
}

export default ICON
