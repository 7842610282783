// using lists for ordered sets
export function setAdd(list1, component) {
  if (!list1.includes(component)) {
    list1.push(component)
  }
  return list1
}

export function setRemove(list1, component) {
  return list1.filter((i) => i !== component)
}

export function setMerge(list1, list2) {
  list2.forEach((elem) => setAdd(list1, elem))
  return list1
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(range(0, 4, 1), [ 0, 1, 2, 3 ])
 * t.deepEqual(range(0, 4), [ 0, 1, 2, 3 ])
 * t.deepEqual(range(0, 4, 2), [ 0, 2 ])
 * t.deepEqual(range(0, 5, 2), [ 0, 2, 4 ])
 * ```
 */
export function range(start, stop, step = 1) {
  return Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)
}

////////////////////////////////////////////////////////////////////////////////
export function removeItem(list, item) {
  return list.filter((i) => i.id !== item.id)
}

export function upsertItem(list, item) {
  return removeItem(list, item).concat(item)
}
