import React, { useCallback, useContext, useEffect, useState } from 'react'

import Link from 'tools/Links'
import ManageMembers from 'tools/ManageMembers'
import Modal from 'tools/Modal'
import RadioButtons from 'tools/RadioButtons'
import { setAdd } from 'utils/array'

import { MEMBER_TYPES, ROLES, ROLES_MAP } from '../defs'
import { UPSERT_PROJECT_MEMBER } from '../graphql'
import ProjectContext from '../reducer'

function addToSet(list, set) {
  return (list || []).reduce((set, role) => {
    set.add(role)
    return set
  }, set)
}

function Members() {
  const viewState = useState(false)
  const [project] = useContext(ProjectContext)
  const [roles, setRoles] = useState(new Set())

  const needs = project.d?.needs || {}
  const proles = needs.roles
  useEffect(() => {
    setRoles(
      project.members.reduce(
        (roles, mbr) => addToSet(proles, addToSet(mbr.roles, roles)),
        new Set()
      )
    )
  }, [project.members, setRoles, proles])

  const missing = ROLES.filter((r) => !roles.has(r.value))

  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4">
      <label className="mb3 heading f4">
        <i className="fas fa-users mr2" />
        {project.name} Team
      </label>
      <ManageMembers
        types={MEMBER_TYPES}
        roles={ROLES}
        token="projectId"
        target={project}
        canChange={project.canUsers}
        onChange={project.refetch}
        mutate={UPSERT_PROJECT_MEMBER}
      />
      {missing.length ? (
        <>
          <div className="mt4 mb3">
            <label className="heading">
              Missing Roles
              <RolesHelp open={viewState[1]} />
            </label>
          </div>
          <i className="gray">
            Common roles found in ventures include the following.
          </i>
          <ul className="less">
            {missing.map((r) => (
              <li
                key={r.value}
                className="button plain clear pv1 ph2 fader flex-items"
                onClick={() => {
                  project.onSave(
                    { token: 'needs' },
                    JSON.stringify({
                      ...needs,
                      roles: setAdd(needs.roles || [], r.value).toSorted()
                    })
                  )
                }}
              >
                <div>
                  {' '}
                  {r.label} <span className="normal gray f6">({r.value})</span>
                </div>
                <div className="fades ml-auto normal">
                  <i className="fas fa-plus mr2" />
                  Add to Needs
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      <NeedHelp viewState={viewState} />
    </div>
  )
}

function RolesHelp({ open }) {
  return (
    <i
      className="fas fa-question-circle ml2 hover-primary pointer"
      onClick={(ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        open(true)
      }}
    />
  )
}

function NeedHelp({ viewState }) {
  const [project] = useContext(ProjectContext)
  const [addRole, setAddRole] = viewState
  const roles = project.d?.needs?.roles || []

  return (
    <div className="mb3">
      <label className="heading mt4 mb3">
        Need help with <RolesHelp open={viewState[1]} />
      </label>
      <i className="gray">
        On your venture you can list specific roles you need help with, and people
        can search for these.
      </i>
      <ul className="less">
        {roles.map((r, x) => (
          <li key={`${r}${x}`} className="b pv1 ph2">
            {ROLES_MAP[r].label}
          </li>
        ))}
      </ul>
      <button className="plain medium ph2 f7" onClick={() => setAddRole(true)}>
        <i className="fas fa-user-alt mr2" />
        Manage Needed Roles
      </button>
      <AddRole project={project} viewState={[addRole, setAddRole]} />
    </div>
  )
}

function AddRole({ project, viewState }) {
  const { onSave } = project
  const saveNeed = useCallback(
    (value) => {
      onSave({ token: 'needs' }, JSON.stringify(value))
    },
    [onSave]
  )

  const setRoles = ({ orig, type, token, boolTrue }, selected) => {
    if (orig.includes(selected))
      saveNeed({ roles: orig.filter((i) => i !== selected) })
    else saveNeed({ roles: orig.concat(selected) })
  }

  return (
    <Modal viewState={viewState} width="fw-95" className="pa4">
      <div className="f3">Needed Roles</div>
      <div className="mt4">Select which roles you need help with:</div>
      <RadioButtons
        className="mt3"
        value={project.d?.needs?.roles || []}
        variant="column"
        type="multiple"
        opts={ROLES}
        onSave={setRoles}
        token="roles"
      />
      <div className="mv4">
        <Link to="/p/24e37/r/d350b">
          Role Missing? Give us feedback on what you would like to see.
        </Link>
      </div>
      <button className="mt4 ph3 pv2" onClick={() => viewState[1](false)}>
        Done
      </button>
    </Modal>
  )
}

export default Members
