// HACK: duplicate of Project/AddJourney
import React, { useCallback, useContext } from 'react'

import { useQuery } from '@apollo/client'

import { CheckIcon } from 'tools/Uniform'

import { JOURNEYS } from 'components/Journey/graphql'

import { ApplyContext } from './reducer'

function SelectJourney({ field, type }) {
  const { data } = useQuery(JOURNEYS, {
    variables: { type },
    fetchPolicy: 'cache-and-network'
  })
  if (!data) return null

  const { journeys } = data

  return (
    <div>
      {journeys.map((j) => (
        <JourneyRow key={j.id} journey={j} type={type} field={field} />
      ))}
    </div>
  )
}

export function JourneyRow({ journey, type, field }) {
  const [state] = useContext(ApplyContext)
  const journeyId = journey.id
  const onSave = state.onSave
  const answer = state.answersD[field.id]
  const checked = (answer?.maps || []).includes(journey.id)
  const changeJourney = useCallback(
    () =>
      onSave({ token: 'journey', field: field }, { remove: checked, journeyId }),
    [onSave, field, checked, journeyId]
  )

  if (journey.type !== type || journey.status !== 'open') {
    return null
  }
  return (
    <button
      className={`clear w-100 flex-between ${checked ? 'primary' : ''}`}
      onClick={changeJourney}
    >
      <div className="b">
        <CheckIcon checked={checked} className="mr3" />
        {journey.name}
      </div>
      <div className="ml3 i">
        <span className="mr3">&mdash;</span>
        {journey.description}
      </div>
      <div className="ml-auto">
        <img src="/assets/img/venturly.svg" style={{ height: '1.5rem' }} alt="" />
      </div>
    </button>
  )
}

export default SelectJourney
