import React from 'react'

import Editable from 'tools/Editable'

import jFrame from 'components/Journey/frame'

function Rubrics() {
  return <Editable frame={jFrame} />
}

export default Rubrics
