import gql from 'graphql-tag'

export const answerVars = `
  id
  version
  status
  questionId
  answer
  readyOn
  acceptedOn
  feedback
  feedbackOn
`

export const journeyVars = `
  id
  name
  status
  description
  type
  org { id name }
  site { id, name, shortId }
  meta
`

export const questionVars = `
  id
  type
  question
  explain
  topics {
    id
    name
    audience
    summary
  }
`

export const questionMapVars = `
id
order
visibleId
submitId
acceptedId
question {
  ${questionVars}
}
`

export const rubricVars = `
id
name
order
questionMaps {
  ${questionMapVars}
}
visibleId
`

export const journeyLoadVars = `
  ${journeyVars}
  milestones {
    id
    name
    order
    meta
    challengeLinks {
      id
      order
      challenge {
        id
        name
      }
    }
  }
  rubrics {
    ${rubricVars}
  }
`

export const loadChallengeVars = `
  id
  order
  challenge {
    id
    name
    explain
    completed
    visible
    context
    links {
      challengeId
    }
    conditions
    actions
  }
`

export const journeyActorMapLoadValues = `
  id
  shortId
  milestoneId
  journey {
    ${journeyLoadVars}
  }
  actor {
    id
    type
    refId
    answers {
      ${answerVars}
    }
    project {
      id
      title
      shortId
    }
  }
`

// challenges {
//   id
//   name
//   award
//   active
// }
// schema {
//   id,
//   grouping,
//   name,
//   order,
//   summary
// }

export const JOURNEYS = gql`
query journeys($status: JourneyStatus, $type: JourneyType, $id: String, $all: Boolean) {
  journeys(status: $status type: $type, id: $id, all: $all) {
    ${journeyVars}
  }
}
`

export const JOURNEY_ACTOR_ADD = gql`
  mutation upsertJourneyActor(
    $action: InputActions!
    $type: JourneyType!
    $journeyId: String!
    $refId: String!
  ) {
    upsertJourneyActor(
      action: $action
      journeyId: $journeyId
      refId: $refId
      type: $type
    ) {
      id
    }
  }
`

export const REACTOR_CHALLENGES = gql`
query reactorChallenges($projectId: String) {
  reactorChallenges(projectId: $projectId) {
    ${loadChallengeVars}
  }
}
`

export const JOURNEY_ACTOR_MAP_LOAD = gql`
query journeyActorMap($id: String!) {
  journeyActorMap(id: $id) {
    success
    reason
    result {
      ${journeyActorMapLoadValues}
    }
  }
}
`

export const UPSERT_ANSWER = gql`
mutation upsertRubricAnswer($answer: InputRubricAnswer!) {
  upsertRubricAnswer(answer: $answer) {
    success
    reason
    result {
      ${answerVars}
    }
  }
}
`
