import React from 'react'

import { ICON } from 'constants/icons'
import paths from 'constants/paths'

import { Link } from 'tools/Links'
import { fromNowShort } from 'utils/time'

import style from './index.module.scss'

export function Tile({
  project,
  children = undefined,
  link = undefined,
  onClick = undefined
}) {
  const age = fromNowShort(project.updatedAt)
  let available = []
  if (project.fileTypes.lightning) {
    available = [
      <i
        className="fas fa-bolt mr2 gold"
        key={project.fileTypes.lightning[0].id}
      />
    ]
  }
  if (project.fileTypes.pitchPdf) {
    available = available.concat([
      <i
        className="fas fa-file-powerpoint mr2 gold"
        key={project.fileTypes.pitchPdf[0].id}
      />
    ])
  }
  // desktop
  if (project.fileTypes.pitchVideo) {
    available = available.concat([
      <i
        className="fas fa-video mr2 gold"
        key={project.fileTypes.pitchVideo[0].id}
      />
    ])
  }
  let needs = []
  if (project.summary.goal) {
    needs = needs.concat(
      <div key="need-goal" className="mr3">
        <i className="fas fa-money-bill-wave-alt mr2 red" /> $
        {Math.floor(project.summary.goal / 1000)}k
      </div>
    )
  }
  if (project.summary.roles) {
    const roles = project.summary.roles
    needs = needs.concat(
      <div key="need-roles" className="mr3">
        <i className="fas fa-user mr2 red" /> {roles.join(', ')}
      </div>
    )
  }
  const args = {}
  if (onClick) {
    args.onClick = onClick
  } else if (link) {
    args.to = link
  } else {
    args.to = `${paths.project}/${project.shortId}`
  }

  return (
    <Link type="div" {...args}>
      <TileBody project={project} age={age} available={available} needs={needs}>
        {children}
      </TileBody>
    </Link>
  )
}

function TileFramed({ project, link = undefined, onClick = undefined }) {
  return (
    <div
      className={`${style.tile} hover-outline-primary theme-frame theme-bg-flat mv4 mh4 pointer`}
    >
      <Tile project={project} link={link} onClick={onClick} />
    </div>
  )
}

function TileBody({ project, age, available, needs, children }) {
  return (
    <div className={`${style.outer} flex`}>
      <div className={style.body}>
        {children}
        <div
          className="pa3"
          style={{ marginRight: `${(project.milestones?.length || 1) * 20}px` }}
        >
          <div className="flex justify-start">
            <div className={style.pic}>
              <img src={project.avatar && project.avatar.url} alt="" />
            </div>
            <div className="ml3 flex flex-column justify-between">
              <div className="f4 b">{project.title}</div>
              <StatusIcons
                className="pv3"
                project={project}
                age={age}
                available={available}
              />
              <div className="f6">
                {project.tags.map((t) => t.tag.label).join(', ')}
              </div>
            </div>
          </div>
          <div className="i mt3">{project.subtitle}</div>
        </div>
      </div>
      <MilestoneRibbons project={project} />
    </div>
  )
}

// TODO: switch over to Journey/Milestone/Ribbons
export function MilestoneRibbons({ project }) {
  if (!project.milestones) return null

  return (
    <div className={style.milestones}>
      {project.milestones.map((m) => (
        <MilestoneRibbon milestone={m} key={m.id} />
      ))}
    </div>
  )
}

function MilestoneRibbon({ milestone }) {
  return (
    <div
      className={style.milestone}
      style={{
        backgroundColor: milestone.meta.style.primary,
        color: milestone.meta.style.text
      }}
    >
      {milestone.name}
    </div>
  )
}

function StatusIcons({ className = '', project, age, available }) {
  return (
    <div className={`flex-items ${className} f7 gray`}>
      {available}
      <div className="mr3">
        <i className={`${ICON.chat} mr1`} />
        {project.summary.comments}
      </div>
      <div className="mr3 flex">
        <i className="fas fa-clock mr2" />
        {age}
      </div>
      <div className="">
        <i className="fas fa-users mr2" />
        {project.members.length}
      </div>
    </div>
  )
}

export default TileFramed
