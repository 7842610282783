import React, { useState } from 'react'

import Modal from 'tools/Modal'

import Edit from './Edit'
import Invites from './Invites'
import Search from './Search'
import Show from './Show'

function Members({
  types,
  roles = undefined,
  target,
  token,
  canChange,
  onChange,
  mutate
}) {
  const editState = useState(false)
  const [edit] = editState
  const [add, setAdd] = useState(false)
  const args = { types, editState, [token]: target.id, target }

  return (
    <>
      <Show {...args} canChange={canChange} roles={roles} />
      {canChange ? (
        <>
          <div>
            {add ? (
              <Search {...args} close={() => setAdd(false)} />
            ) : (
              <button className="plain medium mt3" onClick={() => setAdd(true)}>
                <i className="fas fa-plus mr2" />
                Invite Member
              </button>
            )}
          </div>
          <Invites target={target} onChange={onChange} />
          {edit ? (
            <Modal width="fw-50" viewState={editState}>
              <div className="pl4 pr3">
                <Edit
                  {...args}
                  roles={roles}
                  mutate={mutate}
                  onChange={onChange}
                />
              </div>
            </Modal>
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default Members
