import React, { useContext } from 'react'

import { useQuery } from '@apollo/client'

import { REACTOR_CHALLENGES } from '../graphql'
import { ProjectContext, R_PROJECT } from '../reducer'
import Journeys from './Journeys'

function Progress() {
  const [{ id: projectId }, dispatch] = useContext(ProjectContext)
  useQuery(REACTOR_CHALLENGES, {
    variables: { projectId },
    skip: !projectId,
    onCompleted(result) {
      if (result && result.reactorChallenges) {
        dispatch({
          type: R_PROJECT.SET_CHALLENGES,
          value: result.reactorChallenges
        })
      }
    }
  })

  return (
    <div className="theme-frame-tabbed max-view-page mb5 pa4 relative">
      <label className="mb3 f4 heading">
        <i className="fas fa-route mr3" />
        Journey Progress
      </label>
      <i>
        Journeys guide your venture by providing you milestones to achieve and a
        rubric of questions.
      </i>

      <Journeys className="heading" />
    </div>
  )
}

export default Progress
