import React, { useState } from 'react'

import { useMutation } from '@apollo/client'

import Modal from 'tools/Modal'

import { INVITE } from 'components/Person/graphql'

function RequestAccess({ viewState, project, resourceId, resourceType }) {
  const [note, setNote] = useState('')
  const [result, setResult] = useState(undefined)

  const [makeRequest] = useMutation(INVITE, {
    update(cache, result) {
      setResult(result.data.invite.result)
    }
  })
  return (
    <Modal viewState={viewState} width="fw-min" className="pa4">
      <div className="f3 mb4">Request Access?</div>
      {!result ? (
        <>
          Sorry, you don't have access to this resource.
          <div className="mv4">You can request access:</div>
          <textarea
            placeholder="Reason for wanting access"
            className="w-100"
            rows={4}
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <button
            className="large db mt4"
            onClick={() => {
              makeRequest({
                variables: {
                  action: 'REQUEST',
                  note: note,
                  resourceType,
                  resourceId,
                  projectId: project.id
                }
              })
            }}
          >
            Request Access
          </button>
        </>
      ) : (
        <>
          Your request has been sent!
          <button className="large db mt4" onClick={() => viewState[1](false)}>
            Ok
          </button>
        </>
      )}
    </Modal>
  )
}

export default RequestAccess
