import React, { useCallback, useContext, useState } from 'react'

import { Delete } from 'tools/Confirm'
import { FILE_TYPES } from 'tools/Files/normalize'
import { Input, Textarea } from 'tools/Input'
import Selector from 'tools/Selector'
import Toggle from 'tools/Toggle'

import { R_STUDIO, StudioContext } from '../reducer'

// TODO: Switch to use db-driven configs
const TYPES = [
  { label: 'Input Line', value: 'line', Component: FieldText },
  { label: 'Input Email', value: 'email', Component: FieldText },
  { label: 'Input Phone', value: 'phone', Component: FieldText },
  { label: 'Input Textarea', value: 'text', Component: FieldText },
  { label: 'Input Journey', value: 'journey', Component: FieldJourney },
  { label: 'Input Files', value: 'files', Component: FieldFiles },
  { label: 'Input Website', value: 'website', Component: FieldText },
  { label: 'Input Toggle', value: 'toggle', Component: FieldToggle },
  { label: 'Input Referred By', value: 'referred', Component: FieldReferred },
  {
    label: 'Share Contact Ack',
    value: 'shareContact',
    Component: FieldShareContact
  },
  {
    label: 'Rubric Question',
    value: 'rubricQuestion',
    Component: FieldRubricQuestion
  },
  { label: 'Application Status', value: 'status', Component: FieldStatus }
].reduce((d, a) => {
  d[a.value] = a
  return d
}, {})

const TARGETS = ['user', 'project'].map((label) => ({ label, value: label }))

function EditField({ field }) {
  const [studio, dispatch] = useContext(StudioContext)
  const { Component: FieldEditor } = TYPES[field.type]
  const fieldId = field.id
  const onChange = useCallback(
    (value, token) => {
      dispatch({
        type: R_STUDIO.CHANGE_FIELD,
        fieldId,
        token,
        value
      })
    },
    [dispatch, fieldId]
  )
  const onChangeMeta = useCallback(
    (value, token, meta) => {
      dispatch({
        type: R_STUDIO.CHANGE_FIELD,
        fieldId,
        token,
        meta,
        value
      })
    },
    [dispatch, fieldId]
  )

  const onSaveMeta = useCallback(
    ({ token, metadata, ...args }, value, good, bad) => {
      studio.onSave(
        { ...args, token: 'meta' },
        JSON.stringify({ ...metadata, [token]: value }),
        good,
        bad
      )
    },
    [studio]
  )

  const args = {
    component: 'field',
    cmeta: { portfolioId: studio.id, id: field.id },
    meta: { orgId: studio.orgId },
    onSave: studio.onSave,
    onChange,
    // keeps typescript warnings down
    metacalls: {
      onChangeMeta,
      onSaveMeta
    }
  }
  return (
    <>
      <Delete
        className="absolute top-0 right-0"
        onConfirm={() => {
          studio.onSave({ ...args, token: 'action' }, 'REMOVE')
        }}
      >
        Remove this field: <span className="ml2 pill f6">{field.type}</span>
        <div className="bl bw2 pa2 pl3 mv3">{field.question}</div>
      </Delete>
      <div className="flex-between">
        <Selector
          label="Type"
          value={field.type}
          args={args}
          onSave={studio.onSave}
          opts={Object.values(TYPES)}
          token="type"
        />
        <Selector
          label="Target"
          value={field.target}
          args={args}
          onSave={studio.onSave}
          opts={TARGETS}
          token="target"
        />
        <Toggle
          value={field.required}
          onChange={(x) => {
            studio.onSave({ ...args, token: 'required' }, x)
          }}
        >
          Required
        </Toggle>
        <Input
          {...args}
          token="order"
          valState={[field.order, (v) => onChange(v, 'order')]}
          style={{ width: '3rem' }}
          onSave={(a, b, c, d) => studio.onSave(a, parseInt(b) || 0, c, d)}
        />
      </div>
      <EditExplain field={field} onChange={onChange} args={args} />
      <div className="mt3">
        <FieldEditor field={field} {...args} />
      </div>
    </>
  )
}

function PlusMinus({ edit, setEdit }) {
  return (
    <div
      className="pointer hover-hilite pa1 gray br2 f6"
      onClick={() => setEdit(!edit)}
    >
      <i className={`fas fa-${edit ? 'minus' : 'plus'}`} />
    </div>
  )
}

function EditExplain({ field, onChange, args }) {
  const [edit, setEdit] = useState(false)
  return (
    <>
      <label className="mv3 flex-items">
        Explain ({field.explain.length})
        <PlusMinus edit={edit} setEdit={setEdit} />
      </label>
      {edit ? (
        <Textarea
          {...args}
          token="explain"
          valState={[field.explain, (ev) => onChange(ev, 'explain')]}
          className="w-100"
        />
      ) : null}
    </>
  )
}

function FieldText({ field, onChange, ...args }) {
  return (
    <div>
      <label className="mb3">Question:</label>
      <Input
        {...args}
        token="question"
        valState={[field.question, (ev) => onChange(ev, 'question')]}
        className="w-100 mb3"
      />
      {/* @ts-ignore */}
      <IntoData field={field} {...args} />
    </div>
  )
}

function IntoData({ field, metacalls: { onChangeMeta, onSaveMeta }, ...args }) {
  return (
    <div className="flex">
      <div className="flex-items">
        <label className="mr3">Into:</label>
        <Input
          {...args}
          onSave={onSaveMeta}
          metadata={field.meta}
          token="into"
          valState={[
            field.meta.into || '',
            (ev) => onChangeMeta(ev, 'into', field.meta)
          ]}
          className="w-100"
        />
      </div>
      <div className="flex-items">
        <label className="mr3 ml4">Data:</label>
        <Input
          {...args}
          onSave={onSaveMeta}
          metadata={field.meta}
          token="data"
          valState={[
            field.meta.data || '',
            (ev) => onChangeMeta(ev, 'data', field.meta)
          ]}
          className="w-100"
        />
      </div>
    </div>
  )
}

function FieldJourney({ field, args }) {
  return JSON.stringify(field.meta)
}

function FieldFiles({ field, metacalls: { onSaveMeta }, ...args }) {
  const metadata = field.meta || {}
  if (!metadata.types) {
    metadata.types = []
  }
  if (!metadata.link) {
    metadata.link = field.target
  }

  return (
    <>
      <label className="mb3">Question:</label>
      <Input
        {...args}
        token="question"
        valState={[field.question, (ev) => args.onChange(ev, 'question')]}
        className="w-100 mb3"
      />

      <div className="flex">
        <label className="mr3">Allowed File Types</label>
        <select
          multiple={true}
          value={metadata.types}
          onChange={(ev) => {
            const files = Array.from(ev.target.selectedOptions, (o) => o.value)
            onSaveMeta({ ...args, metadata, token: 'types' }, files)
          }}
        >
          {Object.values(FILE_TYPES).map((t) => (
            <option value={t.type} key={t.type}>
              {t.name}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

function FieldToggle({ field, ...args }) {
  return (
    <div>
      <Toggle
        value={field.meta.label || false}
        onChange={(x) => {
          args.onSaveMeta({ ...args, metadata: field.meta, token: 'label' }, x)
        }}
        className="mb3"
      >
        Show Toggle Label
      </Toggle>
      {/* @ts-ignore */}
      <IntoData field={field} {...args} />
    </div>
  )
}

function FieldReferred({ field, args }) {
  return JSON.stringify(field.meta)
}

function FieldShareContact({
  field,
  metacalls: { onChangeMeta, onSaveMeta },
  ...args
}) {
  return (
    <>
      <div>
        <label className="mb3">Question:</label>
        <Input
          {...args}
          token="question"
          valState={[field.question, (ev) => args.onChange(ev, 'question')]}
          className="w-100 mb3"
        />
        <label className="mb3">Terms of Service Link:</label>
        <Input
          {...args}
          onSave={onSaveMeta}
          metadata={field.meta}
          token="tos"
          valState={[
            field.meta.tos || '',
            (ev) => onChangeMeta(ev, 'tos', field.meta)
          ]}
          className="w-100"
        />
      </div>
      <div>
        <label className="mv3">Unsubscribe Link:</label>
        <Input
          {...args}
          onSave={onSaveMeta}
          metadata={field.meta}
          token="unsub"
          valState={[
            field.meta.unsub || '',
            (ev) => onChangeMeta(ev, 'unsub', field.meta)
          ]}
          className="w-100"
        />
      </div>
    </>
  )
}

function FieldRubricQuestion({ field, args }) {
  return JSON.stringify(field.meta)
}

function FieldStatus({ field, args }) {
  return <></>
}

export function AddField({ isSuper }) {
  const [studio] = useContext(StudioContext)
  const [add, setAdd] = useState(false)

  if (!isSuper) return null
  if (!add) {
    return (
      <button className="clear mt3 w-100 pv2" onClick={() => setAdd(true)}>
        <i className="fas fa-plus mr2" />
        Add Field
      </button>
    )
  }
  return (
    <div className="mt3 flex-items">
      <Selector
        label="Type"
        value={''}
        args={{
          component: 'field',
          cmeta: { portfolioId: studio.id, id: undefined },
          meta: { orgId: studio.orgId }
        }}
        onSave={(a, b, good, c) => {
          studio.onSave(
            a,
            b,
            (s, r) => {
              setAdd(false)
              good && good(s, r)
            },
            c
          )
        }}
        opts={[{ label: '', value: '' }].concat(Object.values(TYPES))}
        token="type"
      />
      <button className="plain ml3 medium" onClick={() => setAdd(false)}>
        Cancel
      </button>
    </div>
  )
}

export default EditField
