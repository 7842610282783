import React from 'react'

import Gradients from './Gradients'
import { Plot, Sparkline } from './Plot'
import { plotValues } from './Plot/lib'
import { LabelBox, Text, TextCenter, TextRight } from './Text'
import { usePaletteSet } from './palette'

// actual "size" in pixels irrelevant as this is vectored.
// a width of 1000 and 564 is a 16:9 HDTV aspect ratio.  If we take off some
// for the navbar, this puts us to 1000x512
function Svg({ style = {}, children, width = 1000, height = 512, ...args }) {
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      style={{
        width: '100%',
        height: 'auto',
        // backgroundColor: 'transparent',
        ...style
      }}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      {...args}
    >
      {children}
    </svg>
  )
}

export default Svg
export {
  Svg,
  usePaletteSet,
  Gradients,
  Plot,
  plotValues,
  Sparkline,
  Text,
  TextCenter,
  TextRight,
  LabelBox
}
