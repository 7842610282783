import React, { useContext } from 'react'
import Select from 'react-select/async'
import SelectCreate from 'react-select/async-creatable'

import { selectStyle } from 'constants/selectStyles'

import { LIST_TAGS } from 'tools/SelectTags/graphql'

import GlobalContext from 'reducer/global'

function normalizeOption(tag) {
  return { ...tag, value: tag.label }
}

function TagSelector({
  tagType,
  mapType,
  value,
  setValue,
  create,
  multi = undefined,
  className = '',
  placeholder = undefined,
  defaultOptions = undefined,
  components = undefined
}) {
  const [{ apollo, user }] = useContext(GlobalContext)

  if (value) {
    value = value.map(normalizeOption)
    if (!multi) {
      value = value[0]
    }
  }

  // if (mapType ==="role"){
  //   console.log("VALUE!!", value)
  // }
  const loadOptions = (matching, setOptions) => {
    apollo
      .query({
        query: LIST_TAGS,
        variables: { type: tagType, matching }
      })
      .then(({ data }) => {
        if (data && data.tags) {
          setOptions(data.tags.map(normalizeOption))
        }
      })
  }

  const args = {
    components,
    className,
    classNamePrefix: 'react-select',
    placeholder,
    defaultOptions,
    loadOptions,
    isMulti: multi,
    styles: selectStyle(user),
    value
  }
  if (!create)
    return <Select {...args} cacheOptions onChange={(v) => setValue(v)} />

  return (
    <SelectCreate
      {...args}
      cacheOptions
      onChange={(v) => setValue(v)}
      noOptionsMessage={() => <>Start typing to find options</>}
    />
  )
}

export default TagSelector
