import paths from 'constants/paths'

import { LOAD_PORTFOLIO, UPSERT_PORTFOLIO } from './graphql'
import { R_STUDIO, StudioContext, StudioProvider } from './reducer'

const mod = {
  Context: StudioContext,
  Provider: StudioProvider,
  DO_LOAD: R_STUDIO.SET,
  DO_MERGE: R_STUDIO.MERGE,
  DO_SAVE: R_STUDIO.UPSERT,
  DO_DELETE: R_STUDIO.DELETE,
  LOAD: LOAD_PORTFOLIO,
  SAVE: UPSERT_PORTFOLIO,
  loadResultKey: 'portfolio',
  targetKey: 'folioId',
  queryName: 'portfolio',
  loadedWait: false,
  componentName: 'portfolio',
  basePath: paths.studio,
  navPath: ({ tabId, params: { orgId, folioId } }) =>
    `${paths.studio}/${orgId}/${tabId}/${folioId}`,
  oneLoadResult: true,
  controlClose: ({ params }) => {
    return `${paths.org}/${params.orgId}/profile`
  }
}
export default mod
