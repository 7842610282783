import React from 'react'
import ReactMarkdown from 'react-markdown'

import remarkGfm from 'remark-gfm'

import Link from '../Links'

function LinkRenderer({ href = '', children = undefined }) {
  if (href.match(/^\/[a-zA-Z0-9?=%/-]+$/)) {
    return <Link to={href}>{children}</Link>
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

function Markdown({ safeLinks = true, children, components = {}, ...props }) {
  if (safeLinks) {
    return (
      <ReactMarkdown
        components={{ ...components, a: LinkRenderer }}
        remarkPlugins={[remarkGfm]}
        children={children}
        {...props}
      />
    )
  }
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} children={children} {...props} />
  )
}

export default Markdown
