import React, { useContext } from 'react'

import Input from '../Profile/Input'
import ProjectContext from '../reducer'

function Valuation({ onSave, onChange }) {
  const [
    {
      d: { financials = {} }
    }
  ] = useContext(ProjectContext)

  return (
    <>
      <div className="flex-start mt4 mb3">
        <Input
          value={financials.current || ''}
          token="current"
          component="financials"
          onChange={onChange}
          onSave={onSave}
          label="Valuation"
          prefix={<div className="gray mr2">$</div>}
          numeric={true}
          placeholder="current pre-funding valuation"
          help="(subject to verification)"
          className="w-50 mr3"
        />
        <Input
          value={financials.previous || ''}
          token="previous"
          component="financials"
          onChange={onChange}
          onSave={onSave}
          label="Previous Valuation"
          prefix={<div className="gray mr2">$</div>}
          numeric={true}
          labelStyle="minimize f6"
          help="The last valuation made by a third party (if applicable)"
          placeholder=""
          className="w-50 ml3"
        />
      </div>
      <Input
        value={financials.method || ''}
        token="method"
        component="financials"
        type="text"
        onChange={onChange}
        label="Valuation Method"
        onSave={onSave}
        labelStyle="minimize f6"
        className="w-100"
        placeholder="Who made the valuation and what method was used to come to that value?"
      />
    </>
  )
}

export default Valuation
