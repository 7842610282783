import React, { useState } from 'react'

export function AddButton({
  onClick,
  children = undefined,
  className = 'f7 w-100'
}) {
  return (
    <button className={`${className} clear large ph2 pv1`} onClick={onClick}>
      <i className="fas fa-plus mr2" />
      {children}
    </button>
  )
}

// like AddButton but wrapping the initial field and create
export const DEFAULT_EDIT = { open: false, error: undefined }

// split out valState so others can give their own for extra behavior
export function CreateButton(args) {
  const valState = useState(DEFAULT_EDIT)
  return <CreateButtonValState valState={valState} {...args} />
}

export function CreateButtonValState({
  onSave,
  valState,
  placeholder,
  children,
  label = undefined,
  className = undefined,
  ...args
}) {
  const [edit, setEdit] = valState
  const [input, setInput] = useState('')
  const submit = () => {
    onSave(
      args,
      input,
      () => {
        setEdit(DEFAULT_EDIT)
      },
      (reason) => {
        setEdit({ ...DEFAULT_EDIT, error: reason })
      }
    )
  }

  if (edit.open) {
    return (
      <>
        <div className={`flex-center ${className}`}>
          {label ? <label className="mr2">{label}:</label> : null}
          <input
            placeholder={placeholder}
            onChange={(ev) => setInput(ev.target.value)}
            onKeyDown={(ev) => {
              // @ts-ignore
              if (ev.key === 'Enter') {
                // @ts-ignore
                submit()
              }
            }}
            className="w-90 mr3"
            autoFocus={true}
          />
          <button
            className="plain large ml-auto"
            onClick={() => setEdit({ ...DEFAULT_EDIT, open: false })}
          >
            Cancel
          </button>
          <button className="large ml3" onClick={submit}>
            Create
          </button>
        </div>
        {edit.error ? <div className="red">{edit.error}</div> : null}
      </>
    )
  }
  return (
    <AddButton
      className={className}
      onClick={() => setEdit({ ...DEFAULT_EDIT, open: true })}
    >
      {children}
    </AddButton>
  )
}

export function AngleIcon({ open, className = '' }) {
  return <i className={`fas fa-angle-${open ? 'down' : 'right'} ${className}`} />
}

export function PivotIcon({ open, className = '' }) {
  return <i className={`fas fa-${open ? 'minus' : 'plus'}-square ${className}`} />
}

export function CheckIcon({ checked, className = '' }) {
  return (
    <i className={`${checked ? 'fas fa-check' : 'far fa'}-square ${className}`} />
  )
}

export function TabFrame({ children, className = 'pa4' }) {
  return (
    <div className={`max-view-page theme-frame-tabbed mb5 ${className}`}>
      {children}
    </div>
  )
}

export function Label({ children, className = 'f4 mb3' }) {
  return <label className={`heading ${className}`}>{children}</label>
}
