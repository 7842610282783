import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import moment from 'moment'

import paths from 'constants/paths'

import { LoadingInline as Loading } from 'tools/Loader'
import { intcmp } from 'utils/string'

import GlobalContext from 'reducer/global'

import OrgTile from '../Tile'
import { ORGS } from '../graphql'
import { normalizeOrg } from '../normalize'
import Filters from './Filters'
import style from './index.module.scss'

////////////////////////////////////////////////////////////////////////////////
// so we can use it with reduce and not get the second arg as an index
function normOrg(x) {
  return normalizeOrg(x)
}
function normalizeOrgs({ result, meta, total, matching }, prefs) {
  result = result.map((p) => {
    p = { ...p }
    p._updated = moment(p.updatedAt)
    p._updated_t = p._updated.unix()
    return p
  })
  return sortOrgs(
    { ordered: result.map(normOrg), meta, sort: {}, total, matching },
    prefs
  )
}

function sortOrgs({ ordered, meta, sort, total, matching }, prefs) {
  return {
    ordered: sortList(ordered, prefs),
    total,
    matching,
    meta,
    sort: { ...prefs }
  }
}

function sortList(list, prefs) {
  let out = list

  out = list.toSorted((a, b) => intcmp(b._updated_t, a._updated_t))
  //
  // if (prefs.sort.rev) {
  //   out.reverse()
  // }

  return out
}

////////////////////////////////////////////////////////////////////////////////
function Browse({ action }) {
  const history = useHistory()
  const [{ user, prefs }] = useContext(GlobalContext)
  let filter = prefs.orgList
  if (action === 'mine') {
    filter = { ...filter, memberId: user.id }
  }

  const [orgs, setOrgs] = useState({
    ordered: [],
    meta: {},
    total: 0,
    matching: 0,
    sort: {}
  })

  const { loading } = useQuery(ORGS, {
    variables: { filter },
    fetchPolicy: 'cache-and-network',
    onCompleted(result) {
      if (!result) return
      if (result.orgs.success) {
        setOrgs(normalizeOrgs(result.orgs, user))
      }
    }
  })

  let bottom = null
  let top = null
  if (action === 'mine') {
    top = `Showing your organizations`
  }
  if (orgs.matching > 0) {
    bottom = `${orgs.ordered.length} most active of ${orgs.matching} matches (${orgs.total} total)`
  } else if (!action) {
    top = (
      <div className="mt4">
        No matches found!
        <p className="mt4" />
        You can create a new investor organization, or change your search filters.
      </div>
    )
  }
  return (
    <div className="relative pb5 mt4">
      <div className="ba b--transparent ph3-m ph0-l pv2 pv4-ns max-view-page relative">
        {loading ? (
          <div className="flex-center absolute top-2 w-100">
            <Loading />
          </div>
        ) : null}
        <div className="f3 i mb3 tc">{top}</div>
        <div
          className={`${style.negativeBoundary} flex flex-wrap justify-center pb2-ns`}
        >
          {orgs.ordered.map((org) => {
            return <OrgTile key={org.id} org={org} />
          })}
        </div>
        <div className="tc">
          <div className="f7 i mb3">{bottom}</div>
          <button className="large" onClick={() => history.push(paths.orgCreate)}>
            Create a Partner Organization
          </button>
        </div>
      </div>
      <Filters />
    </div>
  )
}

export default Browse
