import gql from 'graphql-tag'

import { fileVars } from 'tools/Files/graphql'

import { inviteValues } from 'components/Person/graphql'
import { applicationValues } from 'components/Studio/graphql'

const dataListValues = `
data(types: ["info", "location"]) {
id
type
value
}
`

const orgPublicValues = `
id
shortId
name
updatedAt
insertedAt
status
type
style
summary
avatar
tags {
id
tag {
id
type
label
meta
}
}
`

const orgBaseValues = `
${orgPublicValues}
myAccess
files {
${fileVars}
}
`

export const orgListValues = `
${orgBaseValues}
${dataListValues}
members {
id
type
}
`

const portfolioValues = `
id
shortId
name
status
`

const orgLoadValues = `
${orgBaseValues}
${dataListValues}
portal
folio
portfolios {
${portfolioValues}
}
members {
id
type
user {
id
handle
name
lastSeen
avatar
}
}
invites {
${inviteValues}
}
forums {
id
shortId
author {
id
handle
name
avatar
}
type
subject
visible
meta
insertedAt
updatedAt
}
`

export const ORGS = gql`
query orgs($filter: InputOrgFilter!) {
orgs(filter: $filter) {
success
reason
total
matching
result {
${orgListValues}
}
}
}
`

export const ORG_PUBLIC = gql`
query orgPublic($id: String, $portfolioId: String) {
  orgPublic(id: $id, portfolioId: $portfolioId) {
    ${orgPublicValues}
  }
}
`

export const LOAD_ORG_FULL = gql`
query orgs($id: String!) {
  orgs(filter: {id: $id}) {
    success
    reason
    total
    matching
    result {
      ${orgLoadValues}
    }
  }
}
`

export const LOAD_APPLICATION = gql`
query orgPublic($portfolioId: String!) {
  orgPublic(portfolioId: $portfolioId) {
    success
    reason
    result {
      ${orgPublicValues}
      portfolios (id: $portfolioId) {
        ${portfolioValues}
        style
        application
        applicationFields {
          ${applicationValues}
        }
      }
    }
  }
}
`

export const UPSERT_ORG = gql`
mutation upsertOrg($org: InputOrg) {
  upsertOrg(org: $org) {
    success
    reason
    result {
      ${orgLoadValues}
    }
  }
}
`

export const UPSERT_ORG_MEMBER = gql`
mutation upsertOrgMember($orgId: String!, $member: InputOrgMember!) {
  upsertOrgMember(orgId: $orgId, member: $member) {
    success
    reason
    meta
    result {
      ${orgLoadValues}
    }
  }
}
`
